import { createAction, handleActions } from "redux-actions";
import produce from "immer";

const initialState = {
  web3: "",
  provider: "",
  windowEther: "",
  defaultProvider: "",
  chainId: "",
  active: false,
  balance: "0",
  transactionHash: "",
  isOwner: false,
  governanceType: "",
  mainType: "",
};

const SETDEFAULTPROVIDER = "user/SETDEFAULTPROVIDER";
export const setDefaultProvider = createAction(
  SETDEFAULTPROVIDER,
  (input) => input
);

const SETWEB3 = "user/SETWEB3";
export const setWeb3 = createAction(SETWEB3, (input) => input);

const SETPROVIDER = "user/SETPROVIDER";
export const setProvider = createAction(SETPROVIDER, (input) => input);

const SETWINDOWETHER = "user/SETWINDOWETHER";
export const setWindowEther = createAction(SETWINDOWETHER, (input) => input);

const SETACTIVE = "user/SETACTIVE";
export const setActive = createAction(SETACTIVE, (input) => input);

const SETBALANCE = "user/SETBALANCE";
export const setBalance = createAction(SETBALANCE, (input) => input);

const SETTRANSACTIONHASH = "user/SETTRANSACTIONHASH";
export const setTransactionHash = createAction(
  SETTRANSACTIONHASH,
  (input) => input
);

const SETISOWNER = "user/SETISOWNER";
export const setIsOwner = createAction(SETISOWNER, (input) => input);

const SETGOVERNANCETYPE = "user/SETGOVERNANCETYPE";
export const setGovernanceType = createAction(
  SETGOVERNANCETYPE,
  (input) => input
);

const SETMAINTYPE = "user/SETMAINTYPE";
export const setMainType = createAction(
  SETMAINTYPE,
  (input) => input
);

const user = handleActions(
  {
    [SETWEB3]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.web3 = input;
      }),
    [SETPROVIDER]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.provider = input;
      }),
    [SETWINDOWETHER]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.windowEther = input;
      }),
    [SETDEFAULTPROVIDER]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.defaultProvider = input;
      }),
    [SETACTIVE]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.active = input;
      }),
    [SETBALANCE]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.balance = input;
      }),
    [SETTRANSACTIONHASH]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.transactionHash = input;
      }),
    [SETISOWNER]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.isOwner = input;
      }),
    [SETGOVERNANCETYPE]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.governanceType = input;
      }),
    [SETMAINTYPE]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.mainType = input;
      }),
  },
  initialState
);
export default user;
