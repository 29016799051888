import React from "react";
import { useSelector } from "react-redux";
import { onClickScan, setFromWeiBalance, subString } from "../../common";
import Loading from "./Loading";
import NoResults from "./NoResults";

const StakingBox = ({ list, styles, loading }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);

  return (
    <div className={styles.stakingBox}>
      <div className={styles.top}>
        <div className={styles.tit}>Staking</div>
      </div>
      <div className={`${styles.tableNormal} ${styles.tableStaking}`}>
        <div className={`${styles.thead}`}>
          <div className={`${styles.address}`}>Address</div>
          <div className={`${styles.amount}`}>Amount</div>
          <div className={`${styles.type}`}>Type</div>
          <div className={`${styles.time}`}>Time</div>
          <div className={`${styles.button}`}>ㅤ</div>
        </div>
        <div className={`${styles.scrollWrap}`}>
          <div>
            {loading ? (
              list != "" ? (
                list.map((item, index) => (
                  <div key={index} className={`${styles.tbody}`}>
                    <div className={`${styles.address}`}>
                      {subString(item.staker)}
                    </div>
                    <div className={`${styles.amount}`}>
                      {setFromWeiBalance(defaultProvider, item.amount)} GMMT
                    </div>
                    <div className={`${styles.type}`}>{item.type}</div>
                    <div className={`${styles.time}`}>{item.time}</div>
                    <div className={`${styles.button}`}>
                      <div
                        className={`${styles.btnExplorer}`}
                        onClick={() => {
                          onClickScan(item.transactionHash);
                        }}
                      ></div>
                    </div>
                  </div>
                ))
              ) : (
                <NoResults styles={styles} />
              )
            ) : (
              <Loading styles={styles} loading={loading}/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingBox;
