import React from "react";
import { useSelector } from "react-redux";
import { fromWei, subString, thousandsSeparator } from "../../common";

const StakeComplete = ({ styles, item, inputValue, onClickCancel }) => {

  return (
    <div className={styles.popCont}>
      <p className={styles.popTit}>Complete GMMT</p>
      <ul className={styles.valueList}>
        <li>
          <span className={styles.tit}>Selected Validator</span>
          <span className={styles.value}>{subString(item.validatorAddress)}</span>
        </li>
        <li>
          <span className={styles.tit}>Commission Rate</span>
          <span className={styles.value}>{item.commissionRate / 100}%</span>
        </li>
        <li>
          <span className={styles.tit}>Status</span>
          <span className={styles.value}>
            {" "}
            {item.status == "1"
              ? "Active"
              : item.status == "2"
              ? "InActive"
              : "Jail"}
          </span>
        </li>
        <li>
          <span className={styles.tit}>GMMT Amount</span>
          <span className={styles.value}>
            {thousandsSeparator(inputValue)} GMMT
          </span>
        </li>
      </ul>
      <div className={styles.completeMsg}>
        <img
          src={require("../../assets/images/common_ico_pop_complete@3x.png")}
          alt="ico complete"
        />
        <p>Stake is Successfully Complete</p>
      </div>
      <div className={styles.btnWrap}>
        <button type="button" className={`${styles.btnNormal} ${styles.btnOk}`} onClick={onClickCancel}>
          Ok
        </button>
      </div>
    </div>
  );
};

export default StakeComplete;
