import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../Components/Navbar/NavBar.js";
import StakingABI from "../abi/Staking.json";
import { useLocation } from "react-router";
import Web3 from "web3";
import { setActive, setBalance, setIsOwner, setProvider, setTransactionHash, setWindowEther } from "../redux/user";
import TbodyItem from "../Components/Main/TbodyItem";
import ViewPage from "../Components/Page/ViewPage";
import styles from "../assets/css/GMMTStaking.module.css";
import TradeModal from "../Components/Modals/TradeModal";
import schedule from "node-schedule";
import { scheduleTime } from "../common";
import RegisterValidator from "../Components/Main/RegisterValidator.js";
import { BN } from "bn.js";
import BaseModal from "../Components/Modals/BaseModal.js";
import { setAccount } from "../redux/account.js";

const Main_v2 = ({ styles }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  // const web3 = useSelector((state) => state.user.web3);
  const windowEther = useSelector((state) => state.user.windowEther);
  const account = useSelector((state) => state.account.account);
  const transactionHash = useSelector((state) => state.user.transactionHash);
  const provider = useSelector((state) => state.user.provider);

  const validatorContractAddress =
    process.env.REACT_APP_PUBLIC_STAKING_CONTRACT;
  const chainId = process.env.REACT_APP_NETWORK_CHAINID;

  const [loading, setLoading] = useState(true); 
  const [validatorContract, setValidatorContract] = useState();
  const [validatorList, setValidatorList] = useState([]);
  const [viewValidatorList, setViewValidatorList] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalItem, setModalItem] = useState();
  const [totalPageNum, setTotalPageNum] = useState(0);
  const [nowPageNum, setNowPageNum] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pageLimit, setPageLimit] = useState(10);
  const [mobileCheck, setMobileCheck] = useState(false); // false이면 데탑;

  const stakeModal = (item) => {
    if (account != "") {
      setModalStatus(true);
      setModalType("stake");
      setModalItem(item);
    } else {
      setModalStatus(true);
      setModalType("connect");
    }
  };

  const getValidatorContract = async () => {
    if (defaultProvider != "") {
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        StakingABI,
        validatorContractAddress
      );

      setValidatorContract(contract);
    }
  };

  const getViewValidatorList = (page) => {
    if (validatorList != "") {
      let list = validatorList;
      let totalPageNum = Math.ceil(list.length / limit);
      setTotalPageNum(totalPageNum);
      setNowPageNum(page);
      if (totalPageNum >= Number(page)) {
        let resultList = [];
        for (let i = limit * page - limit; i < limit * page; i++) {
          if (list[i] == undefined) {
            break;
          }
          resultList.push(list[i]);
        }
        setViewValidatorList(resultList);
      } else {
        // window.location.href = "/";
      }
    }
  };

  const getValidatorList = async () => {
    if (validatorContract != undefined) {
      let list = await validatorContract.methods.getValidators().call();
      // let validatorAdded = await validatorContract.getPastEvents(
      //   "ValidatorAdded",
      //   {
      //     filter: {},
      //     fromBlock: 0,
      //     toBlock: "latest",
      //   }
      //   );
      let promise = [];
      for (let i = 0; i < list.length; i++) {
        promise.push(
          new Promise(async (resolve, reject) => {
            // let address = validatorAdded[i].returnValues.validator;
            let address = list[i];
            let isValidator = await validatorContract.methods
              .isValidator(`${address}`)
              .call();
            if (isValidator) {
              let validatorStatus = await validatorContract.methods
                .getValidatorStatus(address)
                .call();
              let validatorDelegation = {
                delegatedAmount: "0",
                atEpoch: "0",
              };

              let checkList = list.some((validatorAddress) => {
                if (validatorAddress.toLowerCase() == address.toLowerCase()) {
                  return true;
                } else {
                  return false;
                }
              });

              let delegatorFee = {
                getDelegatorFee: "0",
                getPendingDelegatorFee: "0",
              };

              let calcAvailableForRedelegateAmount = "0";

              if (account != "") {
                let validatorDelegationResult = await validatorContract.methods
                  .getValidatorDelegation(address, account)
                  .call();
                // let getDelegatorFee = await validatorContract.methods
                //   .getDelegatorFee(address, account)
                //   .call();
                // let getPendingDelegatorFee = await validatorContract.methods
                //   .getPendingDelegatorFee(address, account)
                //   .call();
                // calcAvailableForRedelegateAmount =
                //   await validatorContract.methods
                //     .calcAvailableForRedelegateAmount(address, account)
                //     .call();
                validatorDelegation.delegatedAmount =
                  validatorDelegationResult.delegatedAmount;
                validatorDelegation.atEpoch = validatorDelegationResult.atEpoch;
                // delegatorFee.getDelegatorFee = getDelegatorFee;
                // delegatorFee.getPendingDelegatorFee = getPendingDelegatorFee;
              }

              let sendData = {
                validatorAddress: address,
                ownerAddress: validatorStatus.ownerAddress,
                status: validatorStatus.status,
                totalDelegated: validatorStatus.totalDelegated,
                totalRewards: validatorStatus.totalRewards,
                jailedBefore: validatorStatus.jailedBefore,
                slashesCount: validatorStatus.slashesCount,
                commissionRate: validatorStatus.commissionRate,
                claimedAt: validatorStatus.claimedAt,
                changedAt: validatorStatus.changedAt,
                delegatedAmount: validatorDelegation.delegatedAmount,
                atEpoch: validatorDelegation.atEpoch,
                getDelegatorFee: delegatorFee.getDelegatorFee,
                getPendingDelegatorFee: delegatorFee.getPendingDelegatorFee,
                calcAvailableForRedelegateAmount:
                  calcAvailableForRedelegateAmount.rewardsDust,
                isTop: checkList,
              };

              resolve(sendData);
            }else{
              // resolve("")
            }
          })
        );
      }
      Promise.all(promise).then((values) => {
        let activeArray = [];
        let pendingArray = [];
        let jailedArray = [];

        for (let i = 0; i < values.length; i++) {
          switch (values[i].status) {
            case "1":
              activeArray.push(values[i]);
              break;
            case "2":
              pendingArray.push(values[i]);
              break;
            case "3":
              jailedArray.push(values[i]);
              break;

            default:
              break;
          }
        }

        activeArray.sort((a, b) => {
          return b.totalDelegated - a.totalDelegated;
        });
        pendingArray.sort((a, b) => {
          return b.totalDelegated - a.totalDelegated;
        });
        jailedArray.sort((a, b) => {
          return b.totalDelegated - a.totalDelegated;
        });

        let array = activeArray.concat(pendingArray).concat(jailedArray);

        setValidatorList(array);
        setLoading(false)
      });
    }
  };

  const onClickDelegate = async (btnStatus, address, value) => {
    if (account != "") {
      if (btnStatus) {
        try {
          const web3 = new Web3(windowEther);
          let toWeiValue = web3.utils.toWei(value);
          const contract = new web3.eth.Contract(
            StakingABI,
            validatorContractAddress
          );
          setModalType("confirm");
          let result = await contract.methods.delegate(address).send({
            from: account,
            value: toWeiValue,
          });

          console.log(result);
          if (result.status == true) {
            setModalType("stakeComplete");
          } else {
            setModalType("failed");
          }

          dispatch(setTransactionHash(result.transactionHash));
        } catch (error) {
          console.log(error);
          // if (error.code == 400 &&1 error) {
          setModalType("refuse");
          // }
        }
      }
    }
  };

  const checkdetectMoileDevice = (agent, width) => {
    const mobileRegex = [
      /Android/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    // setMobileCheck(mobileRegex.some(mobile => agent.match(mobile)));
    setMobileCheck(width <= 767);
  };

  useEffect(() => {
    if (mobileCheck) {
      setPageLimit(5);
    } else {
      setPageLimit(10);
    }
  }, [mobileCheck]);

  useEffect(() => {
    checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    window.addEventListener("resize", () => {
      checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
      });
    };
  }, []);

  useEffect(() => {
    getValidatorContract();
  }, [defaultProvider]);

  useEffect(() => {
    getValidatorList();
  }, [validatorContract, account, transactionHash]);

  const [schedule_1, setSchedule_1] = useState("");
  const [schedule_2, setSchedule_2] = useState("");
  const [pageArray, setPageArray] = useState("");

  useEffect(() => {
    if (totalPageNum != 0) {
      let push = [];
      push.push(`/`);
      for (let i = 1; i <= totalPageNum; i++) {
        push.push(`/${i}`);
      }
      setPageArray(push);
    }
  }, [totalPageNum]);

  // account O
  useEffect(() => {
    let schedules = "";
    if (
      validatorContract != "" &&
      account != "" &&
      schedule_1 == "" &&
      pageArray != ""
    ) {
      schedules = schedule.scheduleJob("1", scheduleTime(), () => {
        if (!pageArray.includes(window.location.pathname)) {
          schedule.cancelJob("1");
        } else {
          getValidatorList();
        }
      });
      setSchedule_1(schedules);
    } else if (account == "" && schedule_1 != "") {
      schedule_1.cancel();
      setSchedule_1("");
    }
  }, [validatorContract, account, schedule_1, pageArray]);

  const onClickViewSetRegisterValidator = () => {
    setModalStatus(true);
    setModalType("setRegisterValidator");
  };

  const onClickSetRegiter = async (item) => {
    let defaultWeb3 = new Web3(defaultProvider);
    let stakeAmount = defaultWeb3.utils.toWei(`${item[2].value}`);
    let sendAccount = item[0].value;
    let commission = new BN(item[1].value).mul(new BN(`${100}`)).toString();
    const web3 = new Web3(windowEther);
    try {
      setModalType("confirm");
      const contract = new web3.eth.Contract(
        StakingABI,
        validatorContractAddress
      );
      let result = await contract.methods
        .registerValidator(`${sendAccount}`, `${commission}`)
        .send({
          from: account,
          value: stakeAmount,
        });
      if (result.status == true) {
        setModalType("complete");
      } else {
        setModalType("failed");
      }
      dispatch(setTransactionHash(result.transactionHash));
    } catch (error) {
      console.log("error ==> ", error);
      // if (error.code == 4001) {
      setModalType("refuse");
      // }
    }
  };

  const onClickWallet = (type) => {
    if (type == "MetaMask") {
      return onClickMetamask();
    } else if (type == "WalletConnect") {
      return onClickWalletConnect();
    }
    setModalStatus(false)

  };

  const onClickMetamask = async () => {
    if (window.ethereum != undefined) {
      let web3MetaMask;
      let accounts;
      let chainNum;
      let windowEthers;
      // 지갑이 2개 이상
      if (window.ethereum.overrideIsMetaMask) {
        for (const [key, value] of window.ethereum.providerMap.entries()) {
          if (key == "MetaMask") {
            web3MetaMask = new Web3(value);
            windowEthers = value;
          }
        }
      } else if (
        // 메타마스크만 있을 때
        window.ethereum.overrideIsMetaMask == undefined &&
        window.ethereum.isMetaMask
      ) {
        web3MetaMask = new Web3(window.ethereum);
        windowEthers = window.ethereum;
      } else if (
        // 메타마스크가 없을 때
        window.ethereum.isMetaMask == false
      ) {
        return window.open(
          `https://metamask.app.link/dapp/${process.env.REACT_APP_URL}`
        );
      }
      let getObjString = window.sessionStorage.getItem("GMMT_Staking");
      accounts = await windowEthers.send("eth_requestAccounts");
      accounts = accounts.result[0];
      chainNum = await web3MetaMask.eth.getChainId();
      if (String(chainNum) == chainId) {
        // dispatch(setWeb3(web3MetaMask));
        dispatch(setAccount(accounts));
        dispatch(setActive(true));
        dispatch(setWindowEther(windowEthers));
        // setModalStatus(false);
        let timestamp = Math.floor(+new Date() / 1000);
        let obj = {
          value: "inject",
          type:"metamask",
          expire: timestamp + 3600, // 1 hours
          // expire: timestamp + 3600, // 1 hours
        };
        if (!getObjString) {
          const objString = JSON.stringify(obj);
          window.sessionStorage.setItem("GMMT_Staking", objString);
        }
      } else {
        dispatch(setAccount(""));
        dispatch(setActive(""));
        dispatch(setWindowEther(""));
        onChangeNetWork(windowEthers);
      }
    } else {
      // 지갑이 없을 때
      window.open(
        `https://metamask.app.link/dapp/${process.env.REACT_APP_URL}`
      );
    }
  };

  const onChangeNetWork = async (providerWeb3, type) => {
    let netWorkVersion;
    let _rpcUrl;
    let blockExplorerURL;
    let chainName;
    let symbol;
    try {
      if (chainId == "88998") {
        // testnet
        netWorkVersion = "0x15ba6";
        _rpcUrl = "https://data-seed-premmt-1.mmtscan.io";
        blockExplorerURL = "https://testnet.mmtscan.io";
        chainName = "Mammoth TestNet";
        symbol = "MMT";
      } else if (chainId == "8898") {
        //  mainnet
        netWorkVersion = "0x22c2";
        _rpcUrl = "https://dataseed.mmtscan.io";
        blockExplorerURL = "https://mmtscan.io";
        chainName = "Mammoth Pro";
        symbol = "MMT";
      } else if (chainId == "5") {
        netWorkVersion = "0x5";
        _rpcUrl = "https://rpc.ankr.com/eth_goerli";
        blockExplorerURL = "https://goerli.etherscan.io";
        chainName = "Goerli Test Net";
        symbol = "Goerli";
      } else if (chainId == "1") {
        netWorkVersion = "0x1";
        _rpcUrl = "https://rpc.ankr.com/eth";
        blockExplorerURL = "https://etherscan.io";
        chainName = "Ethereum Mainnet";
        symbol = "ETH";
      } else if (chainId == "3004") {
        netWorkVersion = "0xBBC";
        _rpcUrl = process.env.REACT_APP_NETWORK_RPC;
        blockExplorerURL = "";
        chainName = "GiantMammoth Testnet";
        symbol = "GMMT";
      } else if (chainId == "8989") {
        netWorkVersion = "0x231D";
        _rpcUrl = "https://rpc-asia.gmmtchain.io";
        blockExplorerURL = "https://scan.gmmtchain.io";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      } else if (chainId == "898989") {
        netWorkVersion = "0xDB7AD";
        _rpcUrl = "https://testnet-rpc.gmmtchain.io";
        blockExplorerURL = "https://scan.gmmtchain.io";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      }

      let result = await providerWeb3.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: netWorkVersion,
          },
        ],
      });
    } catch (error) {
      if (error.code == 4001) {
        onClickDisconnect();
      } else {
        try {
          let a = await providerWeb3.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: netWorkVersion,
                chainName: chainName,
                rpcUrls: [_rpcUrl],
                nativeCurrency: {
                  name: chainName,
                  symbol: symbol,
                  decimals: 18,
                },
                blockExplorerUrls: [blockExplorerURL],
              },
            ],
          });
        } catch (addError) {
          console.log("addError", addError);
        }
      }
    }
  };

  const onClickDisconnect = async () => {
    dispatch(setAccount(""));
    dispatch(setActive(false));
    dispatch(setBalance(""));
    dispatch(setIsOwner(false));
    dispatch(setProvider(""))
    window.sessionStorage.removeItem("GMMT_Staking");
    window.localStorage.removeItem("walletconnect");
    setModalStatus(false);
  };

  const onClickWalletConnect = async () => {
    try {
      let result = await provider.enable();
      if (result != undefined) {
        const reChainId = await provider.chainId;
        if (provider.connected && String(reChainId) == String(chainId)) {
          // dispatch(setWeb3(new Web3(provider)));
          // dispatch(setProvider(windowEthers));
          dispatch(setAccount(result[0]));
          dispatch(setActive(true));
          dispatch(setWindowEther(provider));
          let timestamp = Math.floor(+new Date() / 1000);
          let obj = {
            value: "inject",
            type:"walletConnect",
            expire: timestamp + 3600, // 1 hours
            // expire: timestamp + 3600, // 1 hours
          };
          let getObjString = window.sessionStorage.getItem("GMMT_Staking");
          if (!getObjString) {
            const objString = JSON.stringify(obj);
            window.sessionStorage.setItem("GMMT_Staking", objString);
          }
          // setModalStatus(false);
        } else {
          onChangeNetWork(provider, "Wallet");
        }
      }
    } catch (error) {
      if(provider!=""){
        await provider.qrcodeModal.close();
        dispatch(setProvider(""));
        try {
        } catch (error) {
          if (provider == "") {
            onClickDisconnect();
          }
        }
      }
    }
  };

  const [loadingDecimal,setLoadingDecimal] = useState();
  const settingLoadingDecimal = () => {
    let decimal = "";
    setInterval(() => {
      decimal = decimal.concat(".");
      if(decimal == "...."){
        decimal = ""
      }
      setLoadingDecimal(decimal)
      // console.log(decimal)
    },[1000])
  }

  useEffect(()=>{
    settingLoadingDecimal();
  },[])

  // account X
  useEffect(() => {
    let schedules = "";
    if (
      validatorContract != "" &&
      account == "" &&
      schedule_2 == "" &&
      pageArray != ""
    ) {
      schedules = schedule.scheduleJob("2", scheduleTime(), () => {
        if (!pageArray.includes(window.location.pathname)) {
          schedule.cancelJob("2");
        } else {
          getValidatorList();
        }
      });
      setSchedule_2(schedules);
    } else if (account != "" && schedule_2 != "") {
      schedule_2.cancel();
      setSchedule_2("");
    }
  }, [validatorContract, account, schedule_2, pageArray]);

  useLayoutEffect(() => {
    let page = pathname.substring(1, pathname.length);
    if (pathname == "/") {
      page = 1;
    }
    getViewValidatorList(page);
  }, [validatorList, nowPageNum, pathname]);

  useEffect(() => {
    if (account == "") {
      setModalStatus(false);
    }
  }, [account]);
  
  return (
    <div className={styles.content}>
      <NavBar styles={styles} onClick={onClickViewSetRegisterValidator} />
      <div className={`${styles.tableNormal} ${styles.tableValidator}`}>
        <div className={`${styles.thead}`}>
          <div className={`${styles.rank}`}>Rank</div>
          <div className={`${styles.validator}`}>Validator</div>
          <div className={`${styles.status}`}>Status</div>
          <div className={`${styles.total}`}>Total Delegated</div>
          <div className={`${styles.commission}`}>Commission Rate</div>
          <div className={`${styles.amount}`}>Delegated Amount</div>
          <div className={`${styles.button}`}></div>
        </div>
        {loading ? (
            <div className={styles.mainLoading}>Loading{loadingDecimal}</div>
        ) : validatorList != "" ? (
          validatorList.map((item, index) => (
            <TbodyItem
              key={index}
              item={item}
              index={index}
              nowPageNum={nowPageNum}
              limit={limit}
              styles={styles}
              stakeModal={stakeModal}
            />
          ))
        ) : (
          ""
        )}
      </div>
      <div className={styles.mainFooter}>
        <ViewPage
          totalPage={totalPageNum}
          nowPage={nowPageNum}
          pageLimit={pageLimit}
          styles={styles}
          path={"/"}
          visibility={true}
        />
        <div className={styles.mainRegister}>
          {account != "" ? (
            <RegisterValidator
              styles={styles}
              onClick={onClickViewSetRegisterValidator}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {modalStatus ? (
        modalType == "connect" ? (
          <BaseModal
            styles={styles}
            type={modalType}
            onClick={onClickWallet}
            onClickCancel={() => {
              setModalStatus(false);
            }}
            setModalStatus={setModalStatus}
          />
        ) : (
          <TradeModal
            styles={styles}
            type={modalType}
            item={modalItem}
            onClickCancel={() => {
              setModalStatus(false);
            }}
            click={modalType == "stake" ? onClickDelegate : onClickSetRegiter}
          />
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default Main_v2;
