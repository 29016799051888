import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { fromWei, fromWeiToFixed } from "../../common";

const Disconnect = ({ onClickCancel, onClickDisConnect, styles }) => {
  const defaultProvider= useSelector((state) => state.user.defaultProvider);
  const account = useSelector((state) => state.account.account);
  const balance = useSelector((state) => state.user.balance);

  return (
    <div id={styles.walletInfoPop} className={styles.walletInfoPopup}>
      <div className={styles.walletInfoPopupDim} />
      <div className={styles.walletInfo}>
        <div className={styles.title}>
          <span>My Wallet</span>
          <img
            src={require("../../assets/images/ico_close_bl.png")}
            className={styles.closeBtn}
            onClick={onClickCancel}
          />
        </div>
        <div className={styles.myAddress}>
          <div className={styles.myAddressTitle}>My Address</div>
          <div className={styles.addressNumber}>{account}</div>
        </div>
        <div className={styles.myBalance}>
          <div className={styles.myBalanceTitle}>My Balance</div>
          <div className={styles.balanceList}>
            <div className={styles.balance}>
              <div>
                <img
                  src={require("../../assets/images/Gmmt_logo_sym_col.png")}
                />
                <span>GMMT</span>
                {/* <Span_2 className="copied">copied</Span_2> */}
              </div>
              <span>{fromWeiToFixed(defaultProvider, balance)}</span>
            </div>
          </div>
        </div>
        <button className={styles.disconnectBtn} onClick={onClickDisConnect}>
          Disconnect
        </button>
      </div>
    </div>
  );
};

export default Disconnect;
