import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  jsonTitle,
  setFromWeiBalance,
} from "../../common/index";
import BtnBdBlue from "../Buttons/BtnBdBlue";
import BtnBlue from "../Buttons/BtnBlue";

const TbodyItem = ({
  styles
}) => {
  
  return (
    <div className={`${styles.tbody}`}>
      <div className={`${styles.no} ${styles.fwb}`}>
        ○
      </div>
      <div className={`${styles.title}`} title="To everyone who wants to participate in the governance of Giant Mammoth">
        <Link to={`/governance/notice`}>
          <div className={`${styles.img}`}>
            {/* <img src={require("")} /> */}
            <img />
          </div>
          <p className={`${styles.name} ${styles.fwb}`}>
            {"To everyone who wants to participate in the governance of Giant Mammoth"}
          </p>
        </Link>
      </div>
      <div className={`${styles.starTime}`}>{"1"}</div>
      <div className={`${styles.endTime}`}>{"-"}</div>
      <div className={`${styles.proposalVotes}`}>
      {"-"}
        {/* {setFromWeiBalance(defaultProvider, item.proposalVotes)} */}
      </div>
      <div className={`${styles.button}`}>
        {/* <BtnBdBlue
          className={`${styles.btnBdBlue}`}
          styles={`${styles.btnNormal} ${styles.btnBdBlue} ${
            account == ""
              ? styles.unAvailableBtn
              : btnStatus
              ? ""
              : styles.unAvailableBtn
          }`}
          text={text}
          item={item}
          click={clickEventList}
        /> */}
      </div>
    </div>
  );
};

export default TbodyItem;

// stake 버튼 만들기
