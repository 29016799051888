import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import TotalAssetBox from "../Components/ValidatorAssets/TotalAssetBox";
import StakingABI from "../abi/Staking.json";
import NavBar from "../Components/Navbar/NavBar.js";
import Web3 from "web3";
import { setTransactionHash } from "../redux/user";
import { BN } from "bn.js";
import SubTabWrap from "../Components/ValidatorAssets/SubTabWrap";
import TableMyStaking from "../Components/ValidatorAssets/TableMyStaking";
import MyHistory from "../Components/ValidatorAssets/MyHistory";
import ViewPage from "../Components/Page/ViewPage";
import TradeModal from "../Components/Modals/TradeModal";
import moment, { now } from "moment";
import { addArray, scheduleTime } from "../common";
import schedule from "node-schedule";
import NoResults from "../Components/ValidatorAssets/NoResults";

const ValidatorAssets = ({ styles }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  const account = useSelector((state) => state.account.account);
  // const web3 = useSelector((state) => state.user.web3);
  const windowEther = useSelector((state) => state.user.windowEther);
  const transactionHash = useSelector((state) => state.user.transactionHash);
  const validatorContractAddress =
    process.env.REACT_APP_PUBLIC_STAKING_CONTRACT;

  const { pathname } = useLocation();
  const navigator = useNavigate();
  const dispatch = useDispatch();

  const [validatorContract, setValidatorContract] = useState();
  const [validatorList, setValidatorList] = useState([]);

  const [schedule_1, setSchedule_1] = useState("");
  const [pageArray, setPageArray] = useState("");
  const [historyList, setHistoryList] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalItem, setModalItem] = useState();
  const [viewList, setViewList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPageNum, setTotalPageNum] = useState(0);
  const [nowPageNum, setNowPageNum] = useState();
  const [limit, setLimit] = useState(10);
  const [pageLimit, setPageLimit] = useState(10);
  const [mobileCheck, setMobileCheck] = useState(false); // false이면 데탑;
  const [option, setOption] = useState([
    {
      name: "Staked Assets",
      path: "/validatorAssets/stakedAssets",
    },
    {
      name: "History",
      path: "/validatorAssets/history",
    },
  ]);
  const [nowOption, setNowOption] = useState(option[0].path);
  const [optionParams, setOptionParams] = useState({
    "Staked Assets": [
      {
        name: "Validator",
        className: styles.validator,
      },
      {
        name: "Owner",
        className: styles.address,
      },
      {
        name: "Claimable Asset",
        className: styles.claimable,
      },
      {
        name: "Pending Asset",
        className: styles.claimable,
      },
      {
        name: "",
        className: styles.button,
      },
    ],
    History: [
      {
        name: "Validator",
        className: styles.validator,
      },
      {
        name: "Address",
        className: styles.address,
      },
      {
        name: "Amount",
        className: styles.amount,
      },
      {
        name: "Type",
        className: styles.type,
      },
      {
        name: "Epoch",
        className: styles.epoch,
      },
      {
        name: "Block",
        className: styles.block,
      },
      {
        name: "Time",
        className: styles.time,
      },
    ],
  });
  const [nowOptionParams, setNowOptionParams] = useState(
    optionParams[option[0].name]
  );

  const getValidatorContract = async () => {
    if (defaultProvider != "") {
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        StakingABI,
        validatorContractAddress
      );

      setValidatorContract(contract);
    }
  };

  const getValidatorList = async () => {
    if (validatorContract != undefined) {
      let list = await validatorContract.methods.getValidators().call();
      let validatorAdded = await validatorContract.getPastEvents(
        "ValidatorAdded",
        {
          filter: {},
          fromBlock: 0,
          toBlock: "latest",
        }
      );
      let result;
      let promise = [];
      try {
        for (let i = 0; i < validatorAdded.length; i++) {
          let address = validatorAdded[i].returnValues.validator;
          let isValidator = await validatorContract.methods
            .isValidator(`${address}`)
            .call();
          if (isValidator) {
            promise.push(
              new Promise(async (resolve, reject) => {
                let validatorStatus = await validatorContract.methods
                  .getValidatorStatus(address)
                  .call();
                let validatorFee = {
                  getValidatorFee: 0,
                  getPendingValidatorFee: 0,
                };

                if (
                  account.toLowerCase() ==
                  validatorStatus.ownerAddress.toLowerCase()
                ) {
                  try {
                    validatorFee.getValidatorFee = await validatorContract.methods
                      .getValidatorFee(address)
                      .call();
                    validatorFee.getPendingValidatorFee =
                      await validatorContract.methods
                        .getPendingValidatorFee(address)
                        .call();
                  } catch (error) {
                    console.log(error)
                  }
                }

                let sendData = {
                  // 값 입력
                  validatorAddress: address,
                  status: validatorStatus.status,
                  commissionRate: validatorStatus.commissionRate,
                  ownerAddress: validatorStatus.ownerAddress,
                  getValidatorFee: validatorFee.getValidatorFee,
                  getPendingValidatorFee: validatorFee.getPendingValidatorFee,
                };

                resolve(sendData);
              })
            );
          }
        }
      } catch (error) {
        console.log("/", error);
      }
      Promise.all(promise).then((values) => {
        let data = values
        // .filter((data) => {
        //   return data != "";
        // });

        data = data.filter((data) => {
          return data.ownerAddress.toLowerCase() == account.toLowerCase();
        });

        data.sort((a, b) => {
          return b.getValidatorFee - a.getValidatorFee;
        });

        setValidatorList(data);
      });
    }
  };

  const setTimestamp = async (blockNumber) => {
    const web3 = new Web3(defaultProvider);
    let blockData = await web3.eth.getBlock(blockNumber);
    let date = moment.unix(blockData.timestamp).format("YYYY-MM-DD HH:mm:ss");
    return date;
  };

  const getHistory = async () => {
    let validatorOwnerClaimed;
    let eventList = [];
    let promise_1 = [];

    try {
      // history 다시 만들기
      if (
        validatorContract != undefined &&
        account != "" &&
        validatorList != ""
      ) {
        for (let i = 0; i < validatorList.length; i++) {
          let address = validatorList[i].validatorAddress;
          validatorOwnerClaimed = await validatorContract.getPastEvents(
            "ValidatorOwnerClaimed",
            {
              filter: { validator: address },
              fromBlock: 0,
              toBlock: "latest",
            }
          );

          eventList = validatorOwnerClaimed;
          let setData;
          for (let i = 0; i < eventList.length; i++) {
            promise_1.push(
              new Promise(async (resolve, reject) => {
                setData = {
                  type: "Claimed",
                  blockNumber: eventList[i].blockNumber,
                  transactionHash: eventList[i].transactionHash,
                  validator: eventList[i].returnValues.validator,
                  epoch: eventList[i].returnValues.epoch,
                  amount: eventList[i].returnValues.amount,
                  time: await setTimestamp(eventList[i].blockNumber),
                };
                resolve(setData);
              })
            );
          }
        }

        Promise.all(promise_1).then((values) => {
          values.sort((a, b) => {
            return b.blockNumber - a.blockNumber;
          });
          setHistoryList(values);
        });
      }
    } catch (error) {
      console.log("==>", error);
    }
  };

  const setPathNameOption = () => {
    let pathSplit = pathname.split("/");
    pathSplit = "/".concat(pathSplit[1]).concat("/").concat(pathSplit[2]);
    let findOption = option.find((a) => {
      return a.path == pathSplit;
    });
    if (findOption == undefined) {
      navigator("/");
    } else {
      setNowOptionParams(optionParams[findOption.name]);
      setNowOption(pathSplit);
    }
  };

  const getViewList = (page) => {
    let pathSplit = pathname.split("/");
    pathSplit = "/".concat(pathSplit[1]).concat("/").concat(pathSplit[2]);
    let nowList = pathSplit == option[0].path ? validatorList : historyList;

    setLoading(true);
    let totalPageNum = Math.ceil(nowList.length / limit);
    if (totalPageNum == 0) totalPageNum = 1;
    setTotalPageNum(totalPageNum);
    setNowPageNum(page);
    if (nowList != "") {
      if (totalPageNum >= Number(page)) {
        let resultList = [];
        for (let i = limit * page - limit; i < limit * page; i++) {
          if (nowList[i] == undefined) {
            break;
          }
          resultList.push(nowList[i]);
        }
        setViewList(resultList);
        setLoading(false);
      } else {
        // window.location.href = "/";
      }
    } else {
      setViewList("");
      setLoading(false);
    }
  };

  const onClickModal = (item, type) => {
    if (account != "") {
      setModalStatus(true);
      setModalType(type);
      setModalItem(item);
    }
  };

  const onClickValidatorClaim = async (address) => {
    if (account != "") {
      try {
        const web3 = new Web3(windowEther)
        const contract = new web3.eth.Contract(
          StakingABI,
          validatorContractAddress
        );
        setModalType("confirm");
        let result = await contract.methods.claimValidatorFee(address).send({
          from: account,
        });
        if (result.status == true) {
          setModalType("validatorClaimComplete");
        } else {
          setModalType("failed");
        }
        dispatch(setTransactionHash(result.transactionHash));
      } catch (error) {
        console.log("onClickValidatorClaim",error);
        // if (error.code == 4001) {
          setModalType("refuse");
        // }
      }
    }
  };

  const checkdetectMoileDevice = (agent, width) => {
    setMobileCheck(width <= 767);
  };

  const onClickRelease = async (address) => {
    const web3 = new Web3(windowEther)
    const contract = new web3.eth.Contract(
      StakingABI,
      validatorContractAddress
    );
    try {
      setModalType("confirm");
      let result = await contract.methods
        .releaseValidatorFromJail(address)
        .send({
          from: account,
        });
      if (result.status == true) {
        setModalType("complete");
      } else {
        setModalType("failed");
      }
      dispatch(setTransactionHash(result.transactionHash));
    } catch (error) {
      console.log("onClickRelease",error);
      // if (error.code == 4001) {
        setModalType("refuse");
      // }
    }
  };

  const onClickChangeCommission = async (address, commissionRate) => {
    const web3 = new Web3(windowEther)
    const contract = new web3.eth.Contract(
      StakingABI,
      validatorContractAddress
    );
    try {
      setModalType("confirm");
      let newCommission = new BN(`${commissionRate}`)
        .mul(new BN(`${100}`))
        .toString();
      let result = await contract.methods
        .changeValidatorCommissionRate(address, newCommission)
        .send({
          from: account,
        });
      if (result.status == true) {
        setModalType("complete");
      } else {
        setModalType("failed");
      }
      dispatch(setTransactionHash(result.transactionHash));
    } catch (error) {
      console.log("onClickChangeCommission",error);
      // if (error.code == 4001) {
        setModalType("refuse");
      // }
    }
  };

  useEffect(() => {
    if (mobileCheck) {
      setPageLimit(5);
    } else {
      setPageLimit(10);
    }
  }, [mobileCheck]);

  useEffect(() => {
    checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    window.addEventListener("resize", () => {
      checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
      });
    };
  }, []);

  useEffect(() => {
    getValidatorContract();
  }, [defaultProvider]);

  useEffect(() => {
    getValidatorList();
  }, [account, validatorContract, transactionHash]);

  useEffect(() => {
    getHistory();
  }, [validatorContract, account, validatorList]);

  useEffect(() => {
    setPathNameOption();
  }, [pathname, account, defaultProvider, nowOption]);

  useEffect(() => {
    if (totalPageNum != 0) {
      let push = [];
      push.push(`${nowOption}`);
      for (let i = 1; i <= totalPageNum; i++) {
        push.push(`${nowOption}${"/".concat(i)}`);
      }
      setPageArray(push);
    }
  }, [totalPageNum, nowOption]);

  useEffect(() => {
    let pathSplit = pathname.split("/");
    let pathSplitConcat = "/"
      .concat(pathSplit[1])
      .concat("/")
      .concat(pathSplit[2]);
    let page = 0;
    if (
      option[0].path == pathSplitConcat ||
      option[1].path == pathSplitConcat
    ) {
      if (pathSplit[3] == undefined) {
        page = 1;
      } else {
        page = pathSplit[3];
      }
    }
    getViewList(page);
  }, [pathname, validatorList, historyList]);

  useEffect(() => {
    if (account == "") {
      navigator("/");
    }
  }, [account]);

  useEffect(() => {
    let schedules = "";
    if (
      validatorContract != "" &&
      account != "" &&
      schedule_1 == "" &&
      pageArray != "" &&
      nowOption == option[0].path
    ) {
      schedules = schedule.scheduleJob("6", scheduleTime(), () => {
        if (!pageArray.includes(window.location.pathname)) {
          schedule.cancelJob("6");
          setSchedule_1("");
        } else {
          getValidatorList();
        }
      });
      setSchedule_1(schedules);
    } else if (account == "" && schedule_1 != "") {
      schedule_1.cancel();
      setSchedule_1("");
    }
  }, [validatorContract, account, schedule_1, pageArray, nowOption]);

  useEffect(() => {
    let schedules = "";
    if (
      validatorContract != "" &&
      account != "" &&
      schedule_1 == "" &&
      pageArray != "" &&
      nowOption == option[1].path
    ) {
      schedules = schedule.scheduleJob("7", scheduleTime(), () => {
        if (!pageArray.includes(window.location.pathname)) {
          schedule.cancelJob("7");
          setSchedule_1("");
        } else {
          getHistory();
        }
      });
      setSchedule_1(schedules);
    } else if (account == "" && schedule_1 != "") {
      schedule_1.cancel();
      setSchedule_1("");
    }
  }, [validatorContract, account, schedule_1, pageArray, nowOption]);

  useEffect(() => {
    if (account == "") {
      setModalStatus(false);
    }
  }, [account]);

  return (
    <div className={styles.content}>
      <NavBar styles={styles} />
      <TotalAssetBox styles={styles} item={validatorList} />
      <SubTabWrap styles={styles} option={option} nowOption={nowOption} />
      <div
        className={`${styles.tableNormal} ${
          nowOption == option[0].path
            ? styles.tableMyStaking
            : styles.tableHistory
        }`}
      >
        <div className={styles.thead}>
          {nowOptionParams.map((item, index) => (
            <div className={item.className} key={index}>
              {item.name}
            </div>
          ))}
        </div>
        {loading ? (
          ""
        ) : nowOption == option[0].path ? (
          viewList != "" ? (
            viewList.map((item, index) => (
              <TableMyStaking
                item={item}
                styles={styles}
                key={index}
                onClickModal={onClickModal}
                onClickRelease={onClickRelease}
              />
            ))
          ) : (
            <NoResults styles={styles} />
          )
        ) : viewList != "" ? (
          viewList.map((item, index) => (
            <MyHistory item={item} styles={styles} key={index} />
          ))
        ) : (
          <NoResults styles={styles} />
        )}
      </div>
      <div className={styles.mainFooter}>
        <ViewPage
          totalPage={totalPageNum}
          nowPage={nowPageNum}
          pageLimit={pageLimit}
          styles={styles}
          path={nowOption}
        />
      </div>

      {modalStatus ? (
        <TradeModal
          styles={styles}
          type={modalType}
          item={modalItem}
          onClickCancel={() => {
            setModalStatus(false);
          }}
          click={
            modalType == "validatorClaim"
              ? onClickValidatorClaim
              : modalType == "changeCommission"
              ? onClickChangeCommission
              : ""
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ValidatorAssets;
