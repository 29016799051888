import React from "react";
import { useSelector } from "react-redux";
import {  setFromWeiBalance, subString } from "../../common";

const ValidatorClaim = ({ styles, item, onClickCancel, click }) => {
    const defaultProvider = useSelector((state) => state.user.defaultProvider);

  return (
    <div className={styles.popCont}>
      <p className={styles.popTit}>
        <span>Claim Asset</span>
        <img
          src={require("../../assets/images/ico_close_bl.png")}
          alt="close_Icon"
          className={styles.closeBtn}
          onClick={onClickCancel}
        />
      </p>
      <ul className={styles.valueList}>
        <li>
          <span className={styles.tit}>Selected Validator</span>
          <span className={styles.value}>{subString(item.validatorAddress)}</span>
        </li>
        <li>
          <span className={styles.tit}>Claimable Asset</span>
          <span className={styles.value}>
            {setFromWeiBalance(defaultProvider, item.getValidatorFee)} GMMT
          </span>
        </li>
      </ul>
      <div className={styles.btnWrap}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnCancel}`}
          onClick={onClickCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnNext}`}
          onClick={()=>{
            click(item.validatorAddress);
          }}
        >
          Claim
        </button>
      </div>
    </div>
  );
}

export default ValidatorClaim