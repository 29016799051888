import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import RegisterValidator from "../Main/RegisterValidator";
import Propose from "../Votes/Propose";
import SelectBox from "../Votes/SelectBox";
import GovernanceNavBar from "./GovernanceNavBar";
import MainNavBar from "./MainNavBar";
import StakingNavBar from "./StakingNavBar";
import StakingSelectBox from "../Main/StakingSelectBox";

const NavBar = ({ styles, onClick, nowPage, validatorStatus }) => {
  const { pathname } = useLocation();
  const account = useSelector((state) => state.account.account);
  const [linkParams, setLinkParams] = useState([
    {
      name: "Staking",
      path: "/",
    },
    {
      name: "Governance",
      path: "/governance/vote",
    },
  ]);
  const [nowPathName, setNowPathName] = useState("");

  const checkPath = () => {
    let splitPathName = pathname.split("/");
    if (
      splitPathName[0] == "" &&
      (!isNaN(splitPathName[1]) ||
        splitPathName[1] == "" ||
        splitPathName[1] == "inActive" ||
        splitPathName[1] == "validatorAssets" ||
        splitPathName[1] == "myStaking")
    ) {
      setNowPathName("Staking");
    } else if (splitPathName[0] == "" && splitPathName[1] == "governance") {
      setNowPathName("Governance");
    }
  };

  const [nowPathName_2, setNowPathName_2] = useState("");
  const [linkParams_2, setLinkParams_2] = useState([
    {
      name: "Validators",
      path: "/",
    },
    {
      name: "Validators",
      path: "/inActive",
    },
    {
      name: "My staking",
      path: "/myStaking/stakedAssets",
    },
    {
      name: "Validator Assets",
      path: "/validatorAssets/stakedAssets",
    },
  ]);

  const checkPath_2 = () => {
    let splitPathName = pathname.split("/");
    if (
      splitPathName[0] == "" &&
      (!isNaN(splitPathName[1]) || splitPathName[1] == "") ||(!isNaN(splitPathName[1]) || splitPathName[1] == "inActive")
    ) {
      setNowPathName_2("Validators");
    }
    if (splitPathName[0] == "" && splitPathName[1] == "validatorAssets") {
      setNowPathName_2("Validator Assets");
    } else if (splitPathName[0] == "" && splitPathName[1] == "myStaking") {
      setNowPathName_2("My Staking");
    }
  };

  const [mobileCheck, setMobileCheck] = useState(false); // false이면 데탑;

  useLayoutEffect(() => {
    checkPath();
  }, [pathname]);

  useLayoutEffect(() => {
    checkPath_2();
  }, [pathname]);

  const checkdetectMoileDevice = (agent, width) => {
    const mobileRegex = [
      /Android/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    // setMobileCheck(mobileRegex.some(mobile => agent.match(mobile)));
    setMobileCheck(width <= 767);
  };

  useEffect(() => {
    checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    window.addEventListener("resize", () => {
      checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
      });
    };
  }, []);
  
  return (
    <div>
      {/* <MainNavBar styles={styles} /> */}
      {nowPathName == "Staking" ? (
        //여기부터 직선 없애기
        <div
          className={
            mobileCheck
              ? nowPathName_2.toLocaleLowerCase() ==
                linkParams_2[0].name.toLocaleLowerCase()
                ? styles.mobileStakingNavBar
                : ""
              : styles.stakingNavbar
          }
        >
          <StakingNavBar styles={styles} />
          {nowPathName_2.toLocaleLowerCase() ==
            linkParams_2[0].name.toLocaleLowerCase() ||
          nowPathName_2.toLocaleLowerCase() ==
            linkParams_2[1].name.toLocaleLowerCase() ? (
            <div className={styles.governanceNavbarRight}>
              <StakingSelectBox styles={styles} />
              {account != "" ? (
                <RegisterValidator
                  styles={styles}
                  bool={false}
                  // bool={mobileCheck}
                  onClick={onClick}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div
          className={
            mobileCheck
              ? styles.mobileGovernanceNavBar
              : styles.governanceNavbar
          }
        >
          <GovernanceNavBar styles={styles} />
          <div className={styles.governanceNavbarRight}>
            <SelectBox styles={styles} nowPage={nowPage} />
            {validatorStatus ? <Propose styles={styles} /> : ""}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
