import { BN } from "bn.js";
import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Web3 from "web3";
import BtnBlue from "./Buttons/BtnBlue";

import StakingABI from "../abi/Staking.json";
import {
  setActive,
  setBalance,
  setIsOwner,
  setProvider,
  setWindowEther,
} from "../redux/user";
import WalletConnectProvider from "@walletconnect/web3-provider";
import BaseModal from "./Modals/BaseModal";
import Disconnect from "./Modals/Disconnect";
import { Link, useLocation } from "react-router-dom";

import styles from "../assets/css/GMMTStaking.module.css";
import { setAccount } from "../redux/account";

import schedule from "node-schedule";
import { scheduleTime } from "../common";


const Header = () => {
  const dispatch = useDispatch();

  const chainId = process.env.REACT_APP_NETWORK_CHAINID;
  const account = useSelector((state) => state.account.account);
  const active = useSelector((state) => state.user.active);
  const windowEther = useSelector((state) => state.user.windowEther);
  const provider = useSelector((state) => state.user.provider);
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  const transactionHash = useSelector((state) => state.user.transactionHash);

  const StakingAddress = process.env.REACT_APP_PUBLIC_STAKING_CONTRACT;

  const [modalStatus, setModalStatus] = useState(false);
  const [modalType, setModalType] = useState("");
  const [subAccount, setSubAccount] = useState("");
  const [schedule_1, setSchedule_1] = useState("");
  const [schedule_2, setSchedule_2] = useState("");


  const [headerPath, setHeaderPath] = useState([
    {
      name: "Staking",
      path: "/",
    },
    {
      name: "Governance",
      path: "/governance/vote/all",
    },
  ]);

  const stakingPathList = [
    "/address",
    "/",
    "/inActive",
    "/myStaking/stakedAssets",
    "/myStaking/history",
    "/validatorAssets/stakedAssets",
    "/validatorAssets/history",
  ];

  const [nowName, setNowName] = useState("");
  const { pathname } = useLocation();

  const onClickMetamask = async () => {
    if (window.ethereum != undefined) {
      let web3MetaMask;
      let accounts;
      let chainNum;
      let windowEthers;
      // 지갑이 2개 이상
      if (window.ethereum.overrideIsMetaMask) {
        for (const [key, value] of window.ethereum.providerMap.entries()) {
          if (key == "MetaMask") {
            web3MetaMask = new Web3(value);
            windowEthers = value;
          }
        }
      } else if (
        // 메타마스크만 있을 때
        window.ethereum.overrideIsMetaMask == undefined &&
        window.ethereum.isMetaMask
      ) {
        web3MetaMask = new Web3(window.ethereum);
        windowEthers = window.ethereum;
      } else if (
        // 메타마스크가 없을 때
        window.ethereum.isMetaMask == false
      ) {
        return window.open(
          `https://metamask.app.link/dapp/${process.env.REACT_APP_URL}`
        );
      }
      let getObjString = window.sessionStorage.getItem("GMMT_Staking");
      accounts = await windowEthers.send("eth_requestAccounts");
      // accounts = accounts.result[0];
      accounts = accounts.result!=undefined?accounts.result[0]:accounts[0];
      chainNum = await web3MetaMask.eth.getChainId();
      if (String(chainNum) == chainId) {
        // dispatch(setWeb3(web3MetaMask));
        dispatch(setAccount(accounts));
        dispatch(setActive(true));
        dispatch(setWindowEther(windowEthers));
        // setModalStatus(false);
        let timestamp = Math.floor(+new Date() / 1000);
        let obj = {
          value: "inject",
          type:"metamask",
          expire: timestamp + 3600, // 1 hours
          // expire: timestamp + 3600, // 1 hours
        };
        if (!getObjString) {
          const objString = JSON.stringify(obj);
          window.sessionStorage.setItem("GMMT_Staking", objString);
        }
      } else {
        dispatch(setAccount(""));
        dispatch(setActive(""));
        dispatch(setWindowEther(""));
        onChangeNetWork(windowEthers);
      }
    } else {
      // 지갑이 없을 때
      window.open(
        `https://metamask.app.link/dapp/${process.env.REACT_APP_URL}`
      );
    }
  };

  const onChangeNetWork = async (providerWeb3, type) => {
    let netWorkVersion;
    let _rpcUrl;
    let blockExplorerURL;
    let chainName;
    let symbol;
    try {
      if (chainId == "88998") {
        // testnet
        netWorkVersion = "0x15ba6";
        _rpcUrl = "https://data-seed-premmt-1.mmtscan.io";
        blockExplorerURL = "https://testnet.mmtscan.io";
        chainName = "Mammoth TestNet";
        symbol = "MMT";
      } else if (chainId == "8898") {
        //  mainnet
        netWorkVersion = "0x22c2";
        _rpcUrl = "https://dataseed.mmtscan.io";
        blockExplorerURL = "https://mmtscan.io";
        chainName = "Mammoth Pro";
        symbol = "MMT";
      } else if (chainId == "5") {
        netWorkVersion = "0x5";
        _rpcUrl = "https://rpc.ankr.com/eth_goerli";
        blockExplorerURL = "https://goerli.etherscan.io";
        chainName = "Goerli Test Net";
        symbol = "Goerli";
      } else if (chainId == "1") {
        netWorkVersion = "0x1";
        _rpcUrl = "https://rpc.ankr.com/eth";
        blockExplorerURL = "https://etherscan.io";
        chainName = "Ethereum Mainnet";
        symbol = "ETH";
      } else if (chainId == "3004") {
        netWorkVersion = "0xBBC";
        _rpcUrl = process.env.REACT_APP_NETWORK_RPC;
        blockExplorerURL = "";
        chainName = "GiantMammoth Testnet";
        symbol = "GMMT";
      } else if (chainId == "8989") {
        netWorkVersion = "0x231D";
        _rpcUrl = "https://rpc-asia.gmmtchain.io";
        blockExplorerURL = "https://scan.gmmtchain.io";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      } else if (chainId == "898989") {
        netWorkVersion = "0xDB7AD";
        _rpcUrl = "https://testnet-rpc.gmmtchain.io";
        blockExplorerURL = "https://testnet-scan.gmmtchain.io/";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      }else if (chainId == "1818") {
        netWorkVersion = "0x71A";
        _rpcUrl = "http://192.168.0.100:8555";
        blockExplorerURL = "https://scan.gmmtchain.io";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      }

      let result = await providerWeb3.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: netWorkVersion,
          },
        ],
      });
    } catch (error) {
      if (error.code == 4001) {
        onClickDisconnect();
      } else {
        try {
          let a = await providerWeb3.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: netWorkVersion,
                chainName: chainName,
                rpcUrls: [_rpcUrl],
                nativeCurrency: {
                  name: chainName,
                  symbol: symbol,
                  decimals: 18,
                },
                blockExplorerUrls: [blockExplorerURL],
              },
            ],
          });
        } catch (addError) {
          console.log("addError", addError);
        }
      }
    }
  };

  const checkInject = async () => {
    let walletconnect = window.localStorage.getItem("walletconnect");
    walletconnect = JSON.parse(walletconnect);
    let objString = window.sessionStorage.getItem("GMMT_Staking");
    try {
      if (objString == null) {
        // return onClickDisconnect();
      } else if (objString != null) {
        let json = JSON.parse(objString);
        let timestamp = Math.floor(+new Date() / 1000);

        if (timestamp > json.expire) {
          return onClickDisconnect();
        } else {
          if (json.type == undefined) {
            return onClickDisconnect();
          } else if (json.type == "metamask") {
            return onClickMetamask();
          } else if (json.type == "walletConnect") {
            if (walletconnect == null) {
              return onClickDisconnect();
            } else {
              return onClickWalletConnect();
            }
          }
        }
      }
    } catch (error) {
      console.log("checkInject", error);
    }
  };

  const checkInjectUseCallback = useCallback(async()=>{
    let walletconnect = window.localStorage.getItem("walletconnect");
    walletconnect = JSON.parse(walletconnect);
    let objString = window.sessionStorage.getItem("GMMT_Staking");
    try {
      if (objString == null) {
        return onClickDisconnect();
      }else if(objString != null){
        let json = JSON.parse(objString);
        let timestamp = Math.floor(+new Date() / 1000);
  
        if(timestamp > json.expire){
          return onClickDisconnect();
        }else{
          if(json.type == undefined){
            return onClickDisconnect();
          }else if (json.type == "metamask") {
            return onClickMetamask();
          }else if(json.type == "walletConnect"){
            if(walletconnect==null){
              return onClickDisconnect();
            }else{
              return onClickWalletConnect();
            }
          }
        }
      }
    } catch (error) {
      console.log("error ==> ",error)
    }
  },[])
  
  // async () => {
  // };

  const onClickDisconnect = async () => {
    dispatch(setAccount(""));
    dispatch(setActive(false));
    dispatch(setBalance(""));
    dispatch(setIsOwner(false));
    dispatch(setProvider(""));
    dispatch(setWindowEther(""))
    window.sessionStorage.removeItem("GMMT_Staking");
    window.localStorage.removeItem("walletconnect");
    setModalStatus(false);
  };

  if (windowEther != "") {
    windowEther.on("accountsChanged", async function (accountsed) {
      if (account != accountsed[0]) {
        onClickDisconnect();
      }
    });

    windowEther.on("chainChanged", async function (chainIds) {
      onClickDisconnect();
    });
  }
  if (provider != "") {
    console.log(provider != "" , provider != undefined)
    provider.on("accountsChanged", async (accounts) => {
      if (account != "" && account != accounts[0]) {
        onClickDisconnect();
      }
    });

    provider.on("chainChanged", async (chainIds) => {
      if (chainIds != chainId) {
        onClickDisconnect();
      }
    });
  }

  const accountReNumber = () => {
    if (account != undefined) {
      const startStr = account.substring(0, 6);
      const endStr = account.substring(account.length, account.length - 5);
      let text = "";
      if (account != "") {
        text = startStr + "..." + endStr;
      }
      setSubAccount(text);
    }
  };
  const connectWalletModal = () => {
    setModalStatus(true);
    setModalType("connect");
  };
  const disConnectWalletModal = () => {
    setModalStatus(true);
    setModalType("disConnect");
  };

  const getBalance = async () => {
    if (windowEther != "" && account != "") {
      const web3 = new Web3(windowEther)
      let haveAmount = await web3.eth.getBalance(account);
      dispatch(setBalance(haveAmount));
    }
  };

  const onClickWallet = (type) => {
    if (type == "MetaMask") {
      onClickMetamask();
      setModalStatus(false);
      return 
    } else if (type == "WalletConnect") {
      onClickWalletConnect();
      setModalStatus(false);
      return 
    }
  };

  const getProvider = () => {
    let providers = "";
    if (provider == "") {
      // providers = new WalletConnectProvider({
      //   rpc: {
      //     88998: "https://data-seed-premmt-1.mmtscan.io",
      //     8898: "https://dataseed.mmtscan.io",
      //     5: "https://rpc.ankr.com/eth_goerli",
      //     1: "https://rpc.ankr.com/eth",
      //     898989: "https://testnet-rpc.gmmtchain.io",
      //     8989: "https://rpc-asia.gmmtchain.io",
      //   },
      //   bridge: "https://bridge.walletconnect.org",
      //   qrcode: true,
      //   sessionRequestTimeout: 60000
      // });
      dispatch(setProvider(providers));
    }
  };

  const onClickWalletConnect = async () => {
    try {
      let result = await provider.enable();
      if (result != undefined) {
        const reChainId = await provider.chainId;
        if (provider.connected && String(reChainId) == String(chainId)) {
          // dispatch(setWeb3(new Web3(provider)));
          // dispatch(setProvider(windowEthers));
          dispatch(setAccount(result[0]));
          dispatch(setActive(true));
          dispatch(setWindowEther(provider));
          let timestamp = Math.floor(+new Date() / 1000);
          let obj = {
            value: "inject",
            type:"walletConnect",
            expire: timestamp + 3600, // 1 hours
            // expire: timestamp + 3600, // 1 hours
          };
          let getObjString = window.sessionStorage.getItem("GMMT_Staking");
          if (!getObjString) {
            const objString = JSON.stringify(obj);
            window.sessionStorage.setItem("GMMT_Staking", objString);
          }
          // setModalStatus(false);
        } else {
          onChangeNetWork(provider, "Wallet");
        }
      }
    } catch (error) {
      if(provider!=""){
        await provider.qrcodeModal.close();
        dispatch(setProvider(""));
        try {
        } catch (error) {
          if (provider == "") {
            onClickDisconnect();
          }
        }
      }
    }
  };

  const getIsOwner = async () => {
    if (defaultProvider != "" && account != "") {
      let bool = false;
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(StakingABI, StakingAddress);
      const addValidator = await contract.getPastEvents("ValidatorAdded", {
        filter: {},
        fromBlock: 0,
        toBlock: "latest",
      });
      let pushList = [];
      let getValidatorStatus;
      for (let i = 0; i < addValidator.length; i++) {
        let address = addValidator[i].returnValues.validator;
        let isValidator = await contract.methods
          .isValidator(`${address}`)
          .call();
        if (isValidator) {
          getValidatorStatus = await contract.methods
            .getValidatorStatus(`${address}`)
            .call();
          getValidatorStatus = {
            ownerAddress: getValidatorStatus.ownerAddress,
          };
          pushList.push(getValidatorStatus);
        }
      }

      let find = pushList.find(
        (i) => i.ownerAddress.toLowerCase() == account.toLowerCase()
      );
      if (find != undefined) {
        bool = true;
      }
      dispatch(setIsOwner(bool));
    } else if (defaultProvider != "" && account == "") {
      dispatch(setIsOwner(false));
    }
  };

  const getNowPath = () => {
    let split = pathname.split("/");
    if (!isNaN(split[split.length - 1])) {
      split.pop();
    }
    let join;
    if (split.length == 1 && split[split.length - 1] == "") {
      join = "/";
    } else {
      join = split.join("/");
    }
    let nowName = stakingPathList.includes(join) ? "Staking" : "Governance";
    setNowName(nowName);
  };

  // 모바일
  const [mobileCheck, setMobileCheck] = useState(false); // false이면 데탑;

  const checkdetectMoileDevice = (agent, width) => {
    const mobileRegex = [
      /Android/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    // setMobileCheck(mobileRegex.some(mobile => agent.match(mobile)));
    setMobileCheck(width <= 767);
  };
  useEffect(() => {
    checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    window.addEventListener("resize", () => {
      checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
      });
    };
  }, []);


  const checkConnectTime = () => {
    let schedules = "";
    if(provider!=""&& account!=""){
      schedules = schedule.scheduleJob("11", {rule:"*/1 * * * * *"},()=>{
        let walletconnect = window.localStorage.getItem("walletconnect");
        let objString = window.sessionStorage.getItem("GMMT_Staking");
        if(walletconnect == null && objString == null){
          schedule.cancelJob("11")
          return onClickDisconnect();
        }else{
          checkInjectUseCallback()
        }
      })
    }
  }

  useEffect(()=>{
    checkConnectTime();
  },[provider,account])

  useLayoutEffect(() => {
    checkInject();
  }, [provider]);

  useEffect(() => {
    accountReNumber();
  }, [account]);

  useEffect(() => {
    getBalance();
  }, [windowEther, account, transactionHash]);

  useEffect(() => {
    getProvider();
  }, [provider]);

  useEffect(() => {
    getIsOwner();
  }, [defaultProvider, account]);

  useEffect(() => {
    getNowPath();
  }, [pathname]);


  return (
    <div
      className={styles.header}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <h1 className={styles.logo}>
        <Link to={"/"}>
          <img
            src={require("../assets/images/common_logo_horizontal_black@2x.png")}
            alt="GMMT_logo"
          />
        </Link>
      </h1>
      {mobileCheck ? (
        ""
      ) : (
        <div className={styles.headerLink}>
          {headerPath.map((item, index) => (
            <Link
              to={item.path}
              className={nowName == item.name ? styles.on : ""}
              key={index}
            >
              {item.name}
            </Link>
          ))}
        </div>
      )}
      <div className={styles.psr}>
        <BtnBlue
          styles={styles}
          id={account == "" ? styles.btnConnect : styles.btnWallet}
          text={account == "" ? "Connect Wallet" : subAccount}
          click={account == "" ? connectWalletModal : disConnectWalletModal}
        />
        {modalStatus ? (
          modalType == "connect" ? (
            <BaseModal
              styles={styles}
              type={modalType}
              onClick={onClickWallet}
              onClickCancel={() => {
                setModalStatus(false);
              }}
            />
          ) : (
            <Disconnect
              styles={styles}
              onClickCancel={() => {
                setModalStatus(false);
              }}
              onClickDisConnect={onClickDisconnect}
            />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
