import { createAction, handleActions } from "redux-actions";
import produce from "immer";

const initialState = {
  account: "",
};

const SETACCOUNT = "user/SETACCOUNT";
export const setAccount = createAction(SETACCOUNT, (input) => input);

const account = handleActions(
  {
    [SETACCOUNT]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.account = input;
      }),
  },
  initialState
);
export default account;
