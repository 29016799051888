import React from "react";
import { useSelector } from "react-redux";
import { fromWeiDecimal, setFromWeiBalance, subString } from "../../common";

const ReDelegate = ({ styles, item, onClickCancel, click }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);

  return (
    <div className={`${styles.popCont}`}>
      <p className={`${styles.popTit}`}>
        <span>ReDelegate Asset</span>
        <img
          src={require("../../assets/images/ico_close_bl.png")}
          alt="close_Icon"
          className={`${styles.closeBtn}`}
          onClick={onClickCancel}
        />
      </p>
      <ul className={`${styles.valueList}`}>
        <li>
          <span className={`${styles.tit}`}>Selected Validator</span>
          <span className={`${styles.value}`}>
            {subString(item.validatorAddress)}
          </span>
        </li>
        <li>
          <span className={`${styles.tit}`}>Commission Rate</span>
          <span className={`${styles.value}`}>
            {item.commissionRate / 100}%
          </span>
        </li>
        <li>
          <span className={`${styles.tit}`}>Status</span>
          <span className={`${styles.value}`}>
            {item.status == "1"
              ? "Active"
              : item.status == "2"
              ? "InActive"
              : "Jail"}
          </span>
        </li>
        <li>
          <span className={`${styles.tit}`}>ReDelegated Asset</span>
          <span className={`${styles.value}`}>
            {setFromWeiBalance(defaultProvider, item.amountToStake)} GMMT
          </span>
        </li>
      </ul>
      <div className={`${styles.btnWrap}`}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnCancel}`}
          onClick={onClickCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnNext}`}
          onClick={() => {
            click(item.validatorAddress);
          }}
        >
          ReDelegate
        </button>
      </div>
    </div>
  );
};

export default ReDelegate;
