import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  setFromWeiBalance,
  sub,
  subNotToWei,
  subString,
} from "../../common";

const TableMyStaking = ({ styles, item, onClickModal }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  return (
    <div className={styles.tbody}>
      <div className={styles.validator}>
        <div className={styles.img}>
          <img src="" />
        </div>
        <p className={`${styles.name} ${styles.fwb}`}>
          <Link to={`/address/${item.validatorAddress}`}>
            {subString(item.validatorAddress)}
          </Link>
        </p>
      </div>
      <div className={styles.address}>{subString(item.ownerAddress)}</div>
      <div className={styles.amount}>
        {setFromWeiBalance(defaultProvider, item.delegatedAmount)} GMMT
      </div>
      <div className={styles.claimable}>
        {setFromWeiBalance(defaultProvider, item.getDelegatorFee)} GMMT
      </div>
      <div className={styles.pendingClaimable}>
      {/* {setFromWeiBalance(defaultProvider, item.getPendingDelegatorFee)} GMMT */}
      {subNotToWei(defaultProvider, item.getPendingDelegatorFee, item.getDelegatorFee)} GMMT
      </div>
      <div className={styles.button}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnBdBlack} ${
            item.getDelegatorFee != "0" ? "" : styles.inActive
          }`}
          onClick={() => {
            if (item.getDelegatorFee != "0") {
              onClickModal(item, "claim");
            }
          }}
        >
          Claim
        </button>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnBdBlack} ${
            item.amountToStake != "0" ? "" : styles.inActive
          }`}
          onClick={() => {
            if (item.amountToStake != "0") {
              onClickModal(item, "redelegate");
            }
          }}
        >
          ReDelegate
        </button>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnBdBlue}`}
          onClick={() => {
            if(item.delegatedAmount != "0"){
              onClickModal(item, "unstake");
            }
          }}
        >
          Unstake
        </button>
      </div>
    </div>
  );
};

export default TableMyStaking;
