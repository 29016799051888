import React from "react";

const BtnBdBlue = ({ text, click, item, styles }) => {
  return (
    <button
      className={styles}
      onClick={() => {
        click(item);
      }}
    >
      {text}
    </button>
  );
};

export default BtnBdBlue;
