import React from "react";

const Confirm = ({ styles , onClickCancel}) => {
  return (
    <div className={`${styles.popCont} ${styles.msg}`}>
      <img
        src={require("../../assets/images/common_ico_pop_loading@3x.png")}
        alt="ico loading"
        className={styles.icoLoading}
      />
      <p>
        Transaction is Confirming.
        <br />
        Please wait a moment.
      </p>
      <div className={styles.btnWrap}>
        <button type="button" className={`${styles.btnNormal} ${styles.btnOk}`} onClick={onClickCancel}>
          Ok
        </button>
      </div>
    </div>
  );
};

export default Confirm;
