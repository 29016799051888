import React, { useState } from "react";

const Vote = ({ styles, click, onClickCancel, item }) => {
  const [btnList, setBtnList] = useState([
    {
      name: "AginstVotes",
      num: "0",
    },
    {
      name: "ForVotes",
      num: "1",
    },
    {
      name: "AbstainVotes",
      num: "2",
    },
  ]);
  const [nowBtnName, setNowBtnName] = useState("");
  const [num, setNum] = useState("");

  const onClickSelect = (name) => {
    if (nowBtnName != name) {
      let find = btnList.find((e) => {
        return e.name == name;
      });
      setNowBtnName(name);
      setNum(find.num);
    } else if (nowBtnName != "") {
      setNowBtnName("");
      setNum("");
    }
  };

  return (
    <div className={styles.popCont}>
      <p className={styles.popTit}>Vote</p>
      <div>
        {btnList.map((item, index) => (
          <div
            className={`${styles.btnNormal} ${styles.voteBtn} ${
              nowBtnName == item.name ? styles.now : ""
            }`}
            onClick={() => {
              onClickSelect(item.name);
            }}
            key={index}
          >
            {item.name}
          </div>
        ))}
      </div>
      <div className={styles.btnWrap}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnCancel}`}
          onClick={onClickCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnNext} ${
            nowBtnName == "" ? styles.inActiveBc : ""
          }`}
          onClick={() => {
            if (nowBtnName != "") {
              click(item, num);
            }
          }}
        >
          Vote
        </button>
      </div>
    </div>
  );
};

export default Vote;
