import React from "react";

const Failed = ({ styles, onClickCancel, type }) => {
  return (
    <div className={`${styles.popCont} ${styles.msg}`}>
      <img src={require("../../assets/images/common_ico_pop_failed@3x.png")} alt="ico fail" />
      {type == "falied" ? (
        <p>Transaction is Failed.</p>
      ) : (
        <p>The transaction was declined by the user.</p>
      )}
      <div className={styles.btnWrap}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnOk}`}
          onClick={onClickCancel}
        >
          Ok
        </button>
      </div>
    </div>
  );
};

export default Failed;
