import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Web3 from "web3";
import RegisterValidator from "../Main/RegisterValidator";

const StakingNavBar = ({ styles }) => {
  const account = useSelector((state) => state.account.account);
  const isOwner = useSelector((state)=>state.user.isOwner);

  const { pathname } = useLocation();
  const [linkParams, setLinkParams] = useState([
    {
      name: "Validators",
      path: "/",
    },
    {
      name: "My staking",
      path: "/myStaking/stakedAssets",
    },
    {
      name: "Validator Assets",
      path: "/validatorAssets/stakedAssets",
    },
  ]);
  const [nowPathName, setNowPathName] = useState("");

  const checkPath = () => {
    let splitPathName = pathname.split("/");
    if (
      splitPathName[0] == "" &&
      (!isNaN(splitPathName[1]) || splitPathName[1] == "") ||(!isNaN(splitPathName[1]) || splitPathName[1] == "inActive")

    ) {
      setNowPathName("Validators");
    }
    if (splitPathName[0] == "" && splitPathName[1] == "validatorAssets") {
      setNowPathName("Validator Assets");
    } else if (splitPathName[0] == "" && splitPathName[1] == "myStaking") {
      setNowPathName("My Staking");
    }
  };



  useEffect(() => {
    checkPath();
  }, [pathname]);

  return (
    <ul
      className={`${styles.tabWrap} ${
        nowPathName.toLocaleLowerCase() ==
        linkParams[1].name.toLocaleLowerCase()||
        nowPathName.toLocaleLowerCase() ==
        linkParams[2].name.toLocaleLowerCase()
          ? styles.myStaking
          : ""
      }`}
    >
      <li
        className={
          nowPathName.toLocaleLowerCase() ==
          linkParams[0].name.toLocaleLowerCase()
            ? styles.on
            : ""
        }
      >
        <Link to={`${linkParams[0].path}`}>{linkParams[0].name}</Link>
      </li>
      {account != "" ? (
        <li
          className={
            nowPathName.toLocaleLowerCase() ==
            linkParams[1].name.toLocaleLowerCase()
              ? styles.on
              : ""
          }
        >
          <Link to={`${linkParams[1].path}`}>{linkParams[1].name}</Link>
        </li>
      ) : (
        ""
      )}
      {isOwner && account !=""? (
        <li
          className={
            nowPathName.toLocaleLowerCase() ==
            linkParams[2].name.toLocaleLowerCase()
              ? styles.on
              : ""
          }
        >
          <Link to={`${linkParams[2].path}`}>{linkParams[2].name}</Link>
        </li>
      ) : (
        ""
      )}
    </ul>
  );
};

export default StakingNavBar;
