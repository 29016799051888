import React from "react";
import { useSelector } from "react-redux";
import {
  setFromWeiBalance,
} from "../../common";

const TotalAssetBox = ({
  styles,
  totalAmount,
  delegateAmount,
  claimAmount,
}) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);

  return (
    <div className={styles.totalAssetBox}>
      <p className={styles.tit}>Total Asset</p>
      <div className={styles.totalValue}>
        {setFromWeiBalance(defaultProvider, totalAmount)} GMMT
      </div>
      <div className={styles.valueWrap}>
        <div>
          <span className={styles.tit}>Delegated Asset</span>
          <span className={styles.value}>
            {setFromWeiBalance(defaultProvider, delegateAmount)} GMMT
          </span>
        </div>
        <div>
          <span className={styles.tit}>Claimable Asset</span>
          <span className={styles.value}>
            {setFromWeiBalance(defaultProvider, claimAmount)} GMMT
          </span>
        </div>
      </div>
    </div>
  );
};

export default TotalAssetBox;
