import React from "react";
import { useSelector } from "react-redux";
import {
  addValidatorClaimableAssetsArray,
  addValidatorPendingAssetsArray,
  setFromWeiBalance,
  sub,
  subNotToWei,
} from "../../common";

const TotalAssetBox = ({ styles, item }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);



  return (
    <div className={styles.totalAssetBox}>
      <p className={styles.tit}>Total Asset</p>
      <div className={styles.totalValue}>
        {setFromWeiBalance(
          defaultProvider,
          addValidatorClaimableAssetsArray(item)
        )}{" "}
        GMMT
      </div>
      <div className={styles.valueWrap}>
        <div>
          <span className={styles.tit}>Claimable Asset</span>
          <span className={styles.value}>
            {setFromWeiBalance(
              defaultProvider,
              addValidatorClaimableAssetsArray(item)
            )}{" "}
            GMMT
          </span>
        </div>
        <div>
          <span className={styles.tit}>Pending Asset</span>
          <span className={styles.value}>
            {subNotToWei(
              defaultProvider,
              addValidatorPendingAssetsArray(item),
              addValidatorClaimableAssetsArray(item)
            )}{" "}
            GMMT
          </span>
        </div>
      </div>
    </div>
  );
};

export default TotalAssetBox;
