import { BN } from "bn.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";

import StakingABI from "../abi/Staking.json";
import GovernanceABI from "../abi/Governance.json";
import TbodyItem from "../Components/Votes/TbodyItem";
import TbodyItemNotice from "../Components/Votes/TbodyItemnotice";
import NavBar from "../Components/Navbar/NavBar";
import Propose from "../Components/Votes/Propose";
import ViewPage from "../Components/Page/ViewPage";
import TradeModal from "../Components/Modals/TradeModal";
import { useLocation, useNavigate } from "react-router-dom";
import { setTransactionHash } from "../redux/user";
import schedule from "node-schedule";
import { scheduleTime } from "../common";

const GovernanceVote = ({ styles }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  // const web3 = useSelector((state) => state.user.web3);
  const windowEther = useSelector((state) => state.user.windowEther);
  const account = useSelector((state) => state.account.account);
  const governanceType = useSelector((state) => state.user.governanceType);
  const transactionHash = useSelector((state) => state.user.transactionHash);

  const governanceContractAddress = process.env.REACT_APP_PUBLIC_GOVERNANCE;
  const validatorContractAddress =
    process.env.REACT_APP_PUBLIC_STAKING_CONTRACT;

  const [proposalList, setProposalList] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [viewList, setViewList] = useState([]);

  const [modalStatus, setModalStatus] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalItem, setModalItem] = useState();

  const [totalPageNum, setTotalPageNum] = useState(0);
  const [nowPageNum, setNowPageNum] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pageLimit, setPageLimit] = useState(10);
  const [mobileCheck, setMobileCheck] = useState(false); // false이면 데탑;
  const [validatorStatus, setValidatorStatus] = useState(false);
  const [pageArray, setPageArray] = useState("");

  const [loading, setLoading] = useState(false);

  const [nowPath, setNowPath] = useState("");
  const [schedule_1, setSchedule_1] = useState("");
  const [schedule_2, setSchedule_2] = useState("");

  const { pathname } = useLocation();

  const navigator = useNavigate();
  const dispatch = useDispatch();

  const getProposalCreated = async () => {
    try {
      if (defaultProvider != "") {
        let returnData = [];
        let defaultWeb3 = new Web3(defaultProvider);
        const contract = new defaultWeb3.eth.Contract(
          GovernanceABI,
          governanceContractAddress
        );
        let proposalCreated = await contract.getPastEvents("ProposalCreated", {
          filter: {},
          fromBlock: 0,
          toBlock: "latest",
        });
        let promise = [];
        let nowBlockNumber = await defaultWeb3.eth.getBlockNumber();
        for (let i = 0; i < proposalCreated.length; i++) {
          let hasVoted = false;
          if (account != "") {
            hasVoted = await contract.methods
              .hasVoted(proposalCreated[i].returnValues.proposalId, account)
              .call();
          }

          promise.push(
            new Promise(async (resolve, inject) => {
              let proposalVotes = await contract.methods
                .proposalVotes(proposalCreated[i].returnValues.proposalId)
                .call();
              let state = await contract.methods
                .state(proposalCreated[i].returnValues.proposalId)
                .call();
              let returnValues = {
                targets: proposalCreated[i].returnValues.targets,
                values: proposalCreated[i].returnValues.values,
                calldatas: proposalCreated[i].returnValues.calldatas,
                proposalId: proposalCreated[i].returnValues.proposalId,
                proposer: proposalCreated[i].returnValues.proposer,
                description: proposalCreated[i].returnValues.description,
                startBlock: proposalCreated[i].returnValues.startBlock,
                endBlock: proposalCreated[i].returnValues.endBlock,
                startTime: await setTimestamp(
                  proposalCreated[i].returnValues.startBlock
                ),
                endTime: await setEndTimestamp(
                  proposalCreated[i].returnValues.startBlock,
                  proposalCreated[i].returnValues.endBlock
                ),
                signatures: proposalCreated[i].returnValues.signatures,
                buttonStatus:
                  proposalCreated[i].returnValues.endBlock >= nowBlockNumber,
                hasVoted: hasVoted,
                proposalVotes: new BN(`${proposalVotes.againstVotes}`)
                  .add(new BN(`${proposalVotes.forVotes}`))
                  .add(new BN(`${proposalVotes.abstainVotes}`))
                  .toString(),
                state: state,
              };

              resolve(returnValues);

              // returnData.push(returnValues);
            })
          );
        }

        Promise.all(promise).then((values) => {
          values.sort((a, b) => {
            return b.startBlock - a.startBlock;
          });

          setProposalList(values);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setTimestamp = async (blockNumber) => {
    const web3 = new Web3(defaultProvider);
    let blockData = await web3.eth.getBlock(blockNumber);
    let date = moment.unix(blockData.timestamp).format("YYYY-MM-DD HH:mm:ss");
    return date;
  };

  const setEndTimestamp = async (startBlock, endBlock) => {
    const web3 = new Web3(defaultProvider);
    let startBlockData = await web3.eth.getBlock(startBlock);
    let endBlockData = await web3.eth.getBlock(endBlock);
    let subBlock = new BN(`${endBlock}`)
      .sub(new BN(`${startBlock}`))
      .toString();
    let subBlockTime = new BN(`${subBlock}`).mul(new BN(`${3}`)).toString();
    let addTimestamp = new BN(`${startBlockData.timestamp}`)
      .add(new BN(`${subBlockTime}`))
      .toString();
    let date = moment
      .unix(endBlockData == null ? addTimestamp : endBlockData.timestamp)
      .format("YYYY-MM-DD HH:mm:ss");
    return date;
  };

  const getSelectList = () => {
    if (governanceType != "" && proposalList != "") {
      let typeList = [];
      let status = "";
      switch (governanceType) {
        case "all":
          status = "";
          break;
        case "vote":
          status = "1";
          break;
        case "defeated":
          status = "3";
          break;
        case "succeeded":
          status = "4";
          break;
        case "expired":
          status = "6";
          break;
        case "executed":
          status = "7";
          break;

        default:
          break;
      }
      for (let i = 0; i < proposalList.length; i++) {
        if (status == "") {
          typeList.push(proposalList[i]);
        } else if (status == proposalList[i].state) {
          typeList.push(proposalList[i]);
        }
      }
      setSelectList(typeList);
    }
  };

  const getViewProposalList = (page) => {
    let list = selectList;

    let totalPageNum = Math.ceil(list.length / limit);
    if (totalPageNum == 0) totalPageNum = 1;
    setTotalPageNum(totalPageNum);
    setNowPageNum(page);
    if (totalPageNum >= Number(page)) {
      let resultList = [];
      for (let i = limit * page - limit; i < limit * page; i++) {
        if (list[i] == undefined) {
          break;
        }
        resultList.push(list[i]);
      }
      setViewList(resultList);
    } else {
      setNowPageNum(1);
      // window.location.href = `governance/vote/${governanceType}/1`
    }
  };

  const getNowPath = () => {
    let split = pathname.split("/");
    if (!isNaN(split[split.length - 1])) {
      split.pop();
    }
    let join;
    if (split.length == 1 && split[split.length - 1] == "") {
      join = "/";
    } else {
      join = split.join("/");
    }
    setNowPath(join);
  };

  const checkIsValidator = async () => {
    if (defaultProvider != "") {
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        StakingABI,
        validatorContractAddress
      );
      let validatorAdded = await contract.getPastEvents("ValidatorAdded", {
        filter: {},
        fromBlock: 0,
        toBlock: "latest",
      });

      let promise = [];
      for (let i = 0; i < validatorAdded.length; i++) {
        let address = validatorAdded[i].returnValues.validator;
        let isValidator = await contract.methods
          .isValidator(`${address}`)
          .call();
        if (isValidator) {
          let getValidatorStatus = await contract.methods
            .getValidatorStatus(`${validatorAdded[i].returnValues.validator}`)
            .call();
          promise.push(
            new Promise(async (resolve, reject) => {
              let data = {
                owner: getValidatorStatus.ownerAddress,
                status: account == "" ? false : getValidatorStatus.status,
                validator: validatorAdded[i].returnValues.validator,
              };

              resolve(data);
            })
          );
        }
      }

      Promise.all(promise).then((values) => {
        let find = values.find((item) => {
          return item.owner.toUpperCase() == account.toUpperCase();
        });
        if (find == undefined) {
          // navigator(-1)
          setValidatorStatus(false);
        } else {
          if (find.status == "1") {
            setValidatorStatus(true);
          } else {
            setValidatorStatus(false);
          }
        }
      });
    } else if (account == "") {
      // navigator(-1)
      setValidatorStatus(false);
    }
  };

  const onClickModal = (item) => {
    setModalStatus(true);
    setModalType("vote");
    setModalItem(item);
  };

  const onClickVote = async (item, num) => {
    let proposalId = item.proposalId;
    const web3 = new Web3(windowEther);
    const contract = new web3.eth.Contract(
      GovernanceABI,
      governanceContractAddress
    );
    let reason = num == "0" ? "No" : num == "1" ? "Yes" : "Abstain";
    setModalType("confirm");
    try {
      let result = await contract.methods
        .castVoteWithReason(proposalId, num, reason)
        .send({
          from: account,
        });
      if (result.status == true) {
        setModalType("complete");
      } else {
        setModalType("failed");
      }
      dispatch(setTransactionHash(result.transactionHash));
    } catch (error) {
      // if (error.code == 4001) {
      setModalType("refuse");
      // }
    }
  };
  const getDiscriptionHash = (text) => {
    let texts = text;
    if (text == "") {
      texts = "";
    }
    const web3 = new Web3(defaultProvider);
    return web3.utils.sha3(texts);
  };
  const onClickExecute = async (item) => {
    let targets = item.targets;
    let values = item.values;
    let calldatas = item.calldatas;
    let descriptionHash = getDiscriptionHash(item.description);
    const web3 = new Web3(windowEther);
    const contract = new web3.eth.Contract(
      GovernanceABI,
      governanceContractAddress
    );
    setModalStatus(true);
    setModalType("confirm");
    try {
      let result = await contract.methods
        .execute(targets, values, calldatas, descriptionHash)
        .send({
          from: account,
          value: "0",
        });
      if (result.status == true) {
        setModalType("complete");
      } else {
        setModalType("failed");
      }
      dispatch(setTransactionHash(result.transactionHash));
    } catch (error) {
      // if (error.code == 4001) {
      setModalType("refuse");
      // }
    }
  };

  useEffect(() => {
    if (totalPageNum != 0) {
      let push = [];
      push.push(`${nowPath}`);
      for (let i = 1; i <= totalPageNum; i++) {
        push.push(`${nowPath}${"/".concat(i)}`);
      }
      setPageArray(push);
    }
  }, [totalPageNum, nowPath]);

  useEffect(() => {
    let schedules = "";
    if (
      defaultProvider != "" &&
      account != "" &&
      schedule_1 == "" &&
      pageArray != ""
    ) {
      schedules = schedule.scheduleJob("8", scheduleTime(), () => {
        if (!pageArray.includes(window.location.pathname)) {
          schedule.cancelJob("8");
          setSchedule_1("");
        } else {
          getProposalCreated();
        }
      });
      setSchedule_1(schedules);
    } else if (account == "" && schedule_1 != "") {
      schedule_1.cancel();
      setSchedule_1("");
    }
  }, [defaultProvider, account, schedule_1, pageArray]);

  useEffect(() => {
    let schedules = "";
    if (
      defaultProvider != "" &&
      account == "" &&
      schedule_2 == "" &&
      pageArray != ""
    ) {
      schedules = schedule.scheduleJob("9", scheduleTime(), () => {
        if (!pageArray.includes(window.location.pathname)) {
          schedule.cancelJob("9");
          setSchedule_2("");
        } else {
          getProposalCreated();
        }
      });
      setSchedule_2(schedules);
    } else if (account != "" && schedule_2 != "") {
      schedule_2.cancel();
      setSchedule_2("");
    }
  }, [defaultProvider, account, schedule_2, pageArray]);

  useEffect(() => {
    getNowPath();
  }, [pathname]);

  useEffect(() => {
    getProposalCreated();
  }, [defaultProvider, account, transactionHash]);

  useEffect(() => {
    checkIsValidator();
  }, [defaultProvider, account]);

  useEffect(() => {
    getSelectList();
  }, [governanceType, proposalList]);

  useEffect(() => {
    let pathSplit = pathname.split("/");
    let pathSplitConcat = "/"
      .concat(pathSplit[1])
      .concat("/")
      .concat(pathSplit[2])
      .concat("/")
      .concat(pathSplit[3]);
    let page = 0;
    // if (
    //   option[0].path == pathSplitConcat ||
    //   option[1].path == pathSplitConcat
    // ) {
    if (pathSplit[4] == undefined) {
      page = 1;
    } else {
      page = pathSplit[4];
    }
    // }
    getViewProposalList(page);
  }, [selectList, nowPageNum, pathname]);

  const checkdetectMoileDevice = (agent, width) => {
    const mobileRegex = [
      /Android/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    // setMobileCheck(mobileRegex.some(mobile => agent.match(mobile)));
    setMobileCheck(width <= 767);
  };

  useEffect(() => {
    if (mobileCheck) {
      setPageLimit(5);
    } else {
      setPageLimit(10);
    }
  }, [mobileCheck]);

  useEffect(() => {
    checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    window.addEventListener("resize", () => {
      checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
      });
    };
  }, []);

  return (
    <div className={styles.content}>
      <NavBar
        styles={styles}
        nowPage={nowPageNum}
        validatorStatus={validatorStatus}
      />
      <div
        className={`${styles.tableNormal} ${styles.tableValidator} ${styles.governanceVoteTable}`}
      >
        <div className={`${styles.thead}`}>
          <div className={`${styles.no}`}>No</div>
          <div className={`${styles.title}`}>Title</div>
          <div className={`${styles.starTime}`}>Start Block</div>
          <div className={`${styles.endTime}`}>End Block</div>
          {/* <div className={`${styles.starTime}`}>Start Time</div>
          <div className={`${styles.endTime}`}>End Time</div> */}
          <div className={`${styles.proposalVotes}`}>Proposal Votes</div>
          <div className={`${styles.button}`}></div>
        </div>
        <TbodyItemNotice styles={styles} />
        {viewList == "" ? (
          <div/>
        ) : (
          viewList.map((item, index) => (
            <TbodyItem
              item={item}
              key={index}
              index={index}
              limit={limit}
              styles={styles}
              nowPageNum={nowPageNum}
              onClickModal={onClickModal}
              onClickExecute={onClickExecute}
            />
          ))
        )}
      </div>
      <div className={`${styles.mainFooter} ${styles.governanceFooter}`}>
        <ViewPage
          totalPage={totalPageNum}
          nowPage={nowPageNum}
          pageLimit={pageLimit}
          styles={styles}
          path={nowPath}
        />
        <div
          className={styles.mainRegister}
          style={{ visibility: validatorStatus ? "visible" : "hidden"}}
        >
          {/* {validatorStatus ? <Propose styles={styles} /> : ""} */}
          <Propose styles={styles} />
        </div>
      </div>

      {modalStatus ? (
        <TradeModal
          styles={styles}
          type={modalType}
          item={modalItem}
          onClickCancel={() => {
            setModalStatus(false);
          }}
          click={onClickVote}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default GovernanceVote;
