import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  setFromWeiBalance,
  subString,
} from "../../common/index";
import BtnBdBlue from "../Buttons/BtnBdBlue";
import BtnBlue from "../Buttons/BtnBlue";

const TbodyItem = ({ limit, nowPageNum, item, index, styles, stakeModal }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  const account = useSelector((state) => state.account.account);

  return (
    <div className={`${styles.tbody}`}>
      <div className={`${styles.rank} ${styles.fwb}`}>
        {limit * (nowPageNum - 1) + (index + 1)}
      </div>
      <div className={`${styles.validator}`} title={item.validatorAddress}>
        <Link to={`/address/${item.validatorAddress}`}>
          <div className={`${styles.img}`}>
            {/* <img src={require("")} /> */}
            <img />
          </div>
          <p className={`${styles.name} ${styles.fwb}`}>
            {subString(item.validatorAddress)}
          </p>
        </Link>
      </div>
      <div
        className={`${styles.status} ${
          item.status == "1"
            ? styles.active
            : item.status == "2"
            ? styles.inactive
            : styles.jail
        } ${styles.fwb}`}
      >
        {item.status == "1"
          ? "Active"
          : item.status == "2"
          ? "InActive"
          : "Jail"}
      </div>
      <div className={`${styles.total}`}>
        {setFromWeiBalance(defaultProvider, item.totalDelegated)}
      </div>
      <div className={`${styles.commission}`}>{item.commissionRate / 100}%</div>
      <div className={`${styles.amount}`}>
        {item.delegatedAmount != "0"
          ? setFromWeiBalance(defaultProvider, item.delegatedAmount)
          : "-"}
      </div>
      <div className={`${styles.button}`}>
        <BtnBdBlue
          className={`${styles.btnBdBlue}`}
          styles={`${styles.btnNormal} ${styles.btnBdBlue}`
          // ${account == "" ? styles.unAvailableBtn : ""}`
        }
          text={"Stake"}
          click={stakeModal}
          item={item}
        />
      </div>
    </div>
  );
};

export default TbodyItem;

// stake 버튼 만들기
