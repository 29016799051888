import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { setFromWeiBalance, subString, thousandsSeparator } from "../../common";
import Loading from "./Loading";
import NoResults from "./NoResults";

const DelegatorBox = ({
  stakerList,
  totalDelegators,
  styles,
  mobileCheck,
  loading,
}) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);

  return (
    <div className={`${styles.DelegatorsBox}`}>
      <div className={`${styles.top}`}>
        <p className={`${styles.tit}`}>Delegators</p>
        <span className={`${styles.total}`}>
          <span className={`${styles.tit}`}>Total Delegators</span>
          <span className={`${styles.value}`}>
            {thousandsSeparator(totalDelegators)}
          </span>
        </span>
      </div>
      <div className={`${styles.tableNormal} ${styles.tableDelegators}`}>
        <div className={`${styles.thead}`}>
          <div className={`${styles.address}`}>Address</div>
          <div className={`${styles.amount}`}>Amount</div>
        </div>
        <div className={`${styles.scrollWrap}`}>
          <div>
            {loading ? (
              stakerList != "" ? (
                stakerList.map((item, index) => (
                  <div className={`${styles.tbody}`} key={item.staker}>
                    <div className={`${styles.address}`}>
                      {mobileCheck ? subString(item.staker) : item.staker}
                    </div>
                    <div className={`${styles.amount}`}>
                      {setFromWeiBalance(defaultProvider, item.amount)} GMMT
                    </div>
                  </div>
                ))
              ) : (
                <NoResults styles={styles} />
              )
            ) : (
              <Loading styles={styles} loading={loading}/>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DelegatorBox;
