import React, { useEffect, useState } from "react";
import Main from "./Pages/Main";
import Main_v2 from "./Pages/Main_v2";
import Main_v2_inActive from "./Pages/Main_v2_inActive";
import { BrowserRouter, Link, Route, Routes, useLocation } from "react-router-dom";
import "./assets/css/GMMTStaking.module.css";
import TopInfo from "./Components/TopInfo";
import { useDispatch } from "react-redux";
import Web3 from "web3";
import { setDefaultProvider } from "./redux/user";

import Header from "./Components/Header";
import ValidatorInfo from "./Pages/ValidatorInfo";
import GovernancePropose from "./Pages/GovernancePropose";
import MyStaking from "./Pages/MyStaking";
import ValidatorAssets from "./Pages/ValidatorAssets";
import styles from "./assets/css/GMMTStaking.module.css";
import GovernanceVote from "./Pages/GovernanceVote";
import GovernanceProposeDetail from "./Pages/GovernanceProposeDetail";
import GovernanceProposeDetailNotice from "./Pages/GovernanceProposeDetailNotice";
import Footer from "./Components/Footer";

const App = () => {
  const defaultRpc = process.env.REACT_APP_NETWORK_RPC;

  const dispatch = useDispatch();

  const onloadDefaultProvider = () => {
    // let web3 = new Web3(new Web3.providers.HttpProvider(defaultRpc));
    dispatch(setDefaultProvider(defaultRpc));
  };

  useEffect(() => {
    onloadDefaultProvider();
  }, []);

  const [mobileCheck, setMobileCheck] = useState(false); // false이면 데탑;

  const checkdetectMoileDevice = (agent, width) => {
    const mobileRegex = [
      /Android/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];
    // setMobileCheck(mobileRegex.some(mobile => agent.match(mobile)));
    setMobileCheck(width <= 767);
  };
  useEffect(() => {
    checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    window.addEventListener("resize", () => {
      checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
      });
    };
  }, []);
  return (
    <BrowserRouter>
      <Header />
      <div
        className={styles.container}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <TopInfo styles={styles} />
        <Routes>
          {/* Main */}
          {/* <Route path="/" element={<Main styles={styles} />} />
          <Route path="/:page" element={<Main styles={styles} />} /> */}
          <Route path="/" element={<Main_v2 styles={styles} />} />
          <Route path="/inActive" element={<Main_v2_inActive styles={styles} />} />
          {/* MyStaking */}
          <Route
            path="/myStaking/:type/"
            element={<MyStaking styles={styles} />}
          />
          <Route
            path="/myStaking/:type/:page"
            element={<MyStaking styles={styles} />}
          />
          {/* ValidatorAssets */}
          <Route
            path="/validatorAssets/:type/"
            element={<ValidatorAssets styles={styles} />}
          />
          <Route
            path="/validatorAssets/:type/:page"
            element={<ValidatorAssets styles={styles} />}
          />
          {/* ValidatorInfo */}
          <Route
            path="/address/:address/"
            element={<ValidatorInfo styles={styles} />}
          />
          <Route
            path="/address/:address/:page"
            element={<ValidatorInfo styles={styles} />}
          />
          {/* GovernancePropose */}
          <Route
            path="/governance/vote/:type"
            element={<GovernanceVote styles={styles} />}
          />
          <Route
            path="/governance/vote/:type/:page"
            element={<GovernanceVote styles={styles} />}
          />
          {/* GovernancePropose */}
          <Route
            path="/governance/propose"
            element={<GovernancePropose styles={styles} />}
          />
          <Route
            path="/governance/proposeInfo/:proposalId"
            element={<GovernanceProposeDetail styles={styles} />}
          />
          <Route
            path="/governance/notice"
            element={<GovernanceProposeDetailNotice styles={styles} />}
          />
        </Routes>
      </div>
      {!mobileCheck ? (
        ""
      ) : (
        <Footer styles={styles}/>
      )}
    </BrowserRouter>
  );
};

export default App;
