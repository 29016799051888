import React from "react";
import { useSelector } from "react-redux";
import {
  onClickScan,
  setFromWeiBalance,
  subString,
} from "../../common";

const ClaimComplete = ({ styles, item, onClickCancel }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  const balance = useSelector((state) => state.user.balance);
  const transactionHash = useSelector((state)=>state.user.transactionHash);

  return (
    <div className={styles.popCont}>
      <p className={styles.popTit}>
        <span>Claim Asset Completed Successfully</span>
        <img
          src={require("../../assets/images/ico_close_bl.png")}
          alt="close_Icon"
          className={styles.closeBtn}
          onClick={onClickCancel}
        />
      </p>
      <ul className={styles.valueList}>
        <li>
          <span className={styles.tit}>Selected Validator</span>
          <span className={styles.value}>{subString(item.validatorAddress)}</span>
        </li>
        <li>
          <span className={styles.tit}>Commission Rate</span>
          <span className={styles.value}>{item.commissionRate / 100}%</span>
        </li>
        <li>
          <span className={styles.tit}>Status</span>
          <span className={styles.value}>
            {item.status == "1"
              ? "Active"
              : item.status == "2"
              ? "InActive"
              : "Jail"}
          </span>
        </li>
        <li>
          <span className={styles.tit}>Claimable Asset</span>
          <span className={styles.value}>
            {setFromWeiBalance(defaultProvider, item.getDelegatorFee)} GMMT
          </span>
        </li>
      </ul>
      <div className={styles.balanceBox}>
        <p className={styles.subTit}>Balance</p>
        <div className={styles.box}>
          <span>{setFromWeiBalance(defaultProvider, balance)} GMMT</span>
          <span className={styles.plus}>
            (+{setFromWeiBalance(defaultProvider, item.getDelegatorFee)} GMMT)
          </span>
        </div>
      </div>
      <div className={styles.btnWrap}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnCancel}`}
          onClick={onClickCancel}
        >
          Close
        </button>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnNext}`}
          onClick={()=>onClickScan(transactionHash)}
        >
          View on Block Explorer
        </button>
      </div>
    </div>
  );
};

export default ClaimComplete;
