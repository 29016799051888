import { BN } from "bn.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Web3 from "web3";

import GovernanceABI from "../abi/Governance.json";
import {
  jsonDescription,
  jsonTitle,
  setFromWeiBalance,
  subString,
  thousandsSeparator,
} from "../common";
import schedule from "node-schedule";
import { scheduleTime } from "../common";

const GovernanceProposeDetail = ({ styles }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  // const web3 = useSelector((state) => state.user.web3);
  const windowEther = useSelector((state) => state.user.windowEther);
  const navigator = useNavigate();
  const { pathname } = useLocation();

  const governanceContractAddress = process.env.REACT_APP_PUBLIC_GOVERNANCE;

  const [selectType, setSelectType] = useState({
    activateValidator: {
      targets: "0x0000000000000000000000000000000000001000",
      abi: {
        inputs: [
          {
            internalType: "address",
            name: "validator",
            type: "address",
          },
        ],
        name: "activateValidator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    disableValidator: {
      targets: "0x0000000000000000000000000000000000001000",
      abi: {
        inputs: [
          {
            internalType: "address",
            name: "validator",
            type: "address",
          },
        ],
        name: "disableValidator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    addValidator: {
      targets: "0x0000000000000000000000000000000000001000",
      abi: {
        inputs: [
          {
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "addValidator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    setEcosystemReward: {
      targets: "0x0000000000000000000000000000000000001000",
      abi: {
        inputs: [
          {
            internalType: "uint256",
            name: "_amount",
            type: "uint256",
          },
        ],
        name: "setEcosystemReward",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    // removeValidator: {
    //   targets: "0x0000000000000000000000000000000000001000",
    //   abi: {
    //     inputs: [
    //       {
    //         internalType: "address",
    //         name: "account",
    //         type: "address",
    //       },
    //     ],
    //     name: "removeValidator",
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //     type: "function",
    //   },
    // },
    setActiveValidatorsLength: {
      targets: "0x0000000000000000000000000000000000007003",
      abi: {
        inputs: [
          {
            internalType: "uint32",
            name: "newValue",
            type: "uint32",
          },
        ],
        name: "setActiveValidatorsLength",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    setEpochBlockInterval: {
      targets: "0x0000000000000000000000000000000000007003",
      abi: {
        inputs: [
          {
            internalType: "uint32",
            name: "newValue",
            type: "uint32",
          },
        ],
        name: "setEpochBlockInterval",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    setMinStakingAmount: {
      targets: "0x0000000000000000000000000000000000007003",
      abi: {
        inputs: [
          {
            internalType: "uint256",
            name: "newValue",
            type: "uint256",
          },
        ],
        name: "setMinStakingAmount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    setMinValidatorStakeAmount: {
      targets: "0x0000000000000000000000000000000000007003",
      abi: {
        inputs: [
          {
            internalType: "uint256",
            name: "newValue",
            type: "uint256",
          },
        ],
        name: "setMinValidatorStakeAmount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    setValidatorJailEpochLength: {
      targets: "0x0000000000000000000000000000000000007003",
      abi: {
        inputs: [
          {
            internalType: "uint32",
            name: "newValue",
            type: "uint32",
          },
        ],
        name: "setValidatorJailEpochLength",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    setVotingPeriod: {
      targets: "0x0000000000000000000000000000000000007002",
      abi: {
        inputs: [
          {
            internalType: "uint256",
            name: "newVotingPeriod",
            type: "uint256",
          },
        ],
        name: "setVotingPeriod",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
  });
  const [proposalInfo, setProposalInfo] = useState("");
  const [voteList, setVoteList] = useState([]);
  const [type, setType] = useState("");
  const [value, setValue] = useState("");

  const contractArray = [
    {
      contractName:"Staking",
      contractAddress:"0x0000000000000000000000000000000000001000",
    },
    {
      contractName:"SlashingIndicator",
      contractAddress:"0x0000000000000000000000000000000000001001",
    },
    {
      contractName:"SystemReward",
      contractAddress:"0x0000000000000000000000000000000000001002",
    },
    {
      contractName:"StakingPool",
      contractAddress:"0x0000000000000000000000000000000000007001",
    },
    {
      contractName:"Governance",
      contractAddress:"0x0000000000000000000000000000000000007002",
    },
    {
      contractName:"ChainConfig",
      contractAddress:"0x0000000000000000000000000000000000007003",
    },
    {
      contractName:"RuntimeUpgrade",
      contractAddress:"0x0000000000000000000000000000000000007004",
    },
    {
      contractName:"DeployerProxy",
      contractAddress:"0x0000000000000000000000000000000000007005",
    },
  ];

  const upgradeSystemSmartContractABI = [
    {
			"inputs": [
				{
					"internalType": "address",
					"name": "systemContractAddress",
					"type": "address"
				},
				{
					"internalType": "bytes",
					"name": "newByteCode",
					"type": "bytes"
				},
				{
					"internalType": "bytes",
					"name": "applyFunction",
					"type": "bytes"
				}
			],
			"name": "upgradeSystemSmartContract",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		}
  ]

  const getProposeInfo = async () => {
    if (defaultProvider != "") {
      let pathnameSplit = pathname.split("/");
      let proposalId = pathnameSplit[3];
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        GovernanceABI,
        governanceContractAddress
      );
      const proposalCreated = await contract.getPastEvents("ProposalCreated", {
        filter: {},
        fromBlock: 0,
        toBlock: "latest",
      });
      let item = proposalCreated.filter((e) => {
        return e.returnValues.proposalId == proposalId;
      });

      let nowBlockNumber = await web3.eth.getBlockNumber();
      let state = await contract.methods.state(item[0].returnValues.proposalId).call();

      const proposalVotes = await contract.methods
        .proposalVotes(`${item[0].returnValues.proposalId}`)
        .call();
      let formatData = {
        targets: item[0].returnValues.targets,
        values: item[0].returnValues.values,
        calldatas: item[0].returnValues.calldatas,
        proposalId: item[0].returnValues.proposalId,
        proposer: item[0].returnValues.proposer,
        description: item[0].returnValues.description,
        startBlock: item[0].returnValues.startBlock,
        endBlock: item[0].returnValues.endBlock,
        startTime: await setTimestamp(item[0].returnValues.startBlock),
        endTime: await setEndTimestamp(
          item[0].returnValues.startBlock,
          item[0].returnValues.endBlock
        ),
        signatures: item[0].returnValues.signatures,
        buttonStatus: item[0].returnValues.endBlock >= nowBlockNumber,
        againstVotes: proposalVotes.againstVotes,
        forVotes: proposalVotes.forVotes,
        abstainVotes: proposalVotes.abstainVotes,
        state: state,
      };

      setProposalInfo(formatData);
    }
  };

  const setTimestamp = async (blockNumber) => {
    const web3 = new Web3(defaultProvider) 
    let blockData = await web3.eth.getBlock(blockNumber);
    let date = moment.unix(blockData.timestamp).format("YYYY-MM-DD HH:mm:ss");
    return date;
  };

  const setEndTimestamp = async (startBlock, endBlock) => {
    const web3 = new Web3(defaultProvider) 
    let startBlockData = await web3.eth.getBlock(startBlock);
    let endBlockData = await web3.eth.getBlock(endBlock);
    let subBlock = new BN(`${endBlock}`)
      .sub(new BN(`${startBlock}`))
      .toString();
    let subBlockTime = new BN(`${subBlock}`).mul(new BN(`${3}`)).toString();
    let addTimestamp = new BN(`${startBlockData.timestamp}`)
      .add(new BN(`${subBlockTime}`))
      .toString();
    let date = moment
      .unix(endBlockData == null ? addTimestamp : endBlockData.timestamp)
      .format("YYYY-MM-DD HH:mm:ss");
    return date;
  };

  const getVoteList = async () => {
    if (defaultProvider != "") {
      let pathnameSplit = pathname.split("/");
      let proposalId = pathnameSplit[3];
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        GovernanceABI,
        governanceContractAddress
      );

      let voteCast = await contract.getPastEvents("VoteCast", {
        filter: {},
        fromBlock: 0,
        toBlock: "latest",
      });

      let filter = voteCast.filter((e) => {
        return e.returnValues.proposalId == proposalId;
      });
      let promise = [];
      for (let i = 0; i < filter.length; i++) {
        promise.push(
          new Promise(async (resolve, reject) => {
            let data = {
              voter: filter[i].returnValues.voter,
              reason: filter[i].returnValues.reason,
              weight: filter[i].returnValues.weight,
              support: filter[i].returnValues.support,
            };
            resolve(data);
          })
        );
      }

      Promise.all(promise).then((values) => {
        let data = values;
        setVoteList(data);
      });
    }
  };


  const getProposeValue = async () => {
    if (defaultProvider != ""&&proposalInfo!="") {
      const web3 = new Web3(defaultProvider);
      let key = Object.keys(selectType);
      for (let i = 0; i < key.length; i++) {
        let result = web3.eth.abi.encodeFunctionSignature(
          selectType[key[i]].abi
        );
        if (proposalInfo.calldatas[0].includes(result)) {
          setType(key[i]);
          let subString = proposalInfo.calldatas[0].substring(10);
          let decodeLog = web3.eth.abi.decodeParameters(
            [`${selectType[key[i]].abi.inputs[0].type}`],
            subString
          );
          let value =
            decodeLog[0].substring(0, 2) == "0x"
              ? decodeLog[0]
              : key[i] == "setEcosystemReward" ||
                key[i] == "setMinStakingAmount" ||
                key[i] == "setMinValidatorStakeAmount"
              ? setFromWeiBalance(defaultProvider, decodeLog[0])
              : decodeLog[0];
          setValue(value);
        }else{
          let calldataSubstring = proposalInfo.calldatas[0].substring(0,10);

          if(calldataSubstring === "0x38dc8ff3"){
            setType("upgradeSystemSmartContract");
            let types = [];
            for (let i = 0; i < upgradeSystemSmartContractABI[0].inputs.length; i++) {
              types.push(upgradeSystemSmartContractABI[0].inputs[i])
            };
            let decodeParameters = web3.eth.abi.decodeParameters(types,proposalInfo.calldatas[0].substring(10));
            let value = contractArray.findIndex((e) =>{
              return e.contractAddress === decodeParameters.systemContractAddress;
            });
            setValue(contractArray[value].contractName);
          }
        }
      }
    }
  }

  const getCurrent = () => {};

  useEffect(() => {
    getProposeInfo();
  }, [defaultProvider]);

  useEffect(() => {
    getVoteList();
  }, [defaultProvider]);

  useEffect(()=>{
    getProposeValue()
  },[defaultProvider,proposalInfo])

  const [nowPath, setNowPath] = useState("");
  const [schedule_1, setSchedule_1] = useState("");

  useEffect(()=>{
    setNowPath(pathname)
  },[pathname])

  useEffect(()=>{
    let schedules = "";
    if (
      defaultProvider != "" &&
      schedule_1 == "" &&
      nowPath != ""
    ) {
      schedules = schedule.scheduleJob("10", scheduleTime(), () => {
        if (nowPath != window.location.pathname) {
          schedule.cancelJob("10");
          setSchedule_1("");
        } else {
          getProposeInfo();
          getVoteList();
        }
      });
      setSchedule_1(schedules);
    } else if (schedule_1 != "") {
      schedule_1.cancel();
      setSchedule_1("");
    }
  },[defaultProvider,nowPath])

  return (
    <>
      {proposalInfo == "" ? (
        ""
      ) : (
        <div>
          <div className={styles.bgGradation}>
            <button
              type="button"
              className={styles.btnBack}
              onClick={() => {
                navigator(-1);
              }}
            >
              Back to list
            </button>
            <div className={styles.proposalTitle} title={jsonTitle(proposalInfo.description)}>
              {jsonTitle(proposalInfo.description)}
            </div>
          </div>
          <div className={styles.proposeInfoWrap}>
            <div className={styles.leftBox}>
              <div className={styles.proposeInfoCalldata}>
                <div className={styles.proposeInfoType}>
                  <span className={styles.grayText}>Type</span>
                  <span className={styles.blackText}>{type}</span>
                </div>
                <div className={styles.proposeInfoInfo}>
                  <span className={styles.grayText}>Value</span>
                  <span className={styles.blackText}>{value}</span>
                </div>
              </div>
              <div className={styles.desc}>
                {jsonDescription(proposalInfo.description)}
              </div>
              <div className={styles.voteSection}>
                <div className={styles.voteTitle}>
                  <span>Votes</span>({thousandsSeparator(voteList.length)})
                </div>
                <div className={styles.voteList}>
                  <div className={styles.voteListInWrap}>
                    {voteList == "" ? (
                      <div className={`${styles.voteItem} ${styles.noVoter}`}>
                        No Voters
                      </div>
                    ) : (
                      voteList.map((item, index) => (
                        <div className={styles.voteItem} key={index}>
                          <div className={styles.voteAddress}>
                            {subString(item.voter)}
                          </div>
                          <div className={styles.voteDesc}>{item.reason}</div>
                          <div className={styles.votePower}>
                            {setFromWeiBalance(defaultProvider, item.weight)}{" "}
                            GMMT
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <div
                  className={`${styles.mainFooter} ${styles.detailFooters}`}
                ></div>
              </div>
            </div>
            <div className={styles.rightBox}>
              <div
                className={`${styles.whiteBox} ${styles.proposalInfoDetail}`}
              >
                <div className={styles.boldTitle}>Detail</div>
                <div className={styles.detialInfo}>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>Proposal</span>
                    <span className={styles.cont}>
                      {subString(proposalInfo.proposer)}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>Start Block</span>
                    <span className={styles.cont}>
                      {thousandsSeparator(proposalInfo.startBlock)}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>End Block</span>
                    <span className={styles.cont}>
                      {thousandsSeparator(proposalInfo.endBlock)}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>Status</span>
                    <span className={`${styles.cont} ${styles.fwd}`}>
                      {proposalInfo.state == "1" ? "Vote Now" : "Cancel"}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.whiteBox} ${styles.proposalInfoCurrent}`}
              >
                <div className={styles.boldTitle}>Current Results</div>
                <div className={styles.detialInfo}>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>AgainstVotes</span>
                    <span className={styles.cont}>
                      {thousandsSeparator(
                        setFromWeiBalance(
                          defaultProvider,
                          proposalInfo.againstVotes
                        )
                      )}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>ForVotes</span>
                    <span className={styles.cont}>
                      {thousandsSeparator(
                        setFromWeiBalance(
                          defaultProvider,
                          proposalInfo.forVotes
                        )
                      )}
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>AbstainVotes</span>
                    <span className={styles.cont}>
                      {thousandsSeparator(
                        setFromWeiBalance(
                          defaultProvider,
                          proposalInfo.abstainVotes
                        )
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.mainFooter} ${styles.detailFooters}`}></div>
        </div>
      )}
    </>
  );
};

export default GovernanceProposeDetail;
