import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setMainType } from '../../redux/user';

const StakingSelectBox = ({ styles, nowPage }) => {
  const [selectStatus, setSelectStatus] = useState(false);
  const [option, setOption] = useState([
    {
      name: "Active",
      value: "active",
    },
    {
      name: "InActive",
      value: "inActive",
    },
  ]);
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { pathname } = useLocation();

  const mainType = useSelector((state) => state.user.mainType)

  const handleSelect = (value) => {
    let url = value == "active" ? "/": "/inActive" 
    dispatch(setMainType(value));
    navigator(url);
  };

  useEffect(()=>{
    let split = pathname.split("/");
    let value = split[1] == "" ? "active" : "inActive";
    dispatch(setMainType(value));
  },[pathname])


  return (
    <div className={styles.selectWrap}>
      <select
        className={`${styles.selectBox} ${selectStatus ? styles.on : ""}`}
        onChange={(e) => {
          handleSelect(e.target.value);
        }}
        onClick={(e) => {
          setSelectStatus(!selectStatus);
        }}
        value={mainType}
      >
        {option.map((item, index) => (
          <option className={styles.option} value={item.value} key={index}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StakingSelectBox