import React from "react";

const RegisterValidator = ({ styles, bool, onClick }) => {
  return (
    <button
      className={`${styles.btnNormal} ${styles.btnBdGreen} ${styles.register} ${
        bool ? styles.displayNone : ""
      }`}
      onClick={() => {
        onClick();
      }}
    >
      Register Validator
    </button>
  );
};

export default RegisterValidator;
