import { BN } from "bn.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toWei } from "../common";
import governanceABI from "../abi/Governance.json";
import TradeModal from "../Components/Modals/TradeModal";
import Web3 from "web3";
import StakingABI from "../abi/Staking.json";
import { resolve } from "url";
import { setTransactionHash } from "../redux/user";

const GovernancePropose = ({ styles }) => {
  const windowEther = useSelector((state) => state.user.windowEther);
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  const account = useSelector((state) => state.account.account);

  const governanceContractAddress = process.env.REACT_APP_PUBLIC_GOVERNANCE;

  const validatorContractAddress =
    process.env.REACT_APP_PUBLIC_STAKING_CONTRACT;

  const navigator = useNavigate();

  const [selectStatus, setSelectStatus] = useState(false);
  const [inputArray, setInputArray] = useState({
    targets: "",
    values: "",
    calldatas: "",
    description: "",
    title: "",
    days: 0,
    type: "",
  });

  const [inputData, setInputData] = useState([
    {
      cont: inputArray.type,
      placeHolder: "0x...",
      styles: styles.address,
      inputValue: "",
      viewValue: "",
      inputState: false,
      type: "calldata",
    },
    {
      cont: "Date",
      placeHolder: "1",
      styles: styles.date,
      inputValue: "",
      viewValue: "",
      inputState: false,
      type: "date",
    },
    {
      cont: "Title",
      placeHolder: "Title",
      styles: styles.title,
      inputValue: "",
      viewValue: "",
      inputState: false,
      type: "title",
    },
    {
      cont: "Description",
      placeHolder: "0 ~ 0 characters",
      styles: styles.desc,
      inputValue: "",
      viewValue: "",
      inputState: false,
      type: "desc",
    },
  ]);

  const [selectType, setSelectType] = useState({
    activateValidator: {
      targets: "0x0000000000000000000000000000000000001000",
      abi: {
        inputs: [
          {
            internalType: "address",
            name: "validator",
            type: "address",
          },
        ],
        name: "activateValidator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    disableValidator: {
      targets: "0x0000000000000000000000000000000000001000",
      abi: {
        inputs: [
          {
            internalType: "address",
            name: "validator",
            type: "address",
          },
        ],
        name: "disableValidator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    addValidator: {
      targets: "0x0000000000000000000000000000000000001000",
      abi: {
        inputs: [
          {
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "addValidator",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    setEcosystemReward: {
      targets: "0x0000000000000000000000000000000000001000",
      abi: {
        inputs: [
          {
            internalType: "uint256",
            name: "_amount",
            type: "uint256",
          },
        ],
        name: "setEcosystemReward",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    // removeValidator: {
    //   targets: "0x0000000000000000000000000000000000001000",
    //   abi: {
    //     inputs: [
    //       {
    //         internalType: "address",
    //         name: "account",
    //         type: "address",
    //       },
    //     ],
    //     name: "removeValidator",
    //     outputs: [],
    //     stateMutability: "nonpayable",
    //     type: "function",
    //   },
    // },
    setActiveValidatorsLength: {
      targets: "0x0000000000000000000000000000000000007003",
      abi: {
        inputs: [
          {
            internalType: "uint32",
            name: "newValue",
            type: "uint32",
          },
        ],
        name: "setActiveValidatorsLength",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    setEpochBlockInterval: {
      targets: "0x0000000000000000000000000000000000007003",
      abi: {
        inputs: [
          {
            internalType: "uint32",
            name: "newValue",
            type: "uint32",
          },
        ],
        name: "setEpochBlockInterval",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    setMinStakingAmount: {
      targets: "0x0000000000000000000000000000000000007003",
      abi: {
        inputs: [
          {
            internalType: "uint256",
            name: "newValue",
            type: "uint256",
          },
        ],
        name: "setMinStakingAmount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    setMinValidatorStakeAmount: {
      targets: "0x0000000000000000000000000000000000007003",
      abi: {
        inputs: [
          {
            internalType: "uint256",
            name: "newValue",
            type: "uint256",
          },
        ],
        name: "setMinValidatorStakeAmount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    setValidatorJailEpochLength: {
      targets: "0x0000000000000000000000000000000000007003",
      abi: {
        inputs: [
          {
            internalType: "uint32",
            name: "newValue",
            type: "uint32",
          },
        ],
        name: "setValidatorJailEpochLength",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
    setVotingPeriod: {
      targets: "0x0000000000000000000000000000000000007002",
      abi: {
        inputs: [
          {
            internalType: "uint256",
            name: "newVotingPeriod",
            type: "uint256",
          },
        ],
        name: "setVotingPeriod",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    },
  });
  const [selectOption, setSelctOption] = useState([""]);
  const [selectNowOption, setSelectNowOption] = useState("activateValidator");
  const [selectNowOptionData, setSelectNowOptionData] = useState(
    selectType[selectNowOption]
  );
  const [modalStatus, setModalStatus] = useState(false);
  const [modalType, setModalType] = useState("");

  const dispatch = useDispatch();

  const changeNowData = () => {
    setSelectNowOptionData(selectType[selectNowOption]);
    setInputArray((preState) => ({
      ...preState,
      targets: selectType[selectNowOption].targets,
      block: "",
      calldatas: "",
      description: "",
      type: selectNowOption,
    }));
    let find = inputData.findIndex((item) => item.styles == styles.address);
    let copied = [...inputData];
    copied[find].cont = selectType[selectNowOption].abi.inputs[0].name;
    copied[find].placeHolder =
      selectType[selectNowOption].abi.inputs[0].type == "address"
        ? "0x..."
        : "0";
    setInputData(copied);
  };

  const getSelectTypeKey = () => {
    let params = Object.keys(selectType);
    setSelctOption(params);
  };

  const contSetting = (text) => {
    let reText = text;
    if (text == "") {
      return text;
    } else {
      if (reText.indexOf("_") != -1) {
        reText = reText.replace("_", "");
      }
      let first = reText.charAt(0);
      let others = reText.slice(1);

      let newText = first.toUpperCase() + others;
      return newText;
    }
  };

  const onChangeInput = (item, value) => {
    switch (item.type) {
      case "calldata":
        onChangeCalldata(item, value);
        break;
      case "date":
        onChangeDate(item, value);
        break;
      case "title":
        onChangeTitle(item, value);
        break;
      case "desc":
        onChangeDesc(item, value);
        break;

      default:
        break;
    }
  };

  const onChangeCalldata = (items, value) => {
    const web3 = new Web3(windowEther);
    let type = selectNowOptionData.abi.inputs[0].type;
    let require_1 =
      type == "address"
        ? value.length != 42 ||
          !/^[a-zA-Z0-9]*$/.test(value) ||
          value.substring(0, 2) != "0x"
        : false;
    if (value != "" && !require_1 && value.length < 43) {
      let find = inputData.findIndex((item) => item.styles == items.styles);
      let copied = [...inputData];
      copied[find].inputState = true;
      copied[find].viewValue = value;
      value =
        selectNowOptionData.abi.name.includes("amount") ||
        selectNowOptionData.abi.name.includes("Amount")
          ? toWei(web3, value)
          : value;
      let encodeFunction = web3.eth.abi.encodeFunctionCall(
        selectNowOptionData.abi,
        [value]
      );
      copied[find].inputValue = encodeFunction;
      setInputData(copied);
    } else if (value == "") {
      let find = inputData.findIndex((item) => item.styles == items.styles);
      let copied = [...inputData];
      copied[find].inputValue = "";
      copied[find].viewValue = "";
      copied[find].inputState = false;

      setInputData(copied);
    } else if (require_1 && value.length < 43) {
      let find = inputData.findIndex((item) => item.styles == items.styles);
      let copied = [...inputData];
      copied[find].viewValue = value;
      copied[find].inputState = false;

      setInputData(copied);
    }
  };

  const onChangeDate = (items, value) => {
    try {
      let blockCount = new BN(`${value}`).mul(new BN(`${28800}`)).toString();
      let find = inputData.findIndex((item) => item.styles == items.styles);
      let copied = [...inputData];
      copied[find].inputValue = blockCount;
      copied[find].viewValue = value;
      copied[find].inputState = true;
      setInputData(copied);
    } catch (error) {
      console.log(error);
      let find = inputData.findIndex((item) => item.styles == items.styles);
      let copied = [...inputData];
      copied[find].inputState = false;
      setInputData(copied);
    }
  };

  const onChangeTitle = (items, value) => {
    let find = inputData.findIndex((item) => item.styles == items.styles);
    let copied = [...inputData];
    copied[find].inputValue = value;
    copied[find].viewValue = value;
    copied[find].inputState = true;

    setInputData(copied);
  };

  const onChangeDesc = (items, value) => {
    let find = inputData.findIndex((item) => item.styles == items.styles);
    let copied = [...inputData];
    copied[find].inputValue = value;
    copied[find].viewValue = value;
    copied[find].inputState = true;

    setInputData(copied);
  };

  const onClickPropose = async () => {
    const web3 = new Web3(windowEther);
    const contract = new web3.eth.Contract(
      governanceABI,
      governanceContractAddress
    );

    let formatDiscription = {
      title: inputData[2].inputValue,
      description: inputData[3].inputValue,
    };
    formatDiscription = JSON.stringify(formatDiscription);
    try {
      setModalStatus(true);
      setModalType("confirm");
      let result = await contract.methods
        .proposeWithCustomVotingPeriod(
          [inputArray.targets],
          ["0"],
          [inputData[0].inputValue],
          formatDiscription,
          inputData[1].inputValue

          // ["0x0000000000000000000000000000000000007004"],
          // ["0"],
          // [""],
          // "{\"title\":\"update\",\"description\":\"update\"}",
          // "100"
        )
        .send({
          from: account,
          value: "0",
        });
      if (result.status == true) {
        setModalType("complete");
      } else {
        setModalType("failed");
      }
      dispatch(setTransactionHash(result.transactionHash));

    } catch (error) {
      // if (error.code == 4001) {
      setModalType("refuse");
      // }
    }
  };

  const checkIsValidator = async () => {
    if (defaultProvider != "") {
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        StakingABI,
        validatorContractAddress
      );
      let validatorAdded = await contract.getPastEvents("ValidatorAdded", {
        filter: {},
        fromBlock: 0,
        toBlock: "latest",
      });

      let promise = [];
      for (let i = 0; i < validatorAdded.length; i++) {
        let address = validatorAdded[i].returnValues.validator;
        let isValidator = await contract.methods
          .isValidator(`${address}`)
          .call();
        if (isValidator) {
          let getValidatorStatus = await contract.methods
            .getValidatorStatus(`${validatorAdded[i].returnValues.validator}`)
            .call();
          promise.push(
            new Promise(async (resolve, reject) => {
              let data = {
                owner: getValidatorStatus.ownerAddress,
                status: account == "" ? false : getValidatorStatus.status,
                validator: validatorAdded[i].returnValues.validator,
              };

              resolve(data);
            })
          );
        }
      }

      Promise.all(promise).then((values) => {
        let find = values.find((item) => {
          return item.owner.toUpperCase() == account.toUpperCase();
        });
        if (find == undefined) {
          navigator(-1);
        } else {
          if (find.status != "1") {
            navigator(-1);
          }
        }
      });
    } else if (account == "") {
      // navigator(-1)
    }
  };

  useEffect(() => {
    getSelectTypeKey();
  }, []);

  useEffect(() => {
    changeNowData();
  }, [selectNowOption]);

  useEffect(() => {
    checkIsValidator();
  }, [defaultProvider, account]);

  return (
    <div className={styles.content}>
      <div className={styles.proposeTitle}>Propose</div>
      <div className={styles.proposeWrap}>
        <div className={styles.proposeSelectBox}>
          <select
            onChange={(e) => {
              setSelectNowOption(e.target.value);
            }}
            onClick={(e) => {
              setSelectStatus(!selectStatus);
            }}
            className={selectStatus ? styles.on : ""}
          >
            {selectOption.map((item, index) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.inputWrap}>
          {inputData.map((item, index) => (
            <div className={styles.inputItem} key={index}>
              <div className={styles.proposeCont}>{contSetting(item.cont)}</div>
              <div
                className={`${styles.inputDiv} ${
                  item.cont == "Date"
                    ? styles.proposeDate
                    : item.cont == "Description"
                    ? styles.description
                    : ""
                }`}
              >
                {item.cont == "Description" ? (
                  <textarea
                    placeholder={item.placeHolder}
                    onChange={(e) => {
                      onChangeInput(item, e.target.value);
                    }}
                    value={item.viewValue}
                  />
                ) : (
                  <input
                    type={"text"}
                    placeholder={item.placeHolder}
                    onChange={(e) => {
                      onChangeInput(item, e.target.value);
                    }}
                    value={item.viewValue}
                  />
                )}
                {item.cont == "Date" ? <span>Days</span> : ""}
              </div>
            </div>
          ))}
        </div>
        <div className={`${styles.button} ${styles.proposeButtons}`}>
          <button
            type="button"
            className={`${styles.btnNormal} ${styles.btnBdBlack} ${styles.btnBdGray} ${styles.validatorAssetsButton}`}
            onClick={() => {
              navigator(-1);
            }}
          >
            Back To List
          </button>
          <button
            type="button"
            className={`${styles.btnNormal} ${styles.btnBgBlue} ${
              styles.validatorAssetsButton
            }
              ${
                inputData[0].inputState &&
                inputData[1].inputState &&
                inputData[2].inputState &&
                inputData[3].inputState
                  ? ""
                  : styles.unAvailableBtn
              }
              `}
            onClick={() => {
              if (
                inputData[0].inputState &&
                inputData[1].inputState &&
                inputData[2].inputState &&
                inputData[3].inputState
              ) {
                onClickPropose();
              }
            }}
          >
            Propose
          </button>
        </div>
        <div className={`${styles.mainFooter} ${styles.proposeFooter}`}></div>
      </div>

      {modalStatus ? (
        <TradeModal
          styles={styles}
          type={modalType}
          onClickCancel={() => {
            setModalStatus(false);
          }}
          click={""}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default GovernancePropose;
