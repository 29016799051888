import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const PrevBtn = styled.button`
  width: 2rem;
  height: 2rem;
  vertical-align: middle;

  @media screen and (max-width: 767px) {
    width: 5rem;
    height: 5rem;
  }

  @media screen and (max-width: 480px) {
    width: 4rem;
    height: 4rem;
  }
`;
const PrevBtnImg = styled.img`
  display: block;
  width: 100%;
`;

const Prev = ({ onClickPrev, nowPage, styles, path, pathname }) => {
  return (
    <button className={styles}>
      <Link
        to={
          Number(nowPage - 1) == 0
            ? `${pathname}`
            : `${path == "/" ? "" : path}${
                Number(nowPage - 1) == 1
                  ? path == "/"
                    ? "/"
                    : ""
                  : "/".concat(Number(nowPage) - 1)
              }`
        }
      >
        <img src={require("../../assets/images/common_ico_list_prev@3x.png")} />
      </Link>
    </button>
  );
};

export default Prev;
