import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Web3 from "web3";
import {
  fromWei,
  fromWeiThousandsSeparator,
  setFromWeiBalance,
  thousandsSeparator,
} from "../common";
import StakingABI from "../abi/Staking.json";
import { BN } from "bn.js";
import styles from "../assets/css/GMMTStaking.module.css";
import axios from "axios";

const Topinfo = ({}) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);

  const validatorContractAddress =
    process.env.REACT_APP_PUBLIC_STAKING_CONTRACT;

  const [totalBlocks, setTotalBlocks] = useState("");
  const [GMMTPrice, setGMMTPrice] = useState("0");
  const [activeValidator, setActiveValidator] = useState([]);
  const [bondedTokens, setBondedTokens] = useState("0");
  const [apr, setApr] = useState("0");

  const getTotalBlocks = async () => {
    if (defaultProvider != "") {
      const web3 = new Web3(defaultProvider)
      let block = await web3.eth.getBlockNumber();
      setTotalBlocks(block);
    }
  };


  const getValidatorLength = async () => {
    if (defaultProvider != "" ){
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        StakingABI,
        validatorContractAddress
      );
      const activeValidator = await contract.methods.getValidators().call();
      setActiveValidator(activeValidator);
    }
  }

  const getActiveValidatorAndBondedToken = async () => {
    if (defaultProvider != "" && activeValidator!= "") {
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        StakingABI,
        validatorContractAddress
      );

      let totalAmount = 0;

      const addedValidator = await contract.getPastEvents("ValidatorAdded", {
        filter: {},
        fromBlock: 0,
        toBlock: "latest",
      });
      const removeValidator = await contract.getPastEvents("ValidatorRemoved", {
        filter: {},
        fromBlock: 0,
        toBlock: "latest",
      });

      let addValidatorList = [];
      let removeValidatorList = [];

      for (let i = 0; i < addedValidator.length; i++) {
        addValidatorList.push(addedValidator[i].returnValues.validator)
      }
      for (let i = 0; i < removeValidator.length; i++) {
        removeValidatorList.push(removeValidator[i].returnValues.validator)
      }

      let allValidator =  addValidatorList.filter((x) => {
        return !removeValidatorList.includes(x);
      })

      for (let i = 0; i < allValidator.length; i++) {
        let getValidatorStatus = await contract.methods
          .getValidatorStatus(allValidator[i])
          .call();
        totalAmount = new BN(`${getValidatorStatus.totalDelegated}`)
          .add(new BN(`${totalAmount}`))
          .toString();
      }
      setBondedTokens(totalAmount);
    }
  };

  const getGMMTPrice = async () => {
    try {
      let priceList = await axios.get(
        "https://api.coingecko.com/api/v3/coins/giant-mammoth/market_chart?vs_currency=usd&days=1"
      );
      let price = priceList.data.prices[0][1];
      setGMMTPrice(price);
    } catch (error) {
      console.log("",error)
    }

  };

  const getAPR = async () => {
    if(bondedTokens!="0"&&defaultProvider!=""){

      try {
        const web3 = new Web3(defaultProvider);
        const contract = new web3.eth.Contract(
          StakingABI,
          validatorContractAddress
        );
        
        const ecosystem = await contract.methods.ECOSYSTEM_REWARD().call();
  
        let ecosystemYear = new BN(`${ecosystem}`).mul(new BN(`${365}`)).toString();
        let bondedTokensPercent = Number(ecosystemYear) / bondedTokens;
        bondedTokensPercent = bondedTokensPercent * 100;
        bondedTokensPercent = bondedTokensPercent.toFixed(0);
        setApr(bondedTokensPercent)
        
      } catch (error) {
        
      }
    }
  }

  useEffect(() => {
    getTotalBlocks();
    setInterval(async () => {
      getTotalBlocks();
    }, [1000]);
  }, [defaultProvider]);

  useEffect(() => {
    getActiveValidatorAndBondedToken();
    setInterval(async () => {
      getActiveValidatorAndBondedToken();
    }, [5000]);
  }, [defaultProvider,activeValidator]);

  useEffect(() => {
    getValidatorLength();
    setInterval(async () => {
      getValidatorLength();
    }, [5000]);
  }, [defaultProvider]);

  useEffect(() => {
    getGMMTPrice();
    setInterval(async () => {
      getGMMTPrice();
    }, [5000]);
  }, []);

  useEffect(() => {
    getAPR();
  },[defaultProvider,bondedTokens])

  return (
    <div className={styles.topInfo}>
      <div>
        <span className={styles.tit}>Total Blocks</span>
        <span className={`${styles.cont} ${styles.blue}`}>
          {thousandsSeparator(totalBlocks)}
        </span>
      </div>
      <div>
        <span className={styles.tit}>GMMT</span>
        <span className={styles.cont}>$ {Number(GMMTPrice).toFixed(4)}</span>
      </div>
      <div>
        <span className={styles.tit}>Active Validator</span>
        <span className={styles.cont}>{activeValidator.length}</span>
      </div>
      <div>
        <span className={styles.tit}>APR</span>
        <span className={styles.cont}>{thousandsSeparator(apr)} %</span>
      </div>
      <div className={styles.last}>
        <span className={`${styles.tit} ${styles.last}`}>Bonded Coin</span>
        <span className={styles.cont}>
          {/* {`${setFromWeiBalance(defaultProvider, bondedTokens)}`} */}
          {`${fromWeiThousandsSeparator(new Web3(defaultProvider), bondedTokens)}`}
        </span>
      </div>
    </div>
  );
};

export default Topinfo;
