import React from "react";
import { useSelector } from "react-redux";
import {
  fromWei,
  fromWeiDecimal,
  onClickScan,
  setFromWeiBalance,
  subString,
  sum,
} from "../../common";

const ReDelegateComplete = ({ styles, item, onClickCancel, click }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  const transactionHash = useSelector((state) => state.user.transactionHash);

  return (
    <div className={`${styles.popCont}`}>
      <p className={`${styles.popTit}`}>
        <span>ReDelegate Assets Completed Successfully</span>
        <img
          src={require("../../assets/images/ico_close_bl.png")}
          alt="close_Icon"
          className={`${styles.closeBtn}`}
          onClick={onClickCancel}
        />
      </p>
      <ul className={`${styles.valueList}`}>
        <li>
          <span className={`${styles.tit}`}>Selected Validator</span>
          <span className={`${styles.value}`}>
            {subString(item.validatorAddress)}
          </span>
        </li>
        <li>
          <span className={`${styles.tit}`}>Commission Rate</span>
          <span className={`${styles.value}`}>
            {item.commissionRate / 100}%
          </span>
        </li>
        <li>
          <span className={`${styles.tit}`}>Status</span>
          <span className={`${styles.value}`}>
            {item.status == "1"
              ? "Active"
              : item.status == "2"
              ? "InActive"
              : "Jail"}
          </span>
        </li>
        <li>
          <span className={`${styles.tit}`}>ReDelegated Asset</span>
          <span className={`${styles.value}`}>
            {setFromWeiBalance(defaultProvider, item.amountToStake)} GMMT
          </span>
        </li>
      </ul>
      <div className={`${styles.balanceBox}`}>
        <p className={`${styles.subTit}`}>Delegated Asset</p>
        <div className={`${styles.box}`}>
          <span>
            {sum(
              defaultProvider,
              fromWei(defaultProvider, item.delegatedAmount),
              fromWei(defaultProvider, item.amountToStake)
            )}{" "}
            GMMT
          </span>
          <span className={`${styles.plus}`}>
            (+ {setFromWeiBalance(defaultProvider, item.amountToStake)} GMMT)
          </span>
        </div>
      </div>
      <div className={`${styles.btnWrap}`}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnCancel}`}
          onClick={onClickCancel}
        >
          Close
        </button>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnNext}`}
          onClick={() => onClickScan(transactionHash)}
        >
          View on Block Explorer
        </button>
      </div>
    </div>
  );
};

export default ReDelegateComplete;
