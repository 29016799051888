import React from "react";
import { useSelector } from "react-redux";
import { setFromWeiBalance, subString, thousandsSeparator } from "../../common";

const InforWrap = ({ validatorInfo, styles, mobileCheck }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);

  return (
    <div className={`${styles.infoWrap}`}>
      <div className={`${styles.top}`}>
        <div className={`${styles.img}`}>
          <img />
        </div>
        <p className={`${styles.name}`}>
          {mobileCheck
            ? subString(validatorInfo.validatorAddress)
            : validatorInfo.validatorAddress}
        </p>
      </div>
      <div className={`${styles.content}`}>
        <div className={`${styles.box}`}>
          <div>
            <span className={styles.tit}>Owner Address</span>
            <span className={styles.cont}>
              {mobileCheck
                ? subString(validatorInfo.ownerAddress)
                : validatorInfo.ownerAddress}
            </span>
          </div>
          <div>
            <span className={styles.tit}>Commission Rate</span>
            <span className={styles.cont}>
              {validatorInfo.commissionRate / 100}%
            </span>
          </div>
          <div>
            <span className={styles.tit}>Status</span>
            <span className={styles.cont}>
              {" "}
              {validatorInfo.status == "1"
                ? "Active"
                : validatorInfo.status == "2"
                ? "InActive"
                : "Jail"}
            </span>
          </div>
        </div>
        <div className={`${styles.box}`}>
          <div>
            <span className={styles.tit}>Total Delegated</span>
            <span className={`${styles.cont} ${styles.blue} ${styles.fwb}`}>
              {setFromWeiBalance(defaultProvider, validatorInfo.totalDelegated)}{" "}
              GMMT
            </span>
          </div>
          <div>
            <span className={styles.tit}>Jailed Before</span>
            <span className={styles.cont}>
              {thousandsSeparator(validatorInfo.jailedBefore)} Epoch
            </span>
          </div>
          <div>
            <span className={styles.tit}>Slash Counts</span>
            <span className={styles.cont}>
              {thousandsSeparator(validatorInfo.slashesCount)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InforWrap;
