import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const GovernanceNavBar = ({ styles }) => {
  const { pathname } = useLocation();
  const [linkParams, setLinkParams] = useState([
    {
      name: "Vote",
      path: "/governance/vote/all",
    },
  ]);
  const [nowPathName, setNowPathName] = useState("");

  const checkPath = () => {
    let splitPathName = pathname.split("/");
    if (
      splitPathName[0] == "" &&
      (!isNaN(splitPathName[1]) || splitPathName[1] == "")
    ) {
      setNowPathName("Validators");
    }
    if (splitPathName[0] == "" && splitPathName[2] == "vote") {
      setNowPathName("Vote");
    } else if (splitPathName[0] == "" && splitPathName[2] == "propose") {
      setNowPathName("Propose");
    }
  };

  useEffect(() => {
    checkPath();
  }, [pathname]);
  
  return (
    <ul className={`${styles.tabWrap}`}>
      {linkParams.map((item, index) => (
        <li
          key={item.name}
          className={
            nowPathName.toLocaleLowerCase() ==
            item.name.toLocaleLowerCase()
              ? styles.on
              : ""
          }
        >
          <Link to={`${item.path}`}>{item.name}</Link>
        </li>
      ))}
    </ul>
  );
};

export default GovernanceNavBar;
