import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setGovernanceType } from "../../redux/user";

const SelectBox = ({ styles,nowPage }) => {
  const [selectStatus, setSelectStatus] = useState(false);
  const [option, setOption] = useState([
    {
      name: "All",
      value: "all",
    },
    {
      name: "Vote",
      value: "vote",
    },
    {
      name: "Defeated",
      value: "defeated",
    },
    {
      name: "Succeeded",
      value: "succeeded",
    },
    {
      name: "Expired",
      value: "expired",
    },
    {
      name: "Executed",
      value: "executed",
    },
  ]);
  const governanceType = useSelector((state) => state.user.governanceType);

  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { pathname } = useLocation();

  const handleSelect = (value) => {
    dispatch(setGovernanceType(value));
    navigator(`/governance/vote/${value}/1`);
  };

  const checkInject = () => {
    let split = pathname.split("/");
    if(!isNaN(split[split.length-1])){
      split.pop();
    }
    dispatch(setGovernanceType(split[split.length - 1]));
  };

  useEffect(() => {
    let split = pathname.split("/");
    if(!isNaN(split[split.length-1])){
      split.pop();
    }
    let join = split.join("/");
    if(join.includes("/governance/vote") ||join.includes("/governance/propose") ){
      checkInject();
    }
  }, [pathname,nowPage]);

  return (
    <div className={styles.selectWrap}>
      <select
        className={`${styles.selectBox} ${selectStatus ? styles.on : ""}`}
        onChange={(e) => {
          handleSelect(e.target.value);
        }}
        onClick={(e) => {
          setSelectStatus(!selectStatus);
        }}
        value={governanceType}
      >
        {option.map((item, index) => (
          <option className={styles.option} value={item.value}
          key={index}
          >
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectBox;
