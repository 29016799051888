import React, { useEffect, useState } from "react";
import Stake from "./Stake";
import StakeComplete from "./StakeComplete";
import UnStake from "./UnStake";
import UnStakeComplete from "./UnStakeComplete";
import ReDelegate from "./ReDelegate";
import ReDelegateComplete from "./ReDelegateComplete";
import Confirm from "./Confirm";
import Failed from "./Failed";
import { fromWei } from "../../common";
import Web3 from "web3";
import { useSelector } from "react-redux";
import { BN } from "bn.js";
import ChainConfigABI from "../../abi/ChainConfig.json";
import Claim from "./Claim";
import ClaimComplete from "./ClaimComplete";
import ValidatorClaim from "./ValidatorClaim";
import ValidatorClaimComplete from "./ValidatorClaimComplete";
import SetRegisterValidator from "./SetRegisterValidator";
import Complete from "./Complete";
import ChangeCommission from "./ChangeCommission";
import Vote from "./Vote";

const TradeModal = ({ id, type, styles, item, onClickCancel, click }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  const balance = useSelector((state) => state.user.balance);

  const [inputValue, setInputValue] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);

  const chainConfigAddress = process.env.REACT_APP_PUBLIC_CHAINCONFIG;

  const onChangeInput = (e) => {
    let inputAmount = e.target.value;
    let pattern = /^[0-9]*[.,]?[0-9]*$/;
    let bool = /\./.test(inputAmount)
      ? /\.\d+/.test(inputAmount)
        ? inputAmount.toString().match(/\.(\d+)/)[1].length < 9
        : true
      : true;
    if (inputAmount == "") {
      setBtnStatus(false);
      setInputValue("");
    } else {
      if (pattern.test(inputAmount) == true) {
        if (bool) {
          const web3 = new Web3(defaultProvider);
          let toWeiBalance = web3.utils.toWei(inputAmount);
          let toWeiMinBalance = web3.utils.toWei(minAmount);
          let amounts = type == "stake" ? balance : item.delegatedAmount;
          let status = new BN(`${toWeiBalance}`).lte(new BN(`${amounts}`));
          if (Number(toWeiBalance) < Number(toWeiMinBalance)) {
            setBtnStatus(false);
          } else {
            setBtnStatus(status);
          }
          setInputValue(inputAmount);
        }
      }
    }
  };

  const getMinAmonut = async () => {
    const web3 = new Web3(defaultProvider);
    const contract = new web3.eth.Contract(ChainConfigABI, chainConfigAddress);
    const minAmount = await contract.methods.getMinStakingAmount().call();
    setMinAmount(fromWei(web3, minAmount));
  };

  const onClickMax = async (value) => {
    const web3 = new Web3(defaultProvider);
    let values = fromWei(web3, value);
    if(!(/^-?\d+$/.test(values))){
      const regex = /^-?\d+(?:\.\d{0,8})?/;
      const match = regex.exec(values);
      const decimalPart_before = match[0].match(/\d+/)[0];
      const decimalPart_after = match[0].match(/\.\d+/)[0];
      values = decimalPart_before.concat(decimalPart_after)
    }
    setInputValue(values);
    let status = new BN(`${value}`).gte(new BN(`${minAmount * 10 ** 18}`));
    setBtnStatus(status);
  };

  useEffect(() => {
    getMinAmonut();
  }, [defaultProvider]);

  return (
    <div
      id={
        type == "confirm"
          ? styles.loadingPop
          : type == "refuse" || type == "failed"
          ? styles.failPop
          : type == "complete"
          ? styles.completePop
          : ""
      }
      className={styles.popOuter}
    >
      <div className={styles.popupDim} />
      {type == "stake" ? (
        <Stake
          styles={styles}
          item={item}
          btnStatus={btnStatus}
          onClickMax={onClickMax}
          onClickCancel={onClickCancel}
          click={click}
          minAmount={minAmount}
          inputValue={inputValue}
          onChangeInput={onChangeInput}
        />
      ) : type == "stakeComplete" ? (
        <StakeComplete
          styles={styles}
          item={item}
          inputValue={inputValue}
          onClickCancel={onClickCancel}
        />
      ) : type == "unstake" ? (
        <UnStake
          onClickCancel={onClickCancel}
          styles={styles}
          item={item}
          btnStatus={btnStatus}
          onClickMax={onClickMax}
          click={click}
          minAmount={minAmount}
          inputValue={inputValue}
          onChangeInput={onChangeInput}
        />
      ) : type == "unstakeComplete" ? (
        <UnStakeComplete
          styles={styles}
          onClickCancel={onClickCancel}
          inputValue={inputValue}
          item={item}
        />
      ) : type == "claim" ? (
        <Claim
          styles={styles}
          onClickCancel={onClickCancel}
          item={item}
          click={click}
        />
      ) : type == "claimComplete" ? (
        <ClaimComplete
          styles={styles}
          onClickCancel={onClickCancel}
          item={item}
        />
      ) : type == "validatorClaim" ? (
        <ValidatorClaim
          styles={styles}
          onClickCancel={onClickCancel}
          item={item}
          click={click}
        />
      ) : type == "validatorClaimComplete" ? (
        <ValidatorClaimComplete
          styles={styles}
          onClickCancel={onClickCancel}
          item={item}
        />
      ) : type == "redelegate" ? (
        <ReDelegate
          styles={styles}
          onClickCancel={onClickCancel}
          item={item}
          click={click}
        />
      ) : type == "redelegateComplete" ? (
        <ReDelegateComplete
          styles={styles}
          onClickCancel={onClickCancel}
          item={item}
        />
      ) : type == "confirm" ? (
        <Confirm styles={styles} onClickCancel={onClickCancel} />
      ) : type == "failed" || type == "refuse" ? (
        <Failed styles={styles} type={type} onClickCancel={onClickCancel} />
      ) : type == "complete" ? (
        <Complete styles={styles} onClickCancel={onClickCancel} />
      ) : type == "vote" ? (
        <Vote
          styles={styles}
          onClickCancel={onClickCancel}
          click={click}
          item={item}
        />
      ) : type == "setRegisterValidator" ? (
        <SetRegisterValidator
          styles={styles}
          onClickCancel={onClickCancel}
          click={click}
        />
      ) : type == "changeCommission" ? (
        <ChangeCommission
          styles={styles}
          item={item}
          onClickCancel={onClickCancel}
          click={click}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default TradeModal;
