import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  jsonTitle,
  setFromWeiBalance,
  setNumUnit,
  thousandsSeparator,
} from "../../common/index";
import BtnBdBlue from "../Buttons/BtnBdBlue";
import BtnBlue from "../Buttons/BtnBlue";

const TbodyItem = ({
  limit,
  nowPageNum,
  item,
  index,
  styles,
  onClickExecute,
  onClickModal,
}) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  const account = useSelector((state) => state.account.account);
  const [btnStatus, setBtnStatus] = useState(false);
  const [text, setText] = useState("");

  const getText = () => {
    let checkText;
    switch (item.state) {
      case "1":
        checkText = item.hasVoted ? "Voted" : "Vote";
        break;
      case "3":
        checkText = "Defeated";
        break;
      case "4":
        checkText = "Execute";

        break;
      case "6":
        checkText = "Expired";

        break;
      case "7":
        checkText = "Complete";

        break;

      default:
        break;
    }
    setText(checkText);
  };

  const clickEventList = (items) => {
    if (account != "") {
      switch (items.state) {
        case "1":
          if (!items.hasVoted) {
            onClickModal(items);
          }
          break;
        case "4":
          onClickExecute(item);
          break;
      }
    }
  };

  const getBtnStatus = () => {
    let bool =
      (item.state == "1" && item.hasVoted == false) || item.state == "4"
        ? true
        : false;
    setBtnStatus(bool);
  };

  useEffect(() => {
    getText();
  }, [item]);

  useEffect(() => {
    getBtnStatus();
  }, [item]);

  return (
    <div className={`${styles.tbody}`}>
      <div className={`${styles.no} ${styles.fwb}`}>
        {limit * (nowPageNum - 1) + (index + 1)}
      </div>
      <div className={`${styles.title}`} title={jsonTitle(item.description)}>
        <Link to={`/governance/proposeInfo/${item.proposalId}`}>
          <div className={`${styles.img}`}>
            {/* <img src={require("")} /> */}
            <img />
          </div>
          <p className={`${styles.name} ${styles.fwb}`}>
            {jsonTitle(item.description)}
          </p>
        </Link>
      </div>
      <div className={`${styles.starTime}`} title={thousandsSeparator(item.startBlock)}>{setNumUnit(item.startBlock)}</div>
      <div className={`${styles.endTime}`} title={thousandsSeparator(item.endBlock)}>{setNumUnit(item.endBlock)}</div>
      {/* <div className={`${styles.starTime}`}>{item.startTime}</div>
      <div className={`${styles.endTime}`}>{item.endTime}</div> */}
      <div className={`${styles.proposalVotes}`}>
        {setFromWeiBalance(defaultProvider, item.proposalVotes)}
      </div>
      <div className={`${styles.button}`}>
        <BtnBdBlue
          className={`${styles.btnBdBlue}`}
          styles={`${styles.btnNormal} ${styles.btnBdBlue} ${
            account == ""
              ? styles.unAvailableBtn
              : btnStatus
              ? ""
              : styles.unAvailableBtn
          }`}
          text={text}
          item={item}
          click={clickEventList}
        />
      </div>
    </div>
  );
};

export default TbodyItem;

// stake 버튼 만들기
