import React from "react";
import { useSelector } from "react-redux";
import {
  subString,
  setFromWeiBalance,
} from "../../common";

const UnStake = ({
  styles,
  item,
  btnStatus,
  onClickMax,
  onClickCancel,
  click,
  minAmount,
  inputValue,
  onChangeInput,
}) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);

  return (
    <div className={`${styles.popCont}`}>
      <p className={`${styles.popTit}`}>
        <span>Unstake GMMT</span>
        <img
          src={require("../../assets/images/ico_close_bl.png")}
          alt="close_Icon"
          className={`${styles.closeBtn}`}
          onClick={onClickCancel}
        />
      </p>
      <ul className={`${styles.valueList}`}>
        <li>
          <span className={`${styles.tit}`}>Selected Validator</span>
          <span className={`${styles.value}`}>
            {subString(item.validatorAddress)}
          </span>
        </li>
        <li>
          <span className={`${styles.tit}`}>Commission Rate</span>
          <span className={`${styles.value}`}>
            {item.commissionRate / 100}%
          </span>
        </li>
        <li>
          <span className={`${styles.tit}`}>Status</span>
          <span className={`${styles.value}`}>
            {item.status == "1"
              ? "Active"
              : item.status == "2"
              ? "InActive"
              : "Jail"}
          </span>
        </li>
        <li>
          <span className={`${styles.tit}`}>Delegated Asset</span>
          <span className={`${styles.value}`}>
            {setFromWeiBalance(defaultProvider, item.delegatedAmount)}{" "}
            GMMT
          </span>
        </li>
        {/* <li>
          <span className={`${styles.tit}`}>Claimable Asset</span>
          <span className={`${styles.value}`}>14,000 GMMT</span>
        </li> */}
      </ul>
      <div className={`${styles.valueBox}`}>
        <p className={`${styles.subTit}`}>Unstake Amount</p>
        <div className={`${styles.psr}`}>
          <input
            type="text"
            className={`${styles.iptAmount}`}
            placeholder={`min ${minAmount} GMMT`}
            value={inputValue}
            onChange={(e) => {
              onChangeInput(e);
            }}
          />
          {/* <button
            type="button"
            className={`${styles.btnMax}`}
            onClick={() => {
              onClickMax(item.delegatedAmount);
            }}
          >
            Max
          </button> */}
        </div>
      </div>
      <div className={`${styles.btnWrap}`}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnCancel}`}
          onClick={onClickCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnNext} ${
            btnStatus ? "" : styles.inActive
          }`}
          onClick={() => {
            if(btnStatus)click(item.validatorAddress, inputValue)
          }}
        >
          Unstake
        </button>
      </div>
    </div>
  );
};

export default UnStake;
