import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Next from "../Buttons/Next";
import Prev from "../Buttons/Prev";

const ViewPage = ({ totalPage, nowPage, pageLimit, styles, path, visibility }) => {
  const { pathname } = useLocation();
  const account = useSelector((state) => state.account.account);

  const setPage = () => {
    const result = [];

    let count = pageLimit;
    if (totalPage < pageLimit) {
      count = totalPage;
    }

    let pageGroup = Math.ceil(nowPage / count);
    let last = pageGroup * count;
    if (last > totalPage) {
      last = totalPage;
    }
    let first = last - (count - 1);

    for (let i = first; i <= last; i++) {
      result.push(
        <li key={i}>
          <Link
            key={i}
            to={
              nowPage == i
                ? `${pathname}`
                : `${path == "/" ? "" : path}${
                    i == 1 ? (path == "/" ? "/" : "") : "/".concat(i)
                  }`
            }
            className={`${nowPage == i ? styles.on : ""}`}
          >
            {i}
          </Link>
        </li>
      );
    }
    return result;
  };

  const [nowPathName_2, setNowPathName_2] = useState("");
  const [linkParams_2, setLinkParams_2] = useState([
    {
      name: "Validators",
      path: "/",
    },
    {
      name: "My staking",
      path: "/myStaking/stakedAssets",
    },
    {
      name: "Validator Assets",
      path: "/validatorAssets/stakedAssets",
    },
  ]);

  const checkPath_2 = () => {
    let splitPathName = pathname.split("/");
    if (
      splitPathName[0] == "" &&
      (!isNaN(splitPathName[1]) || splitPathName[1] == "")
    ) {
      setNowPathName_2("Validators");
    }
    if (splitPathName[0] == "" && splitPathName[1] == "validatorAssets") {
      setNowPathName_2("Validator Assets");
    } else if (splitPathName[0] == "" && splitPathName[1] == "myStaking") {
      setNowPathName_2("My Staking");
    }
  };

  useEffect(() => {
    checkPath_2();
  }, [pathname]);

  return (
    <div
      className={`${styles.paging} ${
        nowPathName_2.toLocaleLowerCase() ==
          linkParams_2[0].name.toLocaleLowerCase() && account != ""
          ? styles.mainPaging
          : nowPathName_2.toLocaleLowerCase() ==
              linkParams_2[0].name.toLocaleLowerCase() && account == ""
          ? styles.mainPagingNone
          : ""
      }`}
      style={{
        visibility: visibility ? "hidden" : "visible",
        display: visibility && pageLimit == 5 ? "none" : "block",
      }}
    >
      <ul>
        <li>
          <Prev
            nowPage={nowPage}
            styles={styles.btnPrev}
            path={path}
            pathname={pathname}
          />
        </li>
        {setPage()}
        <li>
          <Next
            nowPage={nowPage}
            totalPage={totalPage}
            styles={styles.btnNext}
            path={path}
            pathname={pathname}
          />
        </li>
      </ul>
    </div>
  );
};

export default ViewPage;
