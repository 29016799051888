import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  fromWei,
  fromWeiToFixed,
  setFromWeiBalance,
  subString,
  thousandsSeparator,
} from "../../common";

import ChainConfigABI from "../../abi/ChainConfig.json";
import Web3 from "web3";
import { BN } from "bn.js";

const Stake = ({
  styles,
  item,
  btnStatus,
  onClickMax,
  onClickCancel,
  click,
  minAmount,
  inputValue,
  onChangeInput,
}) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  const balance = useSelector((state) => state.user.balance);

  return (
    <div className={styles.popCont}>
      <p className={styles.popTit}>Stake GMMT</p>
      <ul className={styles.valueList}>
        <li>
          <span className={styles.tit}>Selected Validator</span>
          <span className={styles.value}>{subString(item.validatorAddress)}</span>
        </li>
        <li>
          <span className={styles.tit}>Commission Rate</span>
          <span className={styles.value}>{item.commissionRate / 100}%</span>
        </li>
        <li>
          <span className={styles.tit}>Status</span>
          <span className={styles.value}>
            {item.status == "1"
              ? "Active"
              : item.status == "2"
              ? "InActive"
              : "Jail"}
          </span>
        </li>
      </ul>
      <div className={styles.valueBox}>
        <p className={styles.subTit}>GMMT Amount</p>
        <div className={styles.psr}>
          <input
            type="text"
            className={styles.iptAmount}
            placeholder={`min ${minAmount} GMMT`}
            value={inputValue}
            onChange={(e) => {
              onChangeInput(e);
            }}
          />
          {/* <button
            type="button"
            className={styles.btnMax}
            onClick={() => {
              onClickMax(balance);
            }}
          >
            Max
          </button> */}
        </div>
        <div className={styles.balanceInfo}>
          <span className={styles.tit}>Balance</span>
          <span className={styles.value}>
            {setFromWeiBalance(defaultProvider, balance)} GMMT
          </span>
        </div>
      </div>
      <div className={styles.btnWrap}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnCancel}`}
          onClick={onClickCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnNext} ${
            btnStatus ? "" : styles.disabled
          }`}
          onClick={() => {
            click(btnStatus, item.validatorAddress, inputValue);
          }}
        >
          Stake
        </button>
      </div>
    </div>
  );
};

export default Stake;
