import React from "react";
import { useSelector } from "react-redux";
import {
  onClickScan,
  setFromWeiBalance,
  setSorUnitNum,
  subString,
  thousandsSeparator,
} from "../../common";

const MyHistory = ({ styles, item }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);

  return (
    <div className={`${styles.tbody}`}>
      <div className={`${styles.validator}`}>
        <div className={`${styles.img}`}>
          <img src="" />
        </div>
        <p className={`${styles.name} ${styles.fwb}`}>
          {subString(item.validator)}
        </p>
      </div>
      <div className={`${styles.address}`}>{subString(item.validator)}</div>
      <div className={`${styles.amount}`}>
        {setFromWeiBalance(defaultProvider, item.amount)}{" "}GMMT
      </div>
      <div className={`${styles.type}`}>{item.type}</div>
      <div className={`${styles.epoch}`}>{thousandsSeparator(item.epoch)}</div>
      <div className={`${styles.block}`}>
        {setSorUnitNum(item.blockNumber)}
      </div>
      <div className={`${styles.time}`}>{item.time}</div>
      <div className={`${styles.button}`}>
        <div onClick={()=>onClickScan(item.transactionHash)} className={`${styles.btnExplorer}`}>
          explorer
        </div>
      </div>
    </div>
  );
};

export default MyHistory;
