import React from "react";
import { Link } from "react-router-dom";

const SubTabWrap = ({ styles, option, nowOption }) => {
  return (
    <div className={`${styles.subTabWrap}`}>
      <ul>
        {option.map((item, index) => (
          <li
            className={`${nowOption == item.path ? styles.on : ""}`}
            key={index}
          >
            <Link to={item.path}>{item.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubTabWrap;
