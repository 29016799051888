import { BN } from "bn.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Web3 from "web3";
import chainConfigABI from "../../abi/ChainConfig.json";
import stakingABI from "../../abi/Staking.json";
import { fromWei } from "../../common";

const SetRegisterValidator = ({ styles, click, onClickCancel }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);
  const balance = useSelector((state) => state.user.balance);
  const account = useSelector((state) => state.account.account);
  const chainConfigContractAddress = process.env.REACT_APP_PUBLIC_CHAINCONFIG;
  const stakingContractAddress = process.env.REACT_APP_PUBLIC_STAKING_CONTRACT;

  const [inputItem, setInputItem] = useState([
    {
      name: "Account",
      placeHolder: "0x...",
      max: false,
      value: "",
    },
    {
      name: "Commission",
      placeHolder: "Max",
      max: true,
      value: "",
    },
    {
      name: "Stake Amount",
      placeHolder: "Min",
      max: false,
      value: "",
    },
  ]);
  const [maxCommission, setMaxCommission] = useState("30");
  const [minStakeAmount, setMinStakeAmount] = useState("");

  const [btnBool, setBtnBool] = useState({
    account: false,
    commission: false,
    stakeAmount: false,
  });

  const [validatorList, setValidatorList] = useState([]);

  const getMinStakeAmount = async () => {
    if (defaultProvider != "") {
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        chainConfigABI,
        chainConfigContractAddress
      );

      let getMinValidatorStakeAmount = await contract.methods
        .getMinValidatorStakeAmount()
        .call();
      getMinValidatorStakeAmount = web3.utils.fromWei(
        getMinValidatorStakeAmount
      );
      setMinStakeAmount(getMinValidatorStakeAmount);
    }
  };

  const onChangeInputData = (type, value) => {
    switch (type) {
      case inputItem[0].name:
        reAddress(value);
        break;
      case inputItem[1].name:
        reCommission(value);
        break;
      case inputItem[2].name:
        reStakeAmount(value);
        break;

      default:
        break;
    }
  };

  const getValidatorList = async () => {
    if (defaultProvider != "") {
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        stakingABI,
        stakingContractAddress
      );
      let validatorAdded = await contract.getPastEvents("ValidatorAdded", {
        filter: {},
        fromBlock: 0,
        toBlock: "latest",
      });

      let promise = [];

      for (let i = 0; i < validatorAdded.length; i++) {
        let isValidator = await contract.methods
          .isValidator(`${validatorAdded[i].returnValues.validator}`)
          .call();
        if (isValidator) {
          promise.push(
            new Promise(async (resolve, inject) => {
              let data = {
                validator: validatorAdded[i].returnValues.validator,
                owner: validatorAdded[i].returnValues.owner,
              };

              resolve(data);
            })
          );
        }
      }

      Promise.all(promise).then((values) => {
        setValidatorList(values);
      });
    }
  };

  const reAddress = async (value) => {
    let test_1 = value.substring(0, 2);
    const re = /^[a-zA-Z0-9]*$/.test(value);
    let bool = false;
    if (test_1 == "0x" && value.length == 42 && re) {
      try {
        let findValidator_input = validatorList.find((e) => {
          return e.validator.toLowerCase() == value.toLowerCase();
        });
        let findValidator_userAccount = validatorList.find((e) => {
          return e.owner.toLowerCase() == account.toLowerCase();
        });

        if (
          findValidator_input == undefined &&
          findValidator_userAccount == undefined
        ) {
          bool = true;
        } else {
          bool = false;
        }
      } catch (error) {
        bool = false;
      }
    }

    setBtnBool((prev) => ({
      ...prev,
      account: bool,
    }));
    let findIndex = inputItem.findIndex((item) => item.name == "Account");
    let copiedItems = [...inputItem];
    copiedItems[findIndex].value = value;
    setInputItem(copiedItems);
  };

  const reCommission = (value) => {
    // if (value.includes(".") != undefined && value != "") {
    if (/^-?\d+$/.test(value)) {
      let inputValue = value;
      if (value > 30) {
        inputValue = 30;
      }
      let findIndex = inputItem.findIndex((item) => item.name == "Commission");
      let copiedItems = [...inputItem];
      copiedItems[findIndex].value = inputValue;
      setInputItem(copiedItems);
      setBtnBool((prev) => ({
        ...prev,
        commission: value == "" ? false : true,
      }));
    } else if (value == "") {
      let findIndex = inputItem.findIndex((item) => item.name == "Commission");
      let copiedItems = [...inputItem];
      copiedItems[findIndex].value = "";
      setInputItem(copiedItems);
      setBtnBool((prev) => ({
        ...prev,
        commission: value == "" ? false : true,
      }));
    }
  };

  const reStakeAmount = (value) => {
    let pattern = /^[0-9]*[.,]?[0-9]*$/;
    let subString = 0;
    if(value.indexOf(".") != undefined){
      let inputAmountCom = value.indexOf(".");
      subString = value.substring(inputAmountCom + 1, inputAmountCom.length);
    }
    if (value == "") {
      let findIndex = inputItem.findIndex(
        (item) => item.name == "Stake Amount"
      );
      let copiedItems = [...inputItem];
      copiedItems[findIndex].value = value;
      setInputItem(copiedItems);
      setBtnBool((prev) => ({
        ...prev,
        stakeAmount: Boolean(false),
      }));
    } else {
      if (pattern.test(value) == true) {
        if (subString.length < 9) {
          const web3 = new Web3(defaultProvider)
          let toWeiValue = web3.utils.toWei(value);
          let toWeiMinBalance = web3.utils.toWei(minStakeAmount);
          let q1 = new BN(`${toWeiValue}`).gte(new BN(`${toWeiMinBalance}`));
          let q2 = new BN(`${toWeiValue}`).lte(new BN(`${balance}`));
          let bool = q1 && q2;
          let findIndex = inputItem.findIndex(
            (item) => item.name == "Stake Amount"
          );
          let copiedItems = [...inputItem];
          copiedItems[findIndex].value = value;
          setInputItem(copiedItems);
          setBtnBool((prev) => ({
            ...prev,
            stakeAmount: Boolean(bool),
          }));
        } else {
        }
      }
    }
  };

  const onClickMax = (type, value) => {
    let sendData = value;
    if (type != "Commission") {
      const web3 = new Web3(defaultProvider)
      sendData = fromWei(web3, value);
    }
    onChangeInputData(type, sendData);
  };

  useEffect(() => {
    getMinStakeAmount();
  }, [defaultProvider]);

  useEffect(() => {
    getValidatorList();
  }, [defaultProvider]);

  return (
    <div className={styles.popCont}>
      <p className={styles.popTit}>Register Validator</p>
      {inputItem.map((item, index) => (
        <div
          className={`${styles.valueBox} ${styles.registerInput}`}
          key={index}
        >
          <p className={styles.subTit}>{item.name}</p>
          <div className={styles.psr}>
            <input
              type="text"
              className={styles.iptAmount}
              placeholder={`${item.placeHolder} ${
                index == 0 ? "" : index == 1 ? maxCommission : minStakeAmount
              }${index == 0 ? "" : index == 1 ? "%" : "GMMT"}`}
              onChange={(e) => {
                onChangeInputData(item.name, e.target.value);
              }}
              value={item.value}
            />
            {item.max ? (
              <button
                type="button"
                className={styles.btnMax}
                onClick={() => {
                  onClickMax(item.name, item.name=="Commission"?30:balance);
                }}
              >
                Max
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      ))}
      <div className={styles.btnWrap}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnCancel}`}
          onClick={onClickCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnNext} ${
            btnBool.account && btnBool.commission && btnBool.stakeAmount
              ? ""
              : styles.inActive
          }`}
          onClick={() => {
            if (btnBool.account && btnBool.commission && btnBool.stakeAmount) {
              click(inputItem);
            }
          }}
        >
          Register
        </button>
      </div>
    </div>
  );
};

export default SetRegisterValidator;
