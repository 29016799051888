import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Complete = ({ styles, onClickCancel, type }) => {
  const navigator = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className={`${styles.popCont} ${styles.msg}`}>
      <img
        src={require("../../assets/images/common_ico_pop_complete@3x.png")}
        alt="ico complete"
      />
      <p>Transaction is Successfully Completed.</p>
      <div className={styles.btnWrap}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnOk}`}
          onClick={() => {
            if (pathname == "/governance/propose") {
              navigator("/governance/vote/all");
            } else {
              onClickCancel();
            }
          }}
        >
          Ok
        </button>
      </div>
    </div>
  );
};

export default Complete;
