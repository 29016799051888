import { BN } from "bn.js";
import Web3 from "web3";

export const fromWei = (web3, amount) => {
  let web3_1 = new Web3(web3)
  if (Number(amount) == 0) return 0;
  return web3_1.utils.fromWei(amount);
};

export const fromWeiDecimal = (web3, amount) => {
  if (Number(amount) == 0) return 0;
  let fromWei = web3.utils.fromWei(`${amount}`);
  let split = `${fromWei}`.split(".");
  if (split.length == 1) {
    return fromWei;
  } else if(amount< "1000000000000") {
    return "<0.0001";
  }else {
    // let find = `${split[1]}`.search(/[1-9]/g);
    // let fixed = fromWei.substring(0, fromWei.indexOf(".") + (find + 3));
    // fixed = (fixed * 10 ** 18) / 10 ** 18;
    // let parts = fixed.toString().split('.');
    // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')  
    // return parts.join('.');

    let regex = /^\d+\.\d{2}[1-9]0$|^\d+\.\d{3}[0-9]/;
    let amount = fromWei.match(regex);
    if(amount == null) return fromWei
    amount = amount[0] * 10000 / 10000
    return amount
  }
};

export const fromWeiThousandsSeparator = (web3, balance) => {
  if (balance != undefined && balance != 0 && web3 != "") {
    let fromWeiBalance = web3.utils.fromWei(`${balance}`);
    // let returnValue = fromWeiBalance
    //   .toString()
    //   .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    // return returnValue;
    fromWeiBalance=Number(fromWeiBalance).toFixed(3)
    let parts = fromWeiBalance.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')  
    return parts.join('.');

  } else {
    return 0;
  }
};

export const fromWeiToFixed = (web3, balance) => {
  if (balance != undefined && balance != 0 && web3 != "") {
    let web3_1 = new Web3(web3)
    let fromWeiBalance = web3_1.utils.fromWei(`${balance}`);
    // let fixed = (parseFloat(fromWeiBalance).toFixed(3) * 10000) / 10000;
    let fixed = fromWeiBalance;
    if(fromWeiBalance.indexOf(".") != -1){
      fixed = fromWeiBalance.substring(0, fromWeiBalance.indexOf(".") + 5, 4);
    }
    fixed = (fixed * 10 ** fixed.length) / 10 ** fixed.length;
    // let returnValue = fixed
    //   .toString()
    //   .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    // return returnValue;
    let parts = fixed.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')  
    return parts.join('.');
  } else {
    return 0;
  }
};

export const toWei = (web3, balance) => {
  let web3_1 = new Web3(web3)
  return web3_1.utils.toWei(`${balance}`);
};

export const subString = (string) => {
  let text = "";
  if (string != "" && string != undefined) {
    const startStr = string.substring(0, 5);
    const endStr = string.substring(string.length, string.length - 5);
    text = startStr + "..." + endStr;
  }
  return text;
};

export const thousandsSeparator = (number) => {
  // let returnValue = number
  //   .toString()
  //   .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  // return returnValue;
  let parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')  
  return parts.join('.');
};

export const setSorUnitNum = (number) => {
  if(number<1000000){
    return thousandsSeparator(number)
  }else if(number>=1000000){
    let text_1 = sortNum(number);
    let text_2 = sortUnit(number);
    let concatText = text_1.concat(text_2);
    return concatText;
  }
}

export const sortNum = (num) => {
  let num2 = Math.floor(num);
  if (Number(num2) < 1000) {
    return `${num2}`;
  } else if (Number(num2) < 1000000) {
    let div = `${num2}`.substring(0, `${num2}`.length - 3);
    let mod = `${num2}`.slice(-3);
    let stringDivMod = div.concat(".".concat(mod));
    let fixed = stringDivMod.substring(0, stringDivMod.indexOf(".") + 2, 4);
    fixed = (fixed * 100) / 100;
    return `${fixed}`;
  } else if (Number(num2) >= 1000000) {
    let div = `${num2}`.substring(0, `${num2}`.length - 6);
    let mod = `${num2}`.slice(-6);
    let stringDivMod = div.concat(".".concat(mod));
    let fixed = stringDivMod.substring(0, stringDivMod.indexOf(".") + 2, 4);
    fixed = (fixed * 100) / 100;
    return `${fixed}`;
  }
};

export const sortUnit = (num2) => {
  if (Number(num2) < 1000) {
    return "";
  } else if (Number(num2) < 1000000) {
    return "K";
  } else if (Number(num2) >= 1000000) {
    return "M";
  }
};

export const addArray = (list) => {
  let sum = 0;
  for (let i = 0; i < list.length; i++) {
    sum = new BN(`${list[i]}`).add(new BN(`${sum}`)).toString();
  }
  return sum;
};

export const addValidatorClaimableAssetsArray = (list) => {
  let sum = 0;
  for (let i = 0; i < list.length; i++) {
    sum = new BN(`${list[i].getValidatorFee}`).add(new BN(`${sum}`)).toString();
  }
  
  return sum;
};

export const addValidatorPendingAssetsArray = (list) => {
  let sum = 0;
  for (let i = 0; i < list.length; i++) {
    sum = new BN(`${list[i].getPendingValidatorFee}`).add(new BN(`${sum}`)).toString();
  }

  return sum;
};
export const sub = (web3, big, small) => {
  if(big == 0){
    return 0
  }else{
    let sub;
    let web3_1 = new Web3(web3)
    let amountBig = web3_1.utils.toWei(`${big}`);
    let amountSmall = web3_1.utils.toWei(`${small}`);
    sub = new BN(`${amountBig}`).sub(new BN(`${amountSmall}`)).toString();
    // sub = web3_1.utils.fromWei(`${sub}`);
    return setFromWeiBalance(web3_1, sub);
  }
};

export const subNotToWei = (web3, big, small) => {
    if(big == 0){
    return 0
  }else{
    let sub;
    let web3_1 = new Web3(web3)
    sub = new BN(`${big}`).sub(new BN(`${small}`)).toString();
    // sub = web3_1.utils.fromWei(`${sub}`);
    return setFromWeiBalance(web3_1, sub);
  }
};

export const sum = (web3, big, small) => {
  let sum;
  let web3_1 = new Web3(web3)
  let amountBig = web3_1.utils.toWei(`${big}`);
  let amountSmall = web3_1.utils.toWei(`${small}`);
  sum = new BN(`${amountBig}`).add(new BN(`${amountSmall}`)).toString();
  // sum = web3_1.utils.fromWei(`${sum}`);
  return setFromWeiBalance(web3_1, sum);
};

export const onClickScan = (tx) => {
  let url = process.env.REACT_APP_SCAN_URL;
  url = `${url}/tx/${tx}`;
  return window.open(url);
};

export const setFromWeiBalance = (web3, balance) => {
  let web3_1 = new Web3(web3)
  if (balance == "0" || balance == undefined) {
    return "0";
  } else {
    // if (fromWei(web3, balance) < String(100)) {
      if (Number(fromWei(web3_1, balance)) < 1000) {
        return fromWeiDecimal(web3_1, balance);
      } else {
        let text_1 = sortNum(fromWei(web3_1, balance));
        let text_2 = sortUnit(fromWei(web3_1, balance));
      let concatText = text_1.concat(text_2);
      return concatText;
    }
  }
};

export const setNumUnit = (num) =>{
  if(num!=""&&num!="0"){
    return Number(num)>100000000000?sortNum(num).concat(sortUnit(num)):thousandsSeparator(num)
  }else{
    return num
  }
}


export const scheduleTime = () => {

  return {rule:"*/10 * * * * *"}
}

export const jsonTitle = (text) => {
  try {
    let json = JSON.parse(text);
    return json.title
  } catch (error) {
    return text;
  }
};
export const jsonDescription = (text) => {
  try {
    let json = JSON.parse(text);
    return json.description
  } catch (error) {
    return text;
  }
};
