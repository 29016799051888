import React, { useState } from "react";

const ChangeCommission = ({ styles, item, onClickCancel, click }) => {
  const [maxCommission, setMaxCommission] = useState("30");
  const [inputCommission, setInputCommission] = useState("");
  const [commissionBtn, setCommissionBtn] = useState(false);

  const onChangeInputCommission = (value) => {
    if (!value.includes(".") && value != "") {
      let inputValue = value.replace(/[^0-9]/g, "");
      if (value > 30) {
        inputValue = 30;
      }
      setInputCommission(inputValue);
      setCommissionBtn(true);
    } else if (value == "") {
      setInputCommission("");
      setCommissionBtn(false);
    }
  };

  return (
    <div className={styles.popCont}>
      <p className={styles.popTit}>Change Commission</p>
      <ul className={`${styles.valueList}`}>
        <li>
          <span className={`${styles.tit}`}>Address</span>
          <span className={`${styles.value}`}>{item.validatorAddress}</span>
        </li>
        <li>
          <span className={`${styles.tit}`}>BeFore Commission</span>
          <span className={`${styles.value}`}>
            {item.commissionRate / 100}%
          </span>
        </li>
      </ul>
      <div className={`${styles.valueBox}`}>
        <p className={`${styles.subTit}`}>Commission</p>
        <div className={`${styles.psr}`}>
          <input
            type="text"
            className={`${styles.iptAmount}`}
            placeholder={`Max ${maxCommission}%`}
            onChange={(e) => {
              onChangeInputCommission(e.target.value);
            }}
            value={inputCommission}
          />
          <button
            type="button"
            className={`${styles.btnMax}`}
            onClick={() => {
              onChangeInputCommission(maxCommission);
            }}
          >
            Max
          </button>
        </div>
      </div>
      <div className={styles.btnWrap}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnCancel}`}
          onClick={onClickCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnNext} ${
            commissionBtn ? "" : styles.inActive
          }`}
          onClick={() => {
            if (commissionBtn) {
              click(item.validatorAddress, inputCommission);
            }
          }}
        >
          Change
        </button>
      </div>
    </div>
  );
};

export default ChangeCommission;
