import React, { useState } from "react";
import styled from "styled-components";

const ConnectWallet = ({ onClick, styles }) => {
  const [walletList, setWalletList] = useState([
    {
      name: "MetaMask",
      img: "sym_metamask@2x.png",
    },
    // {
    //   name: "WalletConnect",
    //   img: "sym_walletconnect@2x.png",
    // },
  ]);

  return (
    <div className={styles.popupContainer}>
      <div className={styles.title}>Connect Wallet</div>
      <div className={styles.connectList}>
        {walletList.map((item, index) => (
          <div
            className={index == 0 ? styles.mb : ""}
            onClick={() => {
              onClick(item.name);
            }}
            key={item.name}
          >
            <img
              src={require(`../../assets/images/${item.img}`)}
              width={"40"}
            />
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConnectWallet;
