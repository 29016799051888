import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Web3 from "web3";
import { setAccount } from "../../redux/account";
import { setActive, setBalance, setIsOwner, setProvider, setWindowEther } from "../../redux/user";
import ConnectWallet from "./ConnectWallet";


const BaseModal = ({
  type,
  onClick,
  onClickCancel,
  styles,
  setModalStatus
}) => {
  const dispatch = useDispatch();

  const chainId = process.env.REACT_APP_NETWORK_CHAINID;

  const provider = useSelector((state) => state.user.provider);


  const onClickWallet = (type) => {
    if (type == "MetaMask") {
      onClickMetamask();
    } else if (type == "WalletConnect") {
      onClickWalletConnect();
    }
    setModalStatus(false)
  };

  const onClickMetamask = async () => {
    if (window.ethereum != undefined) {
      let web3MetaMask;
      let accounts;
      let chainNum;
      let windowEthers;
      // 지갑이 2개 이상
      if (window.ethereum.overrideIsMetaMask) {
        for (const [key, value] of window.ethereum.providerMap.entries()) {
          if (key == "MetaMask") {
            web3MetaMask = new Web3(value);
            windowEthers = value;
          }
        }
      } else if (
        // 메타마스크만 있을 때
        window.ethereum.overrideIsMetaMask == undefined &&
        window.ethereum.isMetaMask
      ) {
        web3MetaMask = new Web3(window.ethereum);
        windowEthers = window.ethereum;
      } else if (
        // 메타마스크가 없을 때
        window.ethereum.isMetaMask == false
      ) {
        return window.open(
          `https://metamask.app.link/dapp/${process.env.REACT_APP_URL}`
        );
      }
      let getObjString = window.sessionStorage.getItem("GMMT_Staking");
      accounts = await windowEthers.send("eth_requestAccounts");
      accounts = accounts.result[0];
      chainNum = await web3MetaMask.eth.getChainId();
      if (String(chainNum) == chainId) {
        // dispatch(setWeb3(web3MetaMask));
        dispatch(setAccount(accounts));
        dispatch(setActive(true));
        dispatch(setWindowEther(windowEthers));
        // setModalStatus(false);
        let timestamp = Math.floor(+new Date() / 1000);
        let obj = {
          value: "inject",
          type:"metamask",
          expire: timestamp + 3600, // 1 hours
          // expire: timestamp + 3600, // 1 hours
        };
        if (!getObjString) {
          const objString = JSON.stringify(obj);
          window.sessionStorage.setItem("GMMT_Staking", objString);
        }
      } else {
        dispatch(setAccount(""));
        dispatch(setActive(""));
        dispatch(setWindowEther(""));
        onChangeNetWork(windowEthers);
      }
    } else {
      // 지갑이 없을 때
      window.open(
        `https://metamask.app.link/dapp/${process.env.REACT_APP_URL}`
      );
    }
  };

  const onClickWalletConnect = async () => {
    try {
      let result = await provider.enable();
      if (result != undefined) {
        const reChainId = await provider.chainId;
        if (provider.connected && String(reChainId) == String(chainId)) {
          // dispatch(setWeb3(new Web3(provider)));
          // dispatch(setProvider(windowEthers));
          dispatch(setAccount(result[0]));
          dispatch(setActive(true));
          dispatch(setWindowEther(provider));
          let timestamp = Math.floor(+new Date() / 1000);
          let obj = {
            value: "inject",
            type:"walletConnect",
            expire: timestamp + 3600, // 1 hours
            // expire: timestamp + 3600, // 1 hours
          };
          let getObjString = window.sessionStorage.getItem("GMMT_Staking");
          if (!getObjString) {
            const objString = JSON.stringify(obj);
            window.sessionStorage.setItem("GMMT_Staking", objString);
          }
          // setModalStatus(false);
        } else {
          onChangeNetWork(provider, "Wallet");
        }
      }
    } catch (error) {
      if(provider!=""){
        await provider.qrcodeModal.close();
        dispatch(setProvider(""));
        try {
        } catch (error) {
          if (provider == "") {
            onClickDisconnect();
          }
        }
      }
    }
  };

  const onClickDisconnect = async () => {
    dispatch(setAccount(""));
    dispatch(setActive(false));
    dispatch(setBalance(""));
    dispatch(setIsOwner(false));
    dispatch(setProvider(""))
    window.sessionStorage.removeItem("GMMT_Staking");
    window.localStorage.removeItem("walletconnect");
    setModalStatus(false);
  };
  const onChangeNetWork = async (providerWeb3, type) => {
    let netWorkVersion;
    let _rpcUrl;
    let blockExplorerURL;
    let chainName;
    let symbol;
    try {
      if (chainId == "88998") {
        // testnet
        netWorkVersion = "0x15ba6";
        _rpcUrl = "https://data-seed-premmt-1.mmtscan.io";
        blockExplorerURL = "https://testnet.mmtscan.io";
        chainName = "Mammoth TestNet";
        symbol = "MMT";
      } else if (chainId == "8898") {
        //  mainnet
        netWorkVersion = "0x22c2";
        _rpcUrl = "https://dataseed.mmtscan.io";
        blockExplorerURL = "https://mmtscan.io";
        chainName = "Mammoth Pro";
        symbol = "MMT";
      } else if (chainId == "5") {
        netWorkVersion = "0x5";
        _rpcUrl = "https://rpc.ankr.com/eth_goerli";
        blockExplorerURL = "https://goerli.etherscan.io";
        chainName = "Goerli Test Net";
        symbol = "Goerli";
      } else if (chainId == "1") {
        netWorkVersion = "0x1";
        _rpcUrl = "https://rpc.ankr.com/eth";
        blockExplorerURL = "https://etherscan.io";
        chainName = "Ethereum Mainnet";
        symbol = "ETH";
      } else if (chainId == "3004") {
        netWorkVersion = "0xBBC";
        _rpcUrl = process.env.REACT_APP_NETWORK_RPC;
        blockExplorerURL = "";
        chainName = "GiantMammoth Testnet";
        symbol = "GMMT";
      } else if (chainId == "8989") {
        netWorkVersion = "0x231D";
        _rpcUrl = "https://rpc-asia.gmmtchain.io";
        blockExplorerURL = "https://scan.gmmtchain.io";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      } else if (chainId == "898989") {
        netWorkVersion = "0xDB7AD";
        _rpcUrl = "https://testnet-rpc.gmmtchain.io";
        blockExplorerURL = "https://scan.gmmtchain.io";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      }

      let result = await providerWeb3.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: netWorkVersion,
          },
        ],
      });
    } catch (error) {
      if (error.code == 4001) {
        onClickDisconnect();
      } else {
        try {
          let a = await providerWeb3.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: netWorkVersion,
                chainName: chainName,
                rpcUrls: [_rpcUrl],
                nativeCurrency: {
                  name: chainName,
                  symbol: symbol,
                  decimals: 18,
                },
                blockExplorerUrls: [blockExplorerURL],
              },
            ],
          });
        } catch (addError) {
          console.log("addError", addError);
        }
      }
    }
  };


  return (
    <div id={styles.walletInfoPop} className={styles.popupWrap}>
      <div onClick={onClickCancel} className={styles.popupDim}/>
      {type == "connect" ? <ConnectWallet onClick={onClick instanceof Function ?onClick:onClickWallet} styles={styles}/> : ""}
    </div>
  );
};

export default BaseModal;
