import { BN } from "bn.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Web3 from "web3";

import GovernanceABI from "../abi/Governance.json";
import {
  jsonDescription,
  jsonTitle,
  setFromWeiBalance,
  subString,
  thousandsSeparator,
} from "../common";
import schedule from "node-schedule";
import { scheduleTime } from "../common";

const GovernanceProposeDetail = ({ styles }) => {

  const navigator = useNavigate();

  return (
    <>
      {false ? (
        ""
      ) : (
        <div>
          <div className={styles.bgGradation}>
            <button
              type="button"
              className={styles.btnBack}
              onClick={() => {
                navigator(-1);
              }}
            >
              Back to list
            </button>
            <div className={styles.proposalTitle} title="To everyone who wants to participate in the governance of Giant Mammoth.">
            To everyone who wants to participate in the governance of Giant Mammoth.
            </div>
          </div>
          <div className={styles.proposeInfoWrap}>
            <div className={styles.leftBox}>
              <div className={styles.proposeInfoCalldata}>
                <div className={styles.proposeInfoType}>
                  <span className={styles.grayText}>Type</span>
                  <span className={styles.blackText}> Notice</span>
                </div>
                <div className={styles.proposeInfoInfo}>
                  <span className={styles.grayText}>Value</span>
                  <span className={styles.blackText}>-</span>
                </div>
              </div>
              <div className={styles.desc}>
              Hello<br />
              This is Giant Mammoth.<br />
              First of all, thank you for seeing our potential and wanting to participate in Giant Mammoth governance.<br />
              We can promise you that you can build a promising future with us.<br />
              Get special rewards and help shape the ecosystem when you join our Giant Mammoth governance program.<br />
              Please wait for a while as we will soon open an opportunity for you to participate in our governance.<br />
              </div>
              <div className={styles.voteSection}>
                <div className={styles.voteTitle}>
                  <span>Votes</span>
                </div>
                <div className={styles.voteList}>
                  <div className={styles.voteListInWrap}>
                      <div className={`${styles.voteItem} ${styles.noVoter}`}>
                        No Voters
                      </div>
                  </div>
                </div>
                <div
                  className={`${styles.mainFooter} ${styles.detailFooters}`}
                ></div>
              </div>
            </div>
            <div className={styles.rightBox}>
              <div
                className={`${styles.whiteBox} ${styles.proposalInfoDetail}`}
              >
                <div className={styles.boldTitle}>Detail</div>
                <div className={styles.detialInfo}>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>Proposal</span>
                    <span className={styles.cont}>
                      Foundation
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>Start Block</span>
                    <span className={styles.cont}>
                      0
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>End Block</span>
                    <span className={styles.cont}>
                    -
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>Status</span>
                    <span className={`${styles.cont} ${styles.fwd}`}>
                      -
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.whiteBox} ${styles.proposalInfoCurrent}`}
              >
                <div className={styles.boldTitle}>Current Results</div>
                <div className={styles.detialInfo}>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>AgainstVotes</span>
                    <span className={styles.cont}>
                      -
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>ForVotes</span>
                    <span className={styles.cont}>
                      -
                    </span>
                  </div>
                  <div className={styles.detailItem}>
                    <span className={styles.tit}>AbstainVotes</span>
                    <span className={styles.cont}>
                      -
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.mainFooter} ${styles.detailFooters}`}></div>
        </div>
      )}
    </>
  );
};

export default GovernanceProposeDetail;
