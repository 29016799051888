import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = ({ styles }) => {
  const { pathname } = useLocation();

  const [nowName, setNowName] = useState("");
  const [headerPath, setHeaderPath] = useState([
    {
      name: "Staking",
      path: "/",
    },
    {
      name: "Governance",
      path: "/governance/vote/all",
    },
  ]);

  const stakingPathList = [
    "/address",
    "/",
    "/inActive",
    "/myStaking/stakedAssets",
    "/myStaking/history",
    "/validatorAssets/stakedAssets",
    "/validatorAssets/history",
  ];

  const getNowPath = () => {
    let split = pathname.split("/");
    if (!isNaN(split[split.length - 1])) {
      split.pop();
    }
    let join;
    if (split.length == 1 && split[split.length - 1] == "") {
      join = "/";
    } else {
      join = split.join("/");
    }
    let nowName = stakingPathList.includes(join) ? "Staking" : "Governance";
    setNowName(nowName);
  };

  useEffect(() => {
    getNowPath();
  }, [pathname]);

  return (
    <div className={styles.footerLink}>
      <div>
        {headerPath.map((item, index) => (
          <Link
            to={item.path}
            className={nowName == item.name ? styles.on : ""}
            key={index}
          >
            {item.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Footer;
