import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
const MainNavBar = ({ styles}) => {
  const { pathname } = useLocation();
  const [linkParams, setLinkParams] = useState([
    {
      name: "Staking",
      path: "/",
    },
    {
      name: "Governance",
      path: "/governance/vote/all",
    },
  ]);
  const [nowPathName, setNowPathName] = useState("");

  const checkPath = () => {
    let splitPathName = pathname.split("/");
    if (
      splitPathName[0] == "" &&
      (!isNaN(splitPathName[1]) ||
        splitPathName[1] == "" ||
        splitPathName[1] == "validatorAssets" ||
        splitPathName[1] == "myStaking")
    ) {
      setNowPathName("Staking");
    }
    if (splitPathName[0] == "" && splitPathName[1] == "governance") {
      setNowPathName("Governance");
    }
  };

  useEffect(() => {
    checkPath();
  }, [pathname]);

  return (
    <ul className={`${styles.tabWrapMain}`}>
      {linkParams.map((item, index) => (
        <li
          key={item.name}
          className={
            nowPathName.toLocaleLowerCase() == item.name.toLocaleLowerCase()
              ? styles.on
              : ""
          }
        >
          <Link to={`${item.path}`}>{item.name}</Link>
        </li>
      ))}
    </ul>
  );
};

export default MainNavBar;
