import { BN } from "bn.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Web3 from "web3";
import StakingABI from "../abi/Staking.json";
import schedule from "node-schedule";
import DelegatorBox from "../Components/ValidatorInfo/DelegatorBox";
import InforWrap from "../Components/ValidatorInfo/InforWrap";
import StakingBox from "../Components/ValidatorInfo/StakingBox";
import { scheduleTime } from "../common";

const ValidatorInfo = ({ styles }) => {
  const validatorContractAddress =
    process.env.REACT_APP_PUBLIC_STAKING_CONTRACT;

  const navigator = useNavigate();

  const defaultProvider = useSelector((state) => state.user.defaultProvider);

  const { pathname } = useLocation();
  const [validatorAddress, setValidatorAddress] = useState("");
  const [validatorInfo, setValidatorInfo] = useState({
    ownerAddress: "",
    status: "",
    totalDelegated: "",
    totalRewards: "",
    jailedBefore: "",
    slashesCount: "",
    commissionRate: "",
    claimedAt: "",
    changedAt: "",
  });

  const [eventList, setEventList] = useState([]);
  const [stakerList, setStakerList] = useState([]);
  const [totalDelegators, setTotalDelegators] = useState("0");
  const [schedule_1, setSchedule_1] = useState("");
  const [mobileCheck, setMobileCheck] = useState(false); // false이면 데탑;
  const [delegatorLoading, setDelegatorLoading] = useState(false);
  const [stakingLoading, setStakingLoading] = useState(false);

  const getAddress = () => {
    let splitPathName = pathname.split("/");
    setValidatorAddress(splitPathName[2]);
  };

  const getValidatorInfo = async () => {
    if (defaultProvider != "" && validatorAddress != "") {
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        StakingABI,
        validatorContractAddress
      );

      let validatorStatus = await contract.methods
        .getValidatorStatus(validatorAddress)
        .call();

      let setData = {
        validatorAddress: validatorAddress,
        ownerAddress: validatorStatus.ownerAddress,
        status: validatorStatus.status,
        totalDelegated: validatorStatus.totalDelegated,
        totalRewards: validatorStatus.totalRewards,
        jailedBefore: validatorStatus.jailedBefore,
        slashesCount: validatorStatus.slashesCount,
        commissionRate: validatorStatus.commissionRate,
        claimedAt: validatorStatus.claimedAt,
        changedAt: validatorStatus.changedAt,
      };

      setValidatorInfo(setData);
    }
  };

  const getStakingList = async () => {
    if (defaultProvider != "" && validatorAddress != "") {
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        StakingABI,
        validatorContractAddress
      );

      let delegate = await contract.getPastEvents("Delegated", {
        filter: { validator: validatorAddress },
        fromBlock: 0,
        toBlock: "latest",
      });


      let unDelegated = await contract.getPastEvents("Undelegated", {
        filter: { validator: validatorAddress },
        fromBlock: 0,
        toBlock: "latest",
      });
      // console.log("delegate",delegate)
      // console.log("unDelegated",unDelegated)

      let allReturnValues = [];



      for (let i = 0; i < delegate.length; i++) {
        let data = {
          type: "delegate",
          staker: delegate[i].returnValues.staker,
          amount: delegate[i].returnValues.amount,
        };
        allReturnValues.push(data);
      }
      for (let i = 0; i < unDelegated.length; i++) {
        let data = {
          type: "unDelegated",
          staker: unDelegated[i].returnValues.staker,
          amount: unDelegated[i].returnValues.amount,
        };
        allReturnValues.push(data);
      }

      allReturnValues.sort((a, b) => {
        return b.type - a.type;
      });

      let setData = [];
      let flag = false;
      let cnt = 0;

      for (let i = 0; i < allReturnValues.length; i++) {
        cnt = 0;
        flag = false;
        for (let value in setData) {
          if (setData[value].staker == allReturnValues[i].staker) {
            ++cnt;
            if (
              allReturnValues[i].type == "delegate" ||
              allReturnValues[i].type == "reDelegate"
            ) {
              setData[value].amount = new BN(`${setData[value].amount}`)
                .add(new BN(`${allReturnValues[i].amount}`))
                .toString();
            } else if (allReturnValues[i].type == "unDelegated") {
              setData[value].amount = new BN(`${setData[value].amount}`)
                .sub(new BN(`${allReturnValues[i].amount}`))
                .toString();
            }
          }
        }
        if (cnt == 0) {
          if (allReturnValues[i].amount != "0") {
            setData.push({
              staker: allReturnValues[i].staker,
              amount: allReturnValues[i].amount,
            });
          }
        }
      }

      let data = setData.filter((staker) => {
        return Number(staker.amount) > 0;
      });

      data.sort((a, b) => {
        return Number(b.amount) - Number(a.amount);
      });

      setTotalDelegators(data.length);
      setStakerList(data);
      setDelegatorLoading(true);
    }
  };

  const getHistory_ = async () => {
    if (defaultProvider != "" && validatorAddress != "") {
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        StakingABI,
        validatorContractAddress
      );
      let allEvent = [];
      let delegate = await contract.getPastEvents("Delegated", {
        filter: { validator: validatorAddress },
        fromBlock: 0,
        toBlock: "latest",
      });
      let claim = await contract.getPastEvents("Claimed", {
        filter: { validator: validatorAddress },
        fromBlock: 0,
        toBlock: "latest",
      });
      // let reDelegate = await contract.getPastEvents("Redelegated", {
      //   filter: { validator: validatorAddress },
      //   fromBlock: 0,
      //   toBlock: "latest",
      // });
      let unDelegate = await contract.getPastEvents("Undelegated", {
        filter: { validator: validatorAddress },
        fromBlock: 0,
        toBlock: "latest",
      });

      let eventList = delegate.concat(unDelegate).concat(claim);

      for (let i = 0; i < eventList.length; i++) {
        if(eventList[i].returnValues.amount != "0"){
          let inputData = {
            type: eventList[i].event,
            amount: eventList[i].returnValues.amount,
            validator: eventList[i].returnValues.validator,
            time: await setTimestamp(eventList[i].blockNumber),
            blockNumber: eventList[i].blockNumber,
            transactionHash: eventList[i].transactionHash,
            epoch: eventList[i].returnValues.epoch,
            staker: eventList[i].returnValues.staker,
          };
          allEvent.push(inputData);
        }
      }

      allEvent.sort((a, b) => {
        return b.blockNumber - a.blockNumber;
      });

      setEventList(allEvent);
      setStakingLoading(true);
    }
  };

  const getHistory = async () => {
    if (defaultProvider != "" && validatorAddress != "") {
      const web3 = new Web3(defaultProvider);
      const contract = new web3.eth.Contract(
        StakingABI,
        validatorContractAddress
      );
      let allEvent = [];

      let delegate = new Promise(async (resolve, reject) => {
        let delegate = await contract.getPastEvents("Delegated", {
          filter: { validator: validatorAddress },
          fromBlock: 0,
          toBlock: "latest",
        });
        resolve(delegate)
      });

      let claim = new Promise(async (resolve, reject) => {
        let claim = await contract.getPastEvents("Claimed", {
          filter: { validator: validatorAddress },
          fromBlock: 0,
          toBlock: "latest",
        });
        resolve(claim)

      });

      let unDelegate = new Promise(async (resolve, reject) => {
        let unDelegate = await contract.getPastEvents("Undelegated", {
          filter: { validator: validatorAddress },
          fromBlock: 0,
          toBlock: "latest",
        });
        resolve(unDelegate)

      });

      Promise.all([delegate, claim, unDelegate]).then(async (values) => {
        let concatArray = [];
        for (let i = 0; i < values.length; i++) {
          concatArray.push(...values[i]);
        }
        let returnArray = []; 
        
        for (let i = 0; i < concatArray.length; i++) {
          if(concatArray[i].returnValues.amount != "0"){
            let inputData = {
              type: concatArray[i].event,
              amount: concatArray[i].returnValues.amount,
              validator: concatArray[i].returnValues.validator,
              time: await setTimestamp(concatArray[i].blockNumber),
              blockNumber: concatArray[i].blockNumber,
              transactionHash: concatArray[i].transactionHash,
              epoch: concatArray[i].returnValues.epoch,
              staker: concatArray[i].returnValues.staker,
            };
            returnArray.push(inputData);
          }
        }
        returnArray.sort((a,b) => {
          return b.blockNumber - a.blockNumber
        })
        setEventList(returnArray);
        setStakingLoading(true);
      });
    }
  };

  const setTimestamp = async (blockNumber) => {
    const web3 = new Web3(defaultProvider);
    let blockData = await web3.eth.getBlock(blockNumber);
    let date = moment.unix(blockData.timestamp).format("YYYY-MM-DD HH:mm:ss");
    return date;
  };

  useEffect(() => {
    getAddress();
  }, [pathname]);

  useEffect(() => {
    getValidatorInfo();
  }, [validatorAddress, defaultProvider]);

  useEffect(() => {
    getStakingList();
  }, [defaultProvider, validatorAddress]);

  useEffect(() => {
    getHistory();
  }, [defaultProvider, validatorAddress]);

  useEffect(() => {
    let schedules = "";
    if (defaultProvider != "" && schedule_1 == "" && validatorAddress != "") {
      schedules = schedule.scheduleJob("5", scheduleTime(), () => {
        if (!window.location.pathname.includes("/address")) {
          schedule.cancelJob("5");
        } else {
          getValidatorInfo();
          getStakingList();
          getHistory();
        }
      });
      setSchedule_1(schedules);
    } else if (schedule_1 != "") {
    }
  }, [defaultProvider, validatorAddress, schedule_1]);

  const checkdetectMoileDevice = (agent, width) => {
    setMobileCheck(width <= 880);
  };

  useEffect(() => {
    checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    window.addEventListener("resize", () => {
      checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        checkdetectMoileDevice(window.navigator.userAgent, window.innerWidth);
      });
    };
  }, []);

  return (
    <div>
      <div className={styles.bgGradation}>
        <button
          type="button"
          className={styles.btnBack}
          onClick={() => {
            navigator(-1);
          }}
        >
          Back to list
        </button>
      </div>
      <InforWrap
        validatorInfo={validatorInfo}
        styles={styles}
        mobileCheck={mobileCheck}
      />
      <div className={styles.tableWrap}>
        <DelegatorBox
          stakerList={stakerList}
          totalDelegators={totalDelegators}
          styles={styles}
          mobileCheck={mobileCheck}
          loading={delegatorLoading}
        />
        <StakingBox list={eventList} styles={styles} loading={stakingLoading}/>
      </div>
      <div className={`${styles.mainFooter}`}>
      </div>
    </div>
  );
};

export default ValidatorInfo;
