import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NextBtn = styled.button`
  width: 2rem;
  height: 2rem;
  vertical-align: middle;

  @media screen and (max-width: 767px) {
    width: 5rem;
    height: 5rem;
  }

  @media screen and (max-width: 480px) {
    width: 4rem;
    height: 4rem;
  }
`;
const NextBtnImg = styled.img`
  display: block;
  width: 100%;
`;

const Next = ({ styles, onClickNext, nowPage, totalPage, path, pathname }) => {
  return (
    <button className={styles}>
      <Link
        to={
          Number(nowPage) == totalPage
            ? `${pathname}`
            : `${path == "/" ? "" : path}${
                Number(nowPage) == totalPage
                  ? path == "/"
                    ? "/"
                    : ""
                  : "/".concat(Number(nowPage) + 1)
              }`
        }
      >
        <img src={require("../../assets/images/common_ico_list_next@3x.png")} />
      </Link>
    </button>
  );
};

export default Next;
