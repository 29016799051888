import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setFromWeiBalance, subNotToWei, subString } from "../../common";

const TableMyStaking = ({ styles, item, onClickModal, onClickRelease }) => {
  const defaultProvider = useSelector((state) => state.user.defaultProvider);

  return (
    <div className={styles.tbody}>
      <div className={styles.validator}>
        <div className={styles.img}>
          <img src="" />
        </div>
        <p className={`${styles.name} ${styles.fwb}`}>
          <Link to={`/address/${item.validatorAddress}`}>
            {subString(item.validatorAddress)}
          </Link>
        </p>
      </div>
      <div className={styles.address}>{subString(item.ownerAddress)}</div>
      <div className={styles.claimable}>
        {setFromWeiBalance(defaultProvider, item.getValidatorFee)} GMMT
      </div>
      <div className={styles.pendingClaimable}>
        {/* {setFromWeiBalance(defaultProvider, item.getPendingValidatorFee)}{" "}GMMT */}
        {subNotToWei(
          defaultProvider,
          item.getPendingValidatorFee,
          item.getValidatorFee
        )}{" "}
        GMMT
      </div>
      <div className={styles.button}>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnBdBlack} ${
            styles.validatorAssetsButton
          } ${item.getValidatorFee != "0" ? "" : styles.inActive}`}
          onClick={() => {
            if (item.getValidatorFee != "0") {
              onClickModal(item, "validatorClaim");
            }
          }}
        >
          Claim
        </button>{" "}
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnBdBlack} ${
            styles.validatorAssetsButton
          } ${item.status == "3" ? "" : styles.inActive}`}
          onClick={() => {
            if (item.status == "3") {
              onClickRelease(item.validatorAddress);
            }
          }}
        >
          Release
        </button>
        <button
          type="button"
          className={`${styles.btnNormal} ${styles.btnBdBlue} ${styles.validatorAssetsButton}
           `}
          onClick={() => {
            // if (item.getValidatorFee != "0") {
            onClickModal(item, "changeCommission");
            // }
          }}
        >
          Change Commission
        </button>
      </div>
    </div>
  );
};

export default TableMyStaking;
