import React, { useEffect, useState } from "react";

const Loading = ({ styles, loading }) => {
  // useEffect(() => {
  //   let dec = ""
  //   setInterval(()=>{
  //     dec = dec.concat(".");
  //     if(dec == "...."){
  //       dec = ""
  //     }
  //     setDecimal(dec)
  //   },[1000])
  // },[])

  return (
    <div className={`${styles.noResult}`}>
      <div className={`${styles.loadingText}`}>Loading...</div>
    </div>
  );
};

export default Loading;
