import { combineReducers } from "redux";
import { all } from "@redux-saga/core/effects";
import { persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";

import user from "./user"
import account from "./account"


const persistConfig = {
    key: "GMMT_Node_Staking",
    storage,
    whitelist: [
        "account",
    ],
}


const rootreducer = combineReducers({
    user,
    account
});


export function* rootsaga() {
    yield all([
    ]);
}

const persistedReducer = persistReducer(persistConfig, rootreducer);

export default persistedReducer;